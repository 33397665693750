import React, { useContext, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { Category } from '@Types/product/Category';
import { GiButter } from 'react-icons/gi';
import AccountIcon from 'components/icons/sol/account';
import CartIcon from 'components/icons/sol/cart';
import CloseIcon from 'components/icons/sol/close';
import ForwardIcon from 'components/icons/sol/forward';
import HeaderLogo from 'components/icons/sol/header-logo';
import MenuIcon from 'components/icons/sol/menu';
import MobileLogo from 'components/icons/sol/mobile-logo';
import SearchIcon from 'components/icons/sol/search';
import Notification from 'components/sol-ui/notification';
import InlineNotification from 'components/sol-ui/notification/inline-notification';
import MiniCartNotification from 'components/sol-ui/notification/mincart-notification';
import { cartQuantityTotal } from 'helpers/cartQuantityTotal';
import { SIGNED_OUT, CART_ITEM_EMPTY } from 'helpers/constants/messages';
import { CurrentPathContext } from 'helpers/continueShoppingContext/continueShoppingContext';
import { useNotification } from 'helpers/notification/notificationContext';
import { PageLoadContext } from 'helpers/page-load-context/pageLoadContext';
import { getReferenceTarget } from 'helpers/reference';
import { RouteHelpers } from 'helpers/routeHelpers';
import { StringHelpers } from 'helpers/stringHelpers';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { useAccount, useCart } from 'frontastic';
import { SOLHeaderMobileSearch } from './header-mobile-search';
import { MiniCart } from './mini-cart';
import { gtmUserLogout } from 'helpers/utils/gtm-events/gtmUser';
import { gtmMenuNavigation } from 'helpers/utils/gtm-events/gtmInteraction';
import { gtmViewCart } from 'helpers/utils/gtm-events/gtmEcommerce';
interface SOLHeaderMobileType {
  data: any;
  categories: Category[];
}

export const SOLHeaderMobile = ({ data, categories }: SOLHeaderMobileType) => {
  //next/router
  const router = useRouter();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const { primaryMenuCount, subMenuLevel1Count, subMenuLevel2Count } = data;
  const { data: cart } = useCart();
  const { showNotification, hideNotification, notification, inlineNotification, hideInlineNotification } =
    useNotification();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [subNav, setSubNav] = useState({
    isOpen: false,
    name: '',
    overrideURL: '',
    isTextOnly: false,
    slug: '',
    children: [],
  });
  const { notificationTimeout, maximumLoginAttemptTimeout } = data;
  const [navIndex, setNavIndex] = useState(0);
  const [promotionalBannerHeight, setPromotionalBannerHeight] = useState(null);
  const { searchContentCount, searchRelatedProdutsCount, searchSuggestedSearchesCount } = data;
  const isMountedRef = useRef(true);
  const { setCurrentPath } = useContext(CurrentPathContext);
  //mini cart open
  const [openMiniCartModal, setOpenMiniCartModal] = useState(false);

  //account actions
  const { logout, loggedIn, account } = useAccount();
  const { accountLink, loginLink } = data;
  const { setIsPageLoading } = useContext(PageLoadContext);

  // We will remove later in the next PR
  // useEffect(() => {
  //   if (notification?.message) {
  //     hideNotification();
  //   }
  //   if (inlineNotification?.message) {
  //     hideInlineNotification();
  //   }
  // }, [router.asPath]);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    // Clear notifications when route changes
    const clearNotifications = () => {
      if (notification?.message) {
        hideNotification();
      }
      if (inlineNotification?.message) {
        hideInlineNotification();
      }
      const excludedPaths = [
        routerURL?.cart,
        routerURL?.account,
        routerURL?.orderHistory,
        routerURL?.myList,
        routerURL?.address,
        routerURL?.creditCard,
        routerURL?.login,
      ];
      if (!excludedPaths.includes(router.asPath)) {
        setCurrentPath(router.asPath);
      }
    };

    router.events.on('routeChangeComplete', clearNotifications);

    return () => {
      router.events.off('routeChangeComplete', clearNotifications);
    };
  }, [notification, inlineNotification, router]);

  const handlePromotionalBannerHeight = () => {
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        if (document.readyState === 'complete' && isMountedRef.current) {
          setPromotionalBannerHeight(document.getElementById('promotional-banner')?.scrollHeight);
        }
      }
    }, 5000);
  };

  useEffect(() => {
    handlePromotionalBannerHeight();
  }, []);

  const handleAccount = () => {
    if (window.location.href.includes('search') || window.location.href.includes('categories')) {
      setCurrentPath(location.href.replace(location.origin, ''));
    }

    if (loggedIn) {
      router.push(getReferenceTarget(accountLink));
    } else {
      router.push(getReferenceTarget(loginLink));
    }
    setIsOpenMenu(false);
  };
  const handleLogout = async () => {
    setIsPageLoading(true);
    setIsOpenMenu(false);
    const response = await logout();
    window.location.href = getReferenceTarget(loginLink);
    // await router.push(getReferenceTarget(loginLink));
    setIsPageLoading(false);
    gtmUserLogout();
    showNotification(SIGNED_OUT, 'success');
  };
  const handleChange = (index) => {
    if (navIndex === index) {
      setNavIndex(100);
    } else {
      setNavIndex(index);
    }
  };
  const closeHamburgerMenu = () => setIsOpenMenu(false);
  const bodyScrollValue = 1;

  useEffect(() => {
    // Handle scrolling and cleanup
    const handleScroll = () => {
      if (promotionalBannerHeight !== 0) {
        setScrollPosition(window.scrollY);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [promotionalBannerHeight]);

  useEffect(() => {
    if (openMiniCartModal || isOpenMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [openMiniCartModal || isOpenMenu]);
  return (
    <>
      <section>
        {!openMiniCartModal && <Notification timeoutInMs={notificationTimeout} />}
        <InlineNotification timeoutInMs={maximumLoginAttemptTimeout} />
      </section>
      <section className={`pb-[85px]`}>
        <section
          className={`fixed left-0 z-[9] min-h-[86px] w-full ${
            promotionalBannerHeight !== 0 && scrollPosition > bodyScrollValue
              ? 'top-0'
              : `top-[${promotionalBannerHeight}px]`
          }`}
        >
          <section className="w-full flex-col bg-[#F7F6F0] p-4 font-bold">
            <section className="flex items-center justify-between">
              <section
                onClick={() => {
                  setIsOpenSearch(false);
                  setIsOpenMenu(true);
                }}
                className="flex h-10 w-[58px] items-center rounded-md bg-transparent"
              >
                <MenuIcon className="h-3 w-5 fill-sol-300" />
              </section>
              <section className="xsMobLogo flex max-w-[191px] justify-center" onClick={() => setIsOpenSearch(false)}>
                <Link href="/">
                  <a aria-label="shades of light logo">
                    <MobileLogo className="h-[54px] w-[191px]" />
                  </a>
                </Link>
              </section>
              <section className="mr-3 flex items-center gap-5 text-sm">
                <section onClick={() => setIsOpenSearch(!isOpenSearch)}>
                  <SearchIcon className="h-[19px] w-[19px]" />
                </section>
                <section className="relative">
                  <CartIcon />

                  <button
                    type="button"
                    id="miniCartBtn"
                    onClick={() => {
                      if (window.location.href.includes('search') || window.location.href.includes('categories')) {
                        setCurrentPath(location.href.replace(location.origin, ''));
                      }

                      if (cart?.lineItems?.length === 0) {
                        showNotification(CART_ITEM_EMPTY, 'success');
                      } else {
                        setIsOpenSearch(false);
                        setOpenMiniCartModal(true);
                      }
                      gtmViewCart(cart, account);
                    }}
                    className={`absolute -top-4 -right-3 flex h-6 w-6 items-center justify-center ${
                      cart?.lineItems?.length > 0 ? 'rounded-[50%] bg-sol-700' : ''
                    }`}
                  >
                    {cart?.lineItems?.length > 0 && (
                      <em className="text-sm not-italic leading-[14px]  text-white">
                        {cartQuantityTotal(cart?.lineItems)}
                      </em>
                    )}
                  </button>
                </section>
              </section>
            </section>
          </section>

          {openMiniCartModal && (
            <section
              className="fixed top-0 right-0 z-10 flex h-[100vh] w-full bg-[#2B2B2B] opacity-90 lg:hidden"
              onClick={() => setOpenMiniCartModal(false)}
            >
              <section className="absolute top-0 left-0 mx-auto mt-6 flex w-[10vw] items-center justify-center">
                <CloseIcon className="fill-white" />
              </section>
            </section>
          )}

          <section
            style={{ width: '90vw', transition: 'all 0.3s ease-in-out' }}
            className={
              openMiniCartModal
                ? 'fixed top-0 right-0 z-20 flex h-full w-full translate-x-0 lg:hidden'
                : 'absolute top-0 right-0 z-20 flex h-full w-full translate-x-full lg:hidden'
            }
          >
            <section className="h-full w-full bg-[#F5F5F5]">
              <section className="relative h-full lg:static">
                <MiniCart
                  cart={cart}
                  mobileModalClose={() => setOpenMiniCartModal(false)}
                  openMiniCartModal={openMiniCartModal}
                  notificationTimeoutInMs={notificationTimeout}
                />
              </section>
            </section>
          </section>

          {isOpenMenu && (
            <section
              className="fixed top-0 left-0 z-10 flex h-[100vh] w-full bg-[#2B2B2B] opacity-90"
              onClick={() => setIsOpenMenu(false)}
            >
              <section className="absolute top-0 right-0 mx-auto mt-6 flex w-[10vw] items-center justify-center">
                <CloseIcon className="fill-white" />
              </section>
            </section>
          )}

          <section
            style={{ width: '90vw', transition: 'all 0.3s ease-in-out' }}
            className={
              isOpenMenu
                ? 'fixed top-0 z-20 flex h-full w-full translate-x-0'
                : 'absolute top-0 z-20 flex w-full -translate-x-full'
            }
          >
            <section className="flex h-full flex-col font-bold">
              {subNav.isOpen ? (
                <section
                  style={{ backgroundColor: '#F2F2F2', borderColor: 'rgba(0, 0, 0, 0.1)' }}
                  className="flex items-center justify-between gap-2 border-b"
                >
                  <section className="flex w-full items-center justify-between gap-4">
                    <section
                      className="flex items-center gap-4 py-5 pl-5"
                      onClick={() =>
                        setSubNav({
                          isOpen: false,
                          children: subNav?.children,
                          name: subNav?.name,
                          overrideURL: subNav?.overrideURL,
                          isTextOnly: subNav?.isTextOnly,
                          slug: subNav?.slug,
                        })
                      }
                    >
                      <section className="rotate-180">
                        <ForwardIcon />
                      </section>
                      <p className="text-sm font-semibold leading-[17px] text-[#222222]">
                        {' '}
                        {StringHelpers.titleCaseFormatter(subNav?.name)}
                      </p>
                    </section>
                    <section className="shrink-0 pr-5">
                      <Link href={RouteHelpers.formatCategoryPath(subNav?.overrideURL || '')}>
                        <a
                          className="text-sm font-semibold leading-[17px] text-[#222222]"
                          onClick={(e) => {
                            e.stopPropagation();

                            gtmMenuNavigation({
                              click_location: RouteHelpers.formatCategoryPath(subNav?.overrideURL || ''),
                              click_name: mapLocaleToMeaningfulFormat(router.locale).exploreAll,
                            });
                            closeHamburgerMenu();
                          }}
                        >
                          {mapLocaleToMeaningfulFormat(router.locale).exploreAll}
                        </a>
                      </Link>
                    </section>
                  </section>
                </section>
              ) : (
                <section className="border-[rgb(0, 0, 0)]/10 flex items-center justify-between gap-2 border-b bg-[#F2F2F2] p-5">
                  <section className="flex items-center gap-2">
                    <AccountIcon />
                    <button
                      type="button"
                      onClick={handleAccount}
                      className="text-sm font-semibold leading-[17px] text-black-900"
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).account}
                    </button>
                  </section>
                  {loggedIn && (
                    <button
                      type="button"
                      onClick={handleLogout}
                      className="relative text-sm font-semibold leading-[17px] text-black-900"
                    >
                      <span className="absolute bottom-0 left-0 top-[15px] h-[1px] w-full bg-[#000]"></span>
                      {mapLocaleToMeaningfulFormat(router.locale).signOut}
                    </button>
                  )}
                </section>
              )}
              <section className="h-[calc(100%_-_57px)] w-[90vw] overflow-y-auto bg-white font-extrabold">
                <ul>
                  {subNav.isOpen
                    ? subNav?.children?.map((nav, index) => (
                        <li
                          key={nav?.name}
                          style={{ borderColor: 'rgba(0, 0, 0, 0.1)' }}
                          className="flex flex-col border-b p-5"
                        >
                          <section
                            className="flex w-full items-center justify-between"
                            onClick={() => handleChange(index)}
                          >
                            <p className="text-sm font-semibold leading-[17px] text-[#222222]">
                              {' '}
                              {StringHelpers.titleCaseFormatter(nav?.name)}
                            </p>
                            {nav?.children?.length > 0 ? (
                              <section className={navIndex === index ? '-rotate-90' : 'rotate-90'}>
                                <ForwardIcon />
                              </section>
                            ) : (
                              <></>
                            )}
                          </section>

                          {navIndex === index && (
                            <ul className="mt-4 flex flex-col font-light">
                              {nav?.children?.map((n, index) => (
                                <li key={index} className="py-1">
                                  {n?.isTextOnly ? (
                                    <p
                                      onClick={closeHamburgerMenu}
                                      className="inline-block text-[13px] font-normal leading-[19px] text-[#222222]"
                                    >
                                      {n?.name}
                                    </p>
                                  ) : (
                                    <Link href={StringHelpers.categoryRouteFormatter(n)}>
                                      <a
                                        onClick={() => {
                                          gtmMenuNavigation({
                                            click_location: StringHelpers.categoryRouteFormatter(n),
                                            click_name: n?.name,
                                          });
                                          closeHamburgerMenu();
                                        }}
                                        className="inline-block text-[13px] font-normal leading-[19px] text-[#222222] hover:underline"
                                      >
                                        {n?.name}
                                      </a>
                                    </Link>
                                  )}
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))
                    : categories?.map((n) => (
                        <li
                          key={n?.name}
                          className=" flex items-center justify-between border-b p-5"
                          onClick={
                            n?.children.length > 0
                              ? () =>
                                  setSubNav({
                                    isOpen: true,
                                    name: n?.name,
                                    children: n?.children,
                                    overrideURL: n?.overrideURL,
                                    isTextOnly: n?.isTextOnly,
                                    slug: n?.slug,
                                  })
                              : () => {
                                  setIsOpenMenu(false);
                                  router.push('#');
                                }
                          }
                        >
                          <button className="inline-block w-full text-left text-sm font-semibold leading-[17px] text-[#222222]">
                            {StringHelpers.titleCaseFormatter(n?.name)}
                          </button>
                          {n?.children.length > 0 ? <ForwardIcon /> : <></>}
                        </li>
                      ))}
                </ul>
              </section>
            </section>
          </section>
          {isOpenSearch && (
            <section className="top-18 absolute z-[5]">
              <SOLHeaderMobileSearch
                categories={categories}
                setIsOpenSearch={setIsOpenSearch}
                searchRelatedProdutsCount={searchRelatedProdutsCount}
                searchSuggestedSearchesCount={searchSuggestedSearchesCount}
                searchContentCount={searchContentCount}
              />
            </section>
          )}
        </section>
      </section>
    </>
  );
};
