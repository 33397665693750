import { SEO_Variant } from './constants/localStorage';
import { DYNAMIC_CATEGORY_PATH } from './constants/route';
import qs from 'qs';
export class RouteHelpers {
  static formatCategoryPath = (path: string) => `${DYNAMIC_CATEGORY_PATH}${path}`;

  static getBaseURL = (path: string) => {
    return path?.split('?')[0];
  };

  static getFilterCount = (path: string, sortOptions: any, fbclidOptions = undefined) => {
    if (sortOptions === undefined) {
      if (fbclidOptions) {
        return path?.split('?')[1]?.replace(/[^=]/g, '').length - 1 || 0;
      } else {
        return path?.split('?')[1]?.replace(/[^=]/g, '').length || 0;
      }
    } else {
      if (fbclidOptions) {
        return path?.split('?')[1]?.replace(/[^=]/g, '').length - 2 || 0;
      } else {
        return path?.split('?')[1]?.replace(/[^=]/g, '').length - 1 || 0;
      }
    }
  };

  static getSEOVariant = (path: any, asPath: any) => {
    if (path) {
      let urlObject = new URL(`${path?.scheme + path?.hostName + asPath}`);
      urlObject.searchParams.delete('sort');
      let updatedUrl = urlObject.toString().replace(/\+/g, '%20');
      const str = updatedUrl?.split('=')[1];
      localStorage.setItem(SEO_Variant, decodeURIComponent(str));
      return decodeURIComponent(str);
    }
  };

  static createCanonicalURL = (base: any, url: any, removeAllQueries = false) => {
    if (base) {
      const urlObject = new URL(`${base + url}`);
      // Remove specific queries, e.g. sort & fbclid
      ['sort', 'fbclid'].forEach((queryToRemove) => {
        if (queryToRemove) {
          urlObject.searchParams.delete(queryToRemove);
        }
      });

      const firstTwoParams = new Map();
      let numberOfValues = 0;

      // The filter values are counted towards the overall count
      // e.g. filterColor=blue,green => 2 filters
      Array.from(urlObject.searchParams.entries()).forEach(([key, value]) => {
        const paramValues = value.split(',');

        paramValues.forEach((paramValue) => {
          if (numberOfValues < 2) {
            if (firstTwoParams.has(key)) {
              firstTwoParams.set(key, firstTwoParams.get(key) + ',' + paramValue);
            } else {
              firstTwoParams.set(key, paramValue);
            }
          }
          numberOfValues += 1;
        });
      });

      urlObject.search = '';

      if (!removeAllQueries) {
        firstTwoParams.forEach((value, key) => urlObject.searchParams.append(key, value));
      }

      urlObject.search = decodeURIComponent(urlObject.search);
      const updatedUrl = urlObject.toString();
      return updatedUrl;
    }
  };

  static encodeToURL = (queryObj: any) => {
    return qs.stringify(queryObj, {
      addQueryPrefix: true,
      arrayFormat: 'comma',
      encodeValuesOnly: true,
      format: 'RFC1738',
    });
  };

  static decodeToObj = (queryString: string) => {
    return qs.parse(queryString, {
      ignoreQueryPrefix: true,
      comma: true,
    });
  };

  static updatePDPRouterQueryColor = (routerPath, colorToUpdate) => {
    const tempRouterPath = routerPath?.split('=');
    tempRouterPath[1] = colorToUpdate;
    let updatedTempRouterPath = tempRouterPath?.join('=');
    return updatedTempRouterPath;
  };
}
