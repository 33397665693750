import { Fragment } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { Card, CardRequest } from '@Types/account/AuthorizeNetAccount';
import { Formik, Form, Field } from 'formik';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import CustomSpinner from 'components/icons/sol/custom-spinner';
import DiscoverCard from 'components/icons/sol/discover';
import AuthorizeNetScript from 'components/sol-ui/payment/authorizeNet';
import { INVALID_CREDIT_CARD } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { StringHelpers } from 'helpers/stringHelpers';
import Accept from 'helpers/utils/authorize-net';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import validationSchema from 'helpers/utils/validationSchema/modal/credit-card';
import { useDarkMode, useAuthorizeNet, useConfig } from 'frontastic';
import AmexCard from '../../../icons/sol/amex';
import CloseIcon from '../../../icons/sol/close';
import CreditCardIcon from '../../../icons/sol/credit-card-icon';
import MasterCard from '../../../icons/sol/mastercard';
import VisaCard from '../../../icons/sol/visa';
import FormEventsAnalyticsTracker from 'components/sol-ui/form-events-analytics-tracker';
import useFormInteraction from 'helpers/hooks/useFormInteraction';

export interface CreditCardProps {
  open?: boolean;
  onClose?: () => void;
  addressId?: string;
  modalHeading?: string;
  primaryBtnContent?: string;
  card?: Card;
  handleAdd?: (request: CardRequest) => void;
  handleUpdate?: (request: CardRequest) => void;
  loading?: boolean;
}

type CreditCardForm = {
  creditCardNumber: string;
  nameOnCard: string;
  expirationDate: string;
  isDefaultCard: boolean;
};

const creditCardInitialValues: CreditCardForm = {
  creditCardNumber: '',
  nameOnCard: '',
  expirationDate: '',
  isDefaultCard: false,
};

const CreditCard: React.FC<CreditCardProps> = ({
  card,
  open,
  onClose,
  handleAdd,
  handleUpdate,
  modalHeading,
  primaryBtnContent,
  loading,
}) => {
  //next/router
  const router = useRouter();
  //Darkmode
  const { mode, theme } = useDarkMode();
  const renderError = (message: any) => <p className="mt-2 text-sm leading-[17px] text-sol-700">{message}</p>;
  //global notification
  const { showNotification } = useNotification();

  const { updateCard, setDefaultCreditCard } = useAuthorizeNet();
  const { data: config } = useConfig();
  const authData = {
    apiLoginID: config?.authorizeNet?.apiLoginKey,
    clientKey: config?.authorizeNet?.clientKey,
  };
  const { handleSubmitInteraction } = useFormInteraction(modalHeading);
  const handleEditProfile = async (data: CreditCardForm) => {
    const cardNumber = data.creditCardNumber.replace(/\s/g, '');
    if (!StringHelpers.checkValidCreditCard(cardNumber)) {
      showNotification(INVALID_CREDIT_CARD, 'error');
    } else {
      const [month, year] = data.expirationDate.split('/');
      const result = await Accept.dispatchData({
        authData,
        cardData: {
          cardNumber: cardNumber,
          month,
          year,
          cardholderName: data.nameOnCard,
        },
      });

      if (result) {
        try {
          if (card?.customerPaymentProfileId) {
            await handleUpdate({
              token: result?.opaqueData?.dataValue,
              isDefault: data?.isDefaultCard || false,
              customerPaymentProfileId: card?.customerPaymentProfileId,
            });
          } else {
            await handleAdd({ token: result?.opaqueData?.dataValue, isDefault: data?.isDefaultCard || false });
          }
          handleSubmitInteraction();
        } catch (error) {
          showNotification(error.toString(), 'error');
        }
        onClose();
      }
    }
  };

  const Input = styled(InputMask)``;

  return (
    <>
      <AuthorizeNetScript />
      <Transition.Root show={open} as={Fragment}>
        <Dialog className={`${mode} ${theme} default fixed inset-0 z-10 overflow-y-auto`} onClose={onClose}>
          <>
            <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="absolute inset-0">
                  {/* eslint-disable */}
                  <div className="absolute top-1/2 left-1/2  w-[90%] max-w-[500px] -translate-x-1/2 -translate-y-1/2 overflow-auto bg-white py-8 px-4 dark:bg-primary-200 sm:px-6  lg:px-8">
                    <section className="flex justify-between">
                      <h1 className="mx-auto mb-9 w-max text-center text-2xl font-bold leading-[29px] text-sol-400 after:mx-auto after:mt-4 after:block after:h-[1px] after:w-[150px] after:bg-[#B19A6A] after:content-['']">
                        {modalHeading}
                      </h1>
                      <section className="pt-2">
                        <button type="button" onClick={onClose}>
                          <CloseIcon className="fill-sol-300 " />
                        </button>
                      </section>
                    </section>
                    <Formik
                      initialValues={creditCardInitialValues}
                      validationSchema={validationSchema}
                      onSubmit={(values) => handleEditProfile(values)}
                    >
                      {(formik) => (
                        <Form>
                          <FormEventsAnalyticsTracker formName={modalHeading} />
                          <section className="grid gap-y-5">
                            <section className="w-full">
                              <label
                                htmlFor="creditCardNumber"
                                className="requiredLabel mb-1 flex text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).creditCardNumber}
                                <span className="ml-2"></span>{' '}
                              </label>
                              <section className="relative">
                                <Field
                                  type="text"
                                  name="creditCardNumber"
                                  id="creditCardNumber"
                                  onBlur={formik.handleBlur}
                                  className={`input-text-primary !w-full pl-10 ${
                                    formik.errors?.creditCardNumber && formik.touched?.creditCardNumber
                                      ? '!border-red-600'
                                      : ''
                                  }`}
                                />
                                <CreditCardIcon className="absolute left-[10px] top-[13px] fill-sol-300" />
                              </section>
                              {formik.errors.creditCardNumber && formik.touched.creditCardNumber && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                  {formik.errors.creditCardNumber}
                                </p>
                              )}
                            </section>
                            <section className="mb-4 flex gap-3">
                              <AmexCard />
                              <DiscoverCard />
                              <MasterCard />
                              <VisaCard />
                            </section>
                            <section className="w-full">
                              <section className="flex items-center justify-between">
                                <label
                                  htmlFor="nameOnCard"
                                  className="requiredLabel mb-1 text-sm font-semibold leading-[17px] text-sol-300"
                                >
                                  {mapLocaleToMeaningfulFormat(router.locale).nameOnCard}
                                  <span className="ml-2"></span>{' '}
                                </label>
                              </section>
                              <Field
                                type="text"
                                name="nameOnCard"
                                id="nameOnCard"
                                onBlur={formik.handleBlur}
                                className={`input-text-primary mt-1 !w-full ${
                                  formik.errors?.nameOnCard && formik.touched?.nameOnCard ? '!border-red-600' : ''
                                }`}
                              />
                              {formik.errors.nameOnCard && formik.touched.nameOnCard && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                  {formik.errors.nameOnCard}
                                </p>
                              )}
                            </section>
                            <section className="flex gap-x-4 lg:grid lg:grid-cols-2 lg:gap-x-10">
                              <section className="w-full">
                                <label
                                  htmlFor="expirationDate"
                                  className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                                >
                                  {mapLocaleToMeaningfulFormat(router.locale).expirationDate}
                                  <span className="ml-1"></span>{' '}
                                </label>
                                <Field
                                  name="expirationDate"
                                  onChange={formik?.handleChange}
                                  onBlur={formik?.handleBlur}
                                  value={formik?.values?.expirationDate ?? creditCardInitialValues?.expirationDate}
                                >
                                  {({ form: { errors, touched } }) => (
                                    <Input
                                      id="expirationDate"
                                      type="text"
                                      mask={'99/99'}
                                      maskChar={null}
                                      placeholder="mm/yy"
                                      onChange={formik?.handleChange}
                                      onBlur={formik?.handleBlur}
                                      value={formik.values.expirationDate ?? creditCardInitialValues?.expirationDate}
                                      className={`input-text-primary mt-1 !w-full ${
                                        errors?.expirationDate && touched?.expirationDate ? '!border-red-600' : ''
                                      }`}
                                    />
                                  )}
                                </Field>
                                {formik.errors.expirationDate && formik.touched.expirationDate && (
                                  <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                    {formik.errors.expirationDate}
                                  </p>
                                )}
                              </section>
                              <section className="hidden w-[115px]">
                                <label
                                  htmlFor="extension"
                                  className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                                >
                                  {mapLocaleToMeaningfulFormat(router.locale).cVV}
                                  <span className="mb-4 ml-1"></span>{' '}
                                </label>

                                <Field
                                  type="text"
                                  name="extension"
                                  id="extension"
                                  className={`input-text-primary mt-1 !w-full`}
                                />
                              </section>
                            </section>
                            <section className="mt-4 flex items-center gap-4">
                              <Field
                                type="checkbox"
                                name="isDefaultCard"
                                id="checkbox"
                                className="h-4 w-4 appearance-none rounded-[2px] border-none border-sol-300 bg-transparent text-white ring-2 ring-gray-300  focus:ring-sol-300"
                              />
                              <label htmlFor="checkbox" className="text-sm leading-[17px] text-sol-300">
                                {mapLocaleToMeaningfulFormat(router.locale).setAsDefaultCreditCard}
                              </label>
                            </section>
                            <section className="flex flex-col items-center gap-0 md:flex-row md:gap-6">
                              <button
                                type="submit"
                                id="creditCard"
                                className={`btn-primary-small flex h-[44px] w-full items-center justify-center px-8 md:w-auto ${
                                  Object.keys(formik.errors).length === 0 ? '' : 'cursor-not-allowed'
                                }`}
                              >
                                {loading ? (
                                  <CustomSpinner text={mapLocaleToMeaningfulFormat(router.locale).processing} />
                                ) : (
                                  primaryBtnContent
                                )}
                              </button>
                              <button
                                type="button"
                                id="cancelAddCreditCard"
                                onClick={onClose}
                                className="btn-cancel flex h-[44px] w-full items-center justify-center underline underline-offset-2 md:w-auto"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).cancel}
                              </button>
                            </section>
                          </section>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default CreditCard;
