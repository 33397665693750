import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { sentenceCase } from 'converting-case';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { useCurrentRefinements, useSortBy, useStats } from 'react-instantsearch';
import CloseIcon from 'components/icons/sol/close';
import DownArrowIcon from 'components/icons/sol/down-icon';
import RightArrowIcon from 'components/icons/sol/icon-right-arrow';
import { useIsMounted } from 'helpers/hooks/useIsMounted';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';
import Spinner from '../../notification/spinner';
import { AlgoliaConfig } from '../plp';
import ProductFilterNav from './filter-nav';
import ProductFilterNavV7 from './filter-nav-v7';
import FilterTrail from './filter-trail';
import FilterTrailV7 from './filter-trail-v7';
import PlpSortBy from './plp-sort-by';
import ProductListItem from './product-list-item';
import { RouteHelpers } from 'helpers/routeHelpers';

const ProductList = ({
  data = null,
  facetsDisplay = null,
  algoliaData = null,
  currentCat = null,
  sortByData = [],
  downrodsContent = [],
  setIsFiltersApplied,
  canResetFilters,
  setCanResetFilters,
  isClearanceCategory,
  CustomSortBy = null,
  setShowNoSearchFound = null,
  setSeoVariant = null,
  isSearchComponent = false,
  setCurrentPageNo,
}) => {
  const algoliaConfig: AlgoliaConfig = algoliaData;
  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);

  const { items: currentSelectedRefinements } = useCurrentRefinements();

  const [showFilterTrail, setShowFilterTrail] = useState(false);

  const [isOpenFilterNav, setIsOpenFilterNav] = useState(false);
  const [sortByOptions, setSortByOptions] = useState([]);
  const isMounted = useIsMounted();

  let title = data.route == 'category' ? data?.categoryData?.name : data.title;
  title = sentenceCase(title ? title : '');

  const { q } = router.query;
  if (q) {
    title = '';
  }

  // useEffect(() => {
  //   document.body.style.overflow = 'hidden';
  //   const unlockScroll = () => {
  //     document.body.style.overflow = 'auto';
  //   };
  //   setTimeout(() => {
  //     unlockScroll();
  //     if (localStorage.getItem('pdpRoutePath') !== null) {
  //       window.scrollTo({ top: parseInt(localStorage.getItem('scrollPosition')) });
  //     }
  //     localStorage.removeItem('scrollPosition');
  //     localStorage.removeItem('pdpRoutePath');
  //   }, 2500);
  //   localStorage.removeItem('plpUrl');
  //   return () => {
  //     unlockScroll();
  //   };
  // }, []);

  useEffect(() => {
    const tmpSortByOptions = sortByData?.map((item) => {
      return {
        label: item?.optionName,
        value: item?.optionIndex.replace('${env}', algoliaConfig?.sortAttributes?.indexPrefix),
        paramName: item?.paramName,
      };
    });

    setSortByOptions(tmpSortByOptions);
  }, []);

  useEffect(() => {
    if (isMounted) {
      const originalPath = window.location.origin + window.location.pathname;
      const queryParams = {};

      const parseUrl = RouteHelpers.decodeToObj(window.location.search);

      const queryPrefix = RouteHelpers.encodeToURL(parseUrl);

      if (currentSelectedRefinements && currentSelectedRefinements.length) {
        setShowFilterTrail(true);
      }
      if (currentSelectedRefinements && currentSelectedRefinements.length == 0 && isMounted) {
        setSeoVariant('');
        setShowFilterTrail(false);
        if (!isEmpty(window.location.search) && window.location.search != queryPrefix && isMounted) {
          window.location.href = `${originalPath}${queryPrefix}`;
        }
      }
    }
  }, [currentSelectedRefinements]);

  return (
    <>
      <section>
        <section className="sticky top-[86px] z-[1] flex gap-10 bg-white pt-4 lg:static lg:pt-0">
          <section className="hidden w-[211px] shrink-0 lg:block"></section>
          <section className="w-full">
            <section className="mb-6 flex flex-col justify-between lg:flex-row lg:gap-10">
              <section>{showFilterTrail && <FilterTrailV7 title={title} facetsDisplay={facetsDisplay} />}</section>

              <section className="shrink-0">
                <section className="grid grid-cols-2 gap-x-5 lg:flex lg:justify-between">
                  {!isDesktop && (
                    <button
                      id="filter"
                      type="button"
                      className="flex h-11 w-full cursor-default items-center justify-between rounded-[6px] border border-[#d9d9d9] bg-white p-3 text-sm leading-[17px] text-sol-300 lg:hidden lg:w-[186px]"
                      onClick={() => setIsOpenFilterNav(true)}
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).filter}
                      <DownArrowIcon className="h-[12px] w-[13px] fill-sol-300" />
                    </button>
                  )}
                  <section className="sortByBtn relative w-full cursor-pointer lg:w-[186px]">
                    {isMounted && (
                      <CustomSortBy
                        className="relative z-[2] cursor-pointer"
                        key={'custom-sort-by'}
                        items={sortByOptions}
                        initialIndex={'products_dev'}
                      />
                    )}
                    <DownArrowIcon className="absolute top-[16px] right-[15px] h-[12px] w-[13px] cursor-pointer fill-sol-300" />
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
        <section className="flex gap-10">
          {isDesktop && isMounted && facetsDisplay && facetsDisplay.length && (
            <section className="hidden w-[211px] shrink-0 lg:block">
              <ProductFilterNavV7
                title={title}
                facetsDisplay={facetsDisplay}
                setIsFiltersApplied={setIsFiltersApplied}
                canResetFilters={canResetFilters}
                setCanResetFilters={setCanResetFilters}
                algoliaConfig={algoliaConfig}
                setShowNoSearchFound={setShowNoSearchFound}
              />
            </section>
          )}
          <section className="w-full">
            <ProductListItem
              data={data}
              downrodsContent={downrodsContent}
              currentCat={currentCat}
              isClearanceCategory={isClearanceCategory}
              isSearchComponent={isSearchComponent}
              setCurrentPageNo={setCurrentPageNo}
            />
          </section>
        </section>
      </section>

      {isOpenFilterNav && (
        <section
          className="fixed top-0 left-0 z-10 flex h-[100vh] w-full bg-[#2B2B2B] opacity-90 lg:hidden"
          onClick={() => setIsOpenFilterNav(false)}
        >
          <section className="absolute top-0 right-0 mx-auto mt-6 flex w-[10vw] items-center justify-center">
            <CloseIcon className="fill-white" />
          </section>
        </section>
      )}
      {!isDesktop && (typeof window !== 'undefined' ? window.innerWidth < 1025 : true) && (
        <section
          style={{ width: '90vw', transition: 'all 0.3s ease-in-out' }}
          className={
            isOpenFilterNav
              ? 'fixed top-0 left-0 z-20 flex h-full w-full translate-x-0 lg:hidden'
              : 'absolute top-0 left-0 z-20 flex h-full w-full -translate-x-full'
          }
        >
          <section className="h-full w-full bg-white">
            <section className="bg-[#f2f2f2] p-5">
              <strong className="text-sm font-semibold leading-[17px] text-black-900">
                {mapLocaleToMeaningfulFormat(router.locale).filter}
              </strong>
            </section>
            <section className="max-h-[calc(100%_-_57px)] overflow-auto">
              <ProductFilterNavV7
                isClearItems={false}
                title={title}
                facetsDisplay={facetsDisplay}
                setIsFiltersApplied={setIsFiltersApplied}
                canResetFilters={canResetFilters}
                setCanResetFilters={setCanResetFilters}
                algoliaConfig={algoliaConfig}
              />
            </section>
          </section>
        </section>
      )}
    </>
  );
};

export default React.memo(ProductList);
