import React, { useState, Fragment, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Listbox, Transition } from '@headlessui/react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import EmailIcon from 'components/icons/email-icon';
import { cartQuantityTotal } from 'helpers/cartQuantityTotal';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
// import { listrakSCAEvent } from 'helpers/listrakHelper';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import { triggerMiniCart } from 'helpers/utils/triggerMiniCart';
import { isWishlistIsValid, validateWishlistItemsQty } from 'helpers/utils/validateCart';
import { useWishlist, useCart, useAccount } from 'frontastic';
import AccountNav from '../account/account-nav';
import ContinueShoppingButton from '../account/continueShoppingButton';
import Breadcrumb from '../cms/Breadcrumb';
import WishlistLineItem from '../line-Item';
import ListShareModal from './list-share-modal';
import { gtmEventAddToCart } from 'helpers/utils/gtm-events/gtmEcommerce';

/* TODO STATIC - this will be replaced with dynamic on later */
const dropDownData = [{ name: 'All Items' }];
function MyList() {
  const [listShareSelected, setListShareSelected] = useState(dropDownData[0]);
  const [showAllItem, setShowAllItem] = useState(dropDownData[0]);
  const [isDesktop] = useMediaQuery(desktop);
  const [filterItemArrow, setFilterItemArrow] = useState(false);
  const [shareListModal, setShareListModal] = useState(false);
  const [copyURLData, setCopyURLData] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('');
  const { data: wishlist, updateLineItem, removeLineItem } = useWishlist();
  const { data: cart, addShoppingList } = useCart();
  const isWishlistValid = isWishlistIsValid(wishlist);
  const [lineItemQuantityDisabled, setLineItemQuantityDisabled] = useState(false);
  const isWishlistAllItemsValid = validateWishlistItemsQty(wishlist, cart);
  useEffect(() => {
    setCurrentUrl(`${window?.location?.origin}${routerURL.shareMyList}/${wishlist?.wishlistId}`);
  }, []);

  function handleFilterItemDropdown() {
    setFilterItemArrow(!filterItemArrow);
  }

  const router = useRouter();

  const openShareListModal = () => {
    setShareListModal(true);
  };

  const closeShareListModal = () => {
    setShareListModal(false);
    setCopyURLData(false);
  };

  const copyURL = () => {
    setCopyURLData(true);
  };
  const { account } = useAccount();
  const addShoppingListToCart = async () => {
    const updatedCart = await addShoppingList(wishlist?.wishlistId);
    triggerMiniCart();
    gtmEventAddToCart({ cart: updatedCart, wishlistItems: wishlist?.lineItems, account });
    // listrakSCAEvent(updatedCart, account);
  };

  return (
    <>
      <Breadcrumb breadcrumLevelLink={false} activePage={mapLocaleToMeaningfulFormat(router.locale).myList} />
      <section className="mx-auto max-w-screen-8xl px-4 pb-10">
        <section>{!isDesktop && <AccountNav />}</section>
        <section>
          <h1 className="mt-6 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] lg:mt-2">
            {mapLocaleToMeaningfulFormat(router.locale).myList}
          </h1>
          <p className="mt-4 text-center text-sm capitalize">
            {wishlist?.lineItems?.length > 0 ? cartQuantityTotal(wishlist?.lineItems) : 0}{' '}
            {mapLocaleToMeaningfulFormat(router.locale).items}
          </p>
        </section>
        {!isWishlistValid && (
          <p className="mt-4 text-center font-medium text-red-600 lg:pr-4 lg:pb-8">
            {' '}
            {mapLocaleToMeaningfulFormat(router.locale).invalidWishlist}
          </p>
        )}
        {wishlist?.lineItems?.length === 0 ? (
          ''
        ) : (
          <section className="mt-6 lg:flex lg:justify-end">
            <section className="flex flex-col justify-between lg:w-[calc(100%_-_233px)] lg:flex-row">
              <section className="shrink-0 lg:mt-4">
                <button className="mb-4 flex items-center text-left text-sm lg:mb-0" onClick={openShareListModal}>
                  <EmailIcon className="mr-2" />
                  {mapLocaleToMeaningfulFormat(router.locale).shareYourList}
                </button>
              </section>
              <section className="w-full flex-col justify-end lg:flex lg:flex-row">
                <section className="relative w-full lg:mr-5 lg:w-[196px]">
                  <Listbox value={listShareSelected} onChange={setListShareSelected}>
                    <Listbox.Button className="relative w-full cursor-default rounded border bg-white py-[11px] pl-3 pr-10 text-left sm:text-sm">
                      <em className="block truncate not-italic">{listShareSelected.name}</em>
                      <em className="absolute inset-y-0 right-0 flex items-center pr-2 not-italic">
                        {!filterItemArrow ? (
                          <section className="my-auto mr-3">
                            <MdOutlineKeyboardArrowDown
                              onClick={handleFilterItemDropdown}
                              className="text-2xl text-sol-300"
                            />
                          </section>
                        ) : (
                          <section className="my-auto mr-3">
                            <MdOutlineKeyboardArrowUp
                              onClick={handleFilterItemDropdown}
                              className="text-2xl text-sol-300"
                            />
                          </section>
                        )}
                      </em>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="ring-black absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm">
                        {dropDownData.map((dropdownDataList, dataID) => (
                          <Listbox.Option
                            key={dataID}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active && 'bg-gray-200 text-sol-300 '
                              }`
                            }
                            value={dropdownDataList}
                          >
                            {({ selected }) => (
                              <>
                                <em className={`block truncate not-italic ${selected ? 'font-medium' : 'font-normal'}`}>
                                  {dropdownDataList.name}
                                </em>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </Listbox>
                </section>
                <button
                  type="button"
                  id="addItemToList"
                  className={`btn-primary-small mt-4 lg:mt-0 ${
                    !isWishlistValid || !isWishlistAllItemsValid
                      ? 'cursor-not-allowed opacity-50 focus:outline-offset-2'
                      : ''
                  }`}
                  onClick={() => (isWishlistValid && isWishlistAllItemsValid ? addShoppingListToCart() : null)}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).addAllToCart}
                </button>
              </section>
            </section>
          </section>
        )}
        <section className="mt-5 flex-col gap-5 md:mt-4 lg:flex lg:flex-row">
          <section>{isDesktop && <AccountNav />}</section>
          <section className="w-full">
            <ul>
              {wishlist?.lineItems?.length === 0 && (
                <p className="w-full rounded border px-5 py-6 text-sm font-normal text-sol-300">
                  {mapLocaleToMeaningfulFormat(router.locale).yourListIsEmpty}
                </p>
              )}
              {wishlist?.lineItems?.map((wishlistItem, i) => (
                <li
                  key={i}
                  className="mb-5 mt-3 w-full rounded border px-5 py-6 pb-5 text-sm font-normal text-sol-300 lg:mt-0 lg:py-2"
                >
                  {!wishlistItem?.isAvailable && (
                    <p className="mt-4 text-left font-medium text-red-600 lg:pr-4 lg:pb-4">
                      {mapLocaleToMeaningfulFormat(router.locale).thisProductNoLongerAvailable}
                    </p>
                  )}
                  <WishlistLineItem
                    componentIdentifier={`myList`}
                    lineItem={wishlistItem}
                    showQuantityInput={true}
                    showAddToCartButton={true}
                    showRemoveButton={true}
                    updateLineItem={updateLineItem}
                    removeLineItem={removeLineItem}
                    showInventory={true}
                    lineItemQuantityDisabled={lineItemQuantityDisabled}
                    setLineItemQuantityDisabled={setLineItemQuantityDisabled}
                    wishlist={wishlist}
                    isFromOrderDetails={false}
                    index={i}
                  />
                </li>
              ))}
            </ul>
          </section>
          <section>{!isDesktop && <ContinueShoppingButton />}</section>
        </section>
        <ListShareModal
          modelOpen={shareListModal}
          onClose={closeShareListModal}
          listHeading={mapLocaleToMeaningfulFormat(router.locale).shareMyList}
          listShareByLink={mapLocaleToMeaningfulFormat(router.locale).shareByLink}
          listShareByLinkSubheading={mapLocaleToMeaningfulFormat(router.locale).anyoneCanViewList}
          listShareByEmail={mapLocaleToMeaningfulFormat(router.locale).shareByEmail}
          listShareByEmailSubheading={mapLocaleToMeaningfulFormat(router.locale).recipientsCanViewList}
          url={currentUrl}
          copyURL={copyURL}
          btnContent={mapLocaleToMeaningfulFormat(router.locale).copy}
          linkCopy={copyURLData}
          shareButtonContent={mapLocaleToMeaningfulFormat(router.locale).share}
          cancelButtonContent={mapLocaleToMeaningfulFormat(router.locale).cancel}
        />
      </section>
    </>
  );
}

export default MyList;
