import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Account } from '@Types/account/Account';
import { Address as AddressType } from '@Types/account/Address';
import CheckIcon from 'components/icons/sol/checked-icon';
import CustomLoader from 'components/icons/sol/custom-loader';
import CustomSpinner from 'components/icons/sol/custom-spinner';
import Spinner from 'components/sol-ui/notification/spinner';
import {
  SHIPPING_ADDRESS_NOT_VALID,
  BILLING_ADDRESS_NOT_VALID,
  CART_ADDRESS_UPDATED,
  SERVER_ERROR,
} from 'helpers/constants/messages';
// import { listrakSCAEvent } from 'helpers/listrakHelper';
import { shippingAddress } from 'helpers/mocks/mockData';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { useCart, useAccount } from 'frontastic';
import AddressSelect from '../../checkout-form/address-select';
import SuggestAddress from '../validate-address';

interface CheckoutAddressProps {
  account: Account;
  addAddress: (address: AddressType) => Promise<Account>;
  setIsLoaded: any;
  isLoaded: any;
}

const CheckoutAuthAddresses: React.FC<CheckoutAddressProps> = ({ account, addAddress, setIsLoaded, isLoaded }) => {
  const { account: userAccount, getCustomerAddresses, loggedIn } = useAccount();
  const [enableCheckoutButton, setEnableCheckoutButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [useSameAsShipping, setUseSameAsShipping] = useState(true);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedShipping, setSelectedShipping] = useState<AddressType>(undefined);
  const [isShippingChanged, setIsShippingChanged] = useState<boolean>(undefined);
  const [selectedBilling, setSelectedBilling] = useState<AddressType>(undefined);
  const { showNotification } = useNotification();
  const [addresses, setAddresses] = useState([]);
  const [validateShipping, setValidateShipping] = useState<any>();
  const [validateBilling, setValidateBilling] = useState<any>();
  const [isShippingValid, setIsShippingValid] = useState(false);
  const [isBillingValid, setIsBillingValid] = useState(false);
  const [isAddressesLoading, setIsAddressesloading] = useState(true);

  const { validateAddress } = useAccount();
  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };
  const router = useRouter();
  const { data: cart, updateCart } = useCart();
  const setReload = () => {
    setLoading(true);
  };

  const setAddressesData = useCallback(() => {
    if (loggedIn) {
      getCustomerAddresses().then((data) => {
        if (data && data?.length > 0) {
          data = data?.sort((address) => (address.isDefaultBillingAddress ? -1 : 1));
          setAddresses(data);
          setIsLoaded(false);
          setIsAddressesloading(false);
        } else {
          setIsAddressesloading(false);
          setAddresses([]);
        }
      });
    } else {
      setAddresses([]);
      setIsLoaded(false);
      setIsAddressesloading(false);
    }
  }, []);

  useEffect(() => {
    setAddressesData();
  }, []);

  useEffect(() => {
    if (selectedShipping === undefined || addresses) {
      setSelectedShipping(addresses.find((address) => address.isDefaultShippingAddress));
    }
    if (selectedBilling === undefined || addresses) {
      setSelectedBilling(addresses.find((address) => address.isDefaultBillingAddress));
    }
  }, [addresses, account]);

  useEffect(() => {
    if (useSameAsShipping) {
      setSelectedBilling(selectedShipping);
    }
  }, [useSameAsShipping]);

  useEffect(() => {
    if (selectedShipping?.addressId && selectedBilling?.addressId) {
      setEnableCheckoutButton(!enableCheckoutButton);
    }
    if (selectedShipping && useSameAsShipping) {
      setSelectedBilling(selectedShipping);
    }
    if (selectedBilling && !useSameAsShipping) {
      setEnableCheckoutButton(true);
    }

    if (selectedBilling && selectedShipping) {
      setEnableCheckoutButton(true);
    } else {
      setEnableCheckoutButton(false);
    }
  }, [selectedShipping, selectedBilling]);

  useEffect(() => {
    if (cart?.billingAddress?.addressId) {
      if (addresses.find((address) => address.addressId === cart?.billingAddress?.addressId)) {
        setSelectedBilling(cart?.billingAddress);
      }
    }
    if (cart?.shippingAddress?.addressId) {
      if (addresses.find((address) => address.addressId === cart?.shippingAddress?.addressId)) {
        setSelectedShipping(cart?.shippingAddress);
      }
    }
    if (cart?.shippingAddress?.addressId && cart?.billingAddress?.addressId) {
      cart?.shippingAddress?.addressId === cart?.billingAddress?.addressId
        ? setUseSameAsShipping(true)
        : setUseSameAsShipping(false);
      if (cart?.email) {
        setEnableCheckoutButton(true);
      }
    }
  }, [cart]);

  const handleUpdateCart = async (e) => {
    e.preventDefault();
    setLoading(true);

    const validateShipping = await validateAddress(selectedShipping);
    setValidateShipping(validateShipping);
    if (!validateShipping.isValid) {
      const validateBilling = await validateAddress(selectedBilling);
      setValidateBilling(validateBilling);
      setCreateModalOpen(true);
      setLoading(false);
      return;
    }

    const validateBilling = await validateAddress(selectedBilling);
    setValidateBilling(validateBilling);
    if (!validateBilling.isValid) {
      setCreateModalOpen(true);
      setLoading(false);
      return;
    }

    function isAddressValid(validateAddress: any, selectedAddress: any): boolean {
      if (
        validateAddress?.city?.toLowerCase() === selectedAddress?.city?.toLowerCase() &&
        validateAddress?.state?.toLowerCase() === selectedAddress?.state?.toLowerCase() &&
        validateAddress?.country?.toLowerCase() === selectedAddress?.country?.toLowerCase() &&
        validateAddress?.postalCode === selectedAddress?.postalCode &&
        validateAddress?.streetName?.toLowerCase() === selectedAddress?.streetName?.toLowerCase() &&
        validateAddress?.streetNumber === selectedAddress?.streetNumber
      ) {
        return true;
      }
      return false;
    }
    const isShippingValid = isAddressValid(validateShipping?.address, selectedShipping);
    setIsShippingValid(isShippingValid);

    const isBillingValid = isAddressValid(validateBilling?.address, selectedBilling);
    setIsBillingValid(isBillingValid);

    if (!isShippingValid || !isBillingValid) {
      setCreateModalOpen(true);
      setLoading(false);
    }

    if (isShippingValid && isBillingValid) {
      try {
        const updatedCart = await updateCart({
          account: { email: account?.email },
          shipping: selectedShipping,
          billing: useSameAsShipping ? selectedShipping : selectedBilling,
        });
        // listrakSCAEvent(updatedCart, account);
        if (updatedCart?.cartId) {
          //showNotification(CART_ADDRESS_UPDATED, 'success');
        } else {
          //showNotification(SERVER_ERROR, 'error');
        }
        router.push(routerURL.checkoutPayment);
      } catch (error) {
        showNotification(SERVER_ERROR, 'error');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    updateCartAddress();
  }, [isShippingChanged, addresses]);

  const updateCartAddress = async () => {
    setLoading(true);

    const defaultShippingAddress = addresses.find((address) => address.isDefaultShippingAddress);

    if (addresses?.length) {
      if ((selectedShipping && selectedBilling) || defaultShippingAddress) {
        try {
          const cart = await updateCart({
            account: { email: account?.email },
            shipping: selectedShipping || defaultShippingAddress,
            billing: useSameAsShipping ? selectedShipping ?? defaultShippingAddress : selectedBilling,
          });
        } catch (error) {
          showNotification(SERVER_ERROR, 'error');
        }
      }
    }

    setLoading(false);
  };

  return !isLoaded ? (
    <>
      <section className="rounded-md border p-5">
        <p className="text-lg font-semibold text-sol-300">
          {mapLocaleToMeaningfulFormat(router.locale).shippingAddress}
        </p>
        <AddressSelect
          addAddress={addAddress}
          addresses={addresses}
          selectedAddress={selectedShipping}
          setSelectedAddress={setSelectedShipping}
          getCustomerAddresses={setAddressesData}
          isAddressesLoading={isAddressesLoading}
          isShippingChanged={isShippingChanged}
          setIsShippingChanged={setIsShippingChanged}
          isBillingAddress={false}
        />
      </section>
      <section className="mt-4">
        <section className="rounded-md border p-5">
          <p className="text-lg font-semibold text-sol-300">
            {mapLocaleToMeaningfulFormat(router.locale).billingAddress}
          </p>
          <section className="my-2 flex items-center">
            {/* <input
              type="checkbox"
              id="sameBillingAddress"
              checked={useSameAsShipping}
              onClick={() => setUseSameAsShipping(!useSameAsShipping)}
              className="mr-3"
            /> */}
            <label className="flex cursor-pointer items-center gap-2">
              <section className="relative">
                <input
                  type="checkbox"
                  id="sameBillingAddress"
                  checked={useSameAsShipping}
                  onChange={() => setUseSameAsShipping(!useSameAsShipping)}
                  className="filterNavCheckbox h-4 w-4 cursor-pointer rounded-[2px] border !border-black-900 text-white shadow-none focus:shadow-none focus:outline-none focus:ring-0"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="7"
                  viewBox="0 0 8 7"
                  fill="none"
                  className={`checked-icon absolute top-[8px] left-1/2 hidden h-2 w-2 -translate-x-1/2

                  `}
                >
                  <path
                    d="M2.134 5.95163C2.2236 6.04791 2.3302 6.12432 2.44766 6.17647C2.56511 6.22862 2.69108 6.25547 2.81832 6.25547C2.94556 6.25547 3.07154 6.22862 3.18899 6.17647C3.30644 6.12432 3.41304 6.04791 3.50264 5.95163L7.43506 1.7607C7.61655 1.56727 7.71851 1.30493 7.71851 1.03139C7.71851 0.757849 7.61655 0.495511 7.43506 0.302087C7.25356 0.108664 7.00741 0 6.75074 0C6.49407 0 6.24791 0.108664 6.06642 0.302087L2.81832 3.77399L1.65209 2.52082C1.4706 2.32739 1.22444 2.21873 0.967771 2.21873C0.711102 2.21873 0.464946 2.32739 0.283454 2.52082C0.101961 2.71424 0 2.97658 0 3.25012C0 3.52366 0.101961 3.786 0.283454 3.97943L2.134 5.95163Z"
                    fill="#222222"
                  ></path>
                </svg>
              </section>

              <p className="pl-4 text-sm">{mapLocaleToMeaningfulFormat(router.locale).useSameAsShipping}</p>
            </label>
          </section>
          {!useSameAsShipping && (
            <AddressSelect
              addAddress={addAddress}
              addresses={addresses}
              selectedAddress={selectedBilling}
              setSelectedAddress={setSelectedBilling}
              getCustomerAddresses={setAddressesData}
              isAddressesLoading={isAddressesLoading}
              isShippingChanged={isShippingChanged}
              setIsShippingChanged={setIsShippingChanged}
              isBillingAddress={true}
            />
          )}
        </section>
      </section>
      <button
        type="button"
        id="authAddress"
        onClick={(e) => enableCheckoutButton && handleUpdateCart(e)}
        className={`btn-primary-large mt-6 w-full disabled:opacity-75 md:w-[294px] ${
          !enableCheckoutButton && 'cursor-not-allowed opacity-50'
        }`}
      >
        {loading ? (
          <section className="flex items-center justify-center gap-3">
            {' '}
            <CustomSpinner text={mapLocaleToMeaningfulFormat(router.locale).processing} />
          </section>
        ) : (
          mapLocaleToMeaningfulFormat(router.locale).continueToPayment
        )}
      </button>
      <SuggestAddress
        open={createModalOpen}
        onClose={closeCreateModal}
        validateShipping={validateShipping}
        validateBilling={validateBilling}
        selectedShipping={selectedShipping}
        selectedBilling={selectedBilling}
        setSelectedAddress={setSelectedShipping}
        setBillingAddress={setSelectedBilling}
        setloading={setReload}
        isSameAddress={useSameAsShipping}
        isShippingValid={isShippingValid}
        isBillingValid={isBillingValid}
        account={account}
      />
    </>
  ) : (
    <></>
  );
};
export default CheckoutAuthAddresses;
