import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import useFormInteraction from 'helpers/hooks/useFormInteraction';
import { gtmFormErrorInteraction } from 'helpers/utils/gtm-events/gtmForm';

interface Props {
  formName: string;
}
export default function FormEventsAnalyticsTracker({ formName }: Props) {
  const { handleStartInteraction } = useFormInteraction(formName);
  const { values, initialValues, errors, touched } = useFormikContext();
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);

  useEffect(() => {
    Object.keys(errors).forEach((fieldName) => {
      /**
       * Validate if error was not already store, in that way we prevent that multiple times trigger the event
       * Validate if field was update or unfocused by using touched[fieldName]
       */
      if (errors[fieldName] && !fieldsWithError.includes(fieldName) && touched[fieldName]) {
        gtmFormErrorInteraction({
          error_field: fieldName,
          error_message: errors[fieldName],
          form_name: formName,
        });
        setFieldsWithError((prev) => [...prev, fieldName]);
      }
    });
  }, [errors, fieldsWithError, touched]);

  useEffect(() => {
    if (JSON.stringify(values) !== JSON.stringify(initialValues)) {
      handleStartInteraction();
    }
  }, [values, initialValues]);

  return <></>;
}
