/**
 * Google Tag Manager Interaction Events
 */

import { gtmTrackEvent, gtmTrackEventError } from 'helpers/utils/gtm-events/gtmTrackEvent';

interface MenuNavigationEvent {
  click_location: string;
  click_name: string;
}

export const gtmMenuNavigation = (event: MenuNavigationEvent) => {
  const eventKey = 'menu_navigation';

  try {
    const eventObject = {
      interaction: event,
    };

    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface ZeroSearchResultsEvent {
  search_term: string;
}
export const gtmZeroSearchResults = (event: ZeroSearchResultsEvent) => {
  const eventKey = 'zero_search_results';
  try {
    const eventObject = {
      interaction: event,
    };
    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface SocialMediaShareEvent {
  method: string;
  content_type: string;
  item_id: string;
}
export const gtmSocialMediaShare = (event: SocialMediaShareEvent) => {
  const eventKey = 'share';

  try {
    const eventObject = {
      interaction: event,
    };
    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface ApplyPromoCodeEvent {
  promo_code: string;
}
export const gtmApplyPromoCode = (event: ApplyPromoCodeEvent) => {
  const eventKey = 'apply_promo_code';
  try {
    const eventObject = {
      interaction: event,
    };
    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface BreadcrumbClickEvent {
  click_name: string;
}
export const gtmBreadcrumbClick = (event: BreadcrumbClickEvent) => {
  const eventKey = 'breadcrumb_click';

  try {
    const eventObject = {
      interaction: event,
    };
    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface ImageScrollEvent {
  click_action: string;
  item_id: string;
  media_name: string;
}
export const gtmImageScroll = (event: ImageScrollEvent) => {
  const eventKey = 'image_scroll';

  try {
    const eventObject = {
      interaction: event,
    };
    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface AddToRegistryEvent {
  currency: string;
  item_id: string;
  iten_name: string;
  price: number;
  quantity: number;
  value: number;
}

export const gtmAddToRegistry = (event: AddToRegistryEvent) => {
  const eventKey = 'add_to_registry';

  try {
    const eventObject = {
      ...event,
    };

    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};
interface ApplyFilterEvent {
  filter_type: string;
  selected_option: string;
}

export const gtmApplyFilter = (event: ApplyFilterEvent) => {
  const eventKey = 'apply_filter';

  try {
    const eventObject = {
      interaction: event,
    };

    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface PLPInteractionEvent {
  click_action: string;
}

export const gtmPLPInteraction = (event: PLPInteractionEvent) => {
  const eventKey = 'plp_interactions';

  try {
    const eventObject = {
      interaction: event,
    };

    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};
interface AccordionInteractionEvent {
  click_action: string;
  interaction_text: string;
  interaction_location: string;
}
export const gtmAccordionInteraction = (event: AccordionInteractionEvent) => {
  const eventKey = 'accordion_interaction';

  try {
    const eventObject = {
      interaction: event,
    };

    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};
