import { useState } from 'react';
import { useAccount } from 'frontastic';
import { StringHelpers } from 'helpers/stringHelpers';
import { gtmFormStartInteraction, gtmFormSubmit } from 'helpers/utils/gtm-events/gtmForm';

const useFormInteraction = (formName: string) => {
  const [hasInteracted, setHasInteracted] = useState(false);
  const { account } = useAccount();

  const handleStartInteraction = () => {
    if (!hasInteracted) {
      setHasInteracted(true);
      gtmFormStartInteraction({ form_name: formName });
    }
  };

  const handleSubmitInteraction = (newsletterOptIn: boolean = false, email?: string) => {
    gtmFormSubmit({
      form_name: formName,
      hashed_email: email ? StringHelpers.convertToSHA256Hash(email) : account?.emailHash || null,
      newsletter_opt_in: newsletterOptIn,
    });
  };

  return { hasInteracted, handleStartInteraction, handleSubmitInteraction };
};

export default useFormInteraction;
