import { gtmTrackEvent, gtmTrackEventError } from './gtmTrackEvent';

interface FormStartInteractionEvent {
  form_name: string;
}

export const gtmFormStartInteraction = (event: FormStartInteractionEvent) => {
  const eventKey = 'form_start';

  try {
    const eventObject = {
      ...event,
    };

    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface FormErrorInteractionEvent {
  form_name: string;
  error_field: string;
  error_message: string;
}
export const gtmFormErrorInteraction = (event: FormErrorInteractionEvent) => {
  const eventKey = 'form_errors';
  try {
    const eventObject = {
      ...event,
    };

    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface FormSubmitEvent {
  form_name: string;
  hashed_email: string;
  newsletter_opt_in: boolean;
}

export const gtmFormSubmit = (event: FormSubmitEvent) => {
  const eventKey = 'form_submit';

  try {
    const eventOjbect = {
      ...event,
    };

    gtmTrackEvent(eventKey, eventOjbect);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};
