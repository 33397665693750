import React, { useState, useRef, useEffect } from 'react';
import MinusIcon from 'components/icons/sol/collapse-minus-icon';
import PlusIcon from 'components/icons/sol/collapse-plus-icon';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { gtmAccordionInteraction } from 'helpers/utils/gtm-events/gtmInteraction';

type AccordionProps = {
  title: string;
  content?: any;
  isClearItems?: boolean;
  className?: string;
  defaultOpen?: boolean;
};
const ReviewAccordion = ({ title, content, isClearItems, className, defaultOpen }: AccordionProps) => {
  const [isDesktop] = useMediaQuery(desktop);
  const [isOpened, setOpened] = useState<boolean>(defaultOpen);
  const [height, setHeight] = useState<string>('0');
  const contentElement = useRef(null);

  useEffect(() => {
    if (isClearItems) {
      setOpened(false);
      setHeight('0px');
    }
  }, [isClearItems]);

  const filterAccordionOpening = () => {
    gtmAccordionInteraction({
      click_action: !isOpened ? 'expand' : 'collapse',
      interaction_text: title,
      interaction_location: null,
    });

    setOpened(!isOpened);
    setHeight(!isOpened ? `${contentElement?.current?.scrollHeight}px` : '0');
  };

  useEffect(() => {
    setOpened(defaultOpen);
    setHeight(defaultOpen ? `auto` : '0');
  }, []);

  return (
    <section className="py-8">
      <section>
        <section
          onClick={filterAccordionOpening}
          className={'text-black flex cursor-pointer items-center justify-between'}
        >
          <section className="relative flex w-full justify-between">
            <strong className="inline-block w-full text-[28px] font-semibold leading-[34px] text-[#222222]">
              {title}
            </strong>
            {isDesktop && (
              <>
                {!isOpened ? (
                  <section className="absolute left-1/2 top-[20%] flex h-[90px] -translate-x-1/2 -translate-y-1/2 overflow-hidden">
                    {content}
                  </section>
                ) : (
                  <></>
                )}
              </>
            )}
            <section className="cursor-pointer pt-1">
              {isOpened ? <MinusIcon className="fill-sol-300" /> : <PlusIcon className="fill-sol-300" />}
            </section>
          </section>
        </section>
        {!isDesktop && (
          <>
            {!isOpened ? (
              <section className="flex h-[90px] w-full justify-center overflow-hidden">{content}</section>
            ) : (
              <></>
            )}
          </>
        )}
      </section>
      <section ref={contentElement} style={{ height: height }} className="overflow-hidden transition-all duration-200">
        <section>{content}</section>
      </section>
    </section>
  );
};

export default ReviewAccordion;
