import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Account } from '@Types/account/Account';
import type { Address as AddressType } from '@Types/account/Address';
import { Country } from '@Types/account/Country';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomCheckBox from 'components/icons/sol/custom-checkbox';
import Spinner from 'components/sol-ui/notification/spinner';
import ReCaptcha from 'components/sol-ui/recaptcha';
import {
  BILLING_ADDRESS_NOT_VALID,
  CART_ADDRESS_UPDATED,
  SERVER_ERROR,
  SHIPPING_ADDRESS_NOT_VALID,
  CART_UPDATE_ERROR,
} from 'helpers/constants/messages';
// import { listrakSCAEvent } from 'helpers/listrakHelper';
import { useNotification } from 'helpers/notification/notificationContext';
import { StringHelpers } from 'helpers/stringHelpers';
import { DEFAULT_COUNTRY, DEFAULT_STATE } from 'helpers/utils/constant';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import {
  emailValidationSchema,
  shippingAddressValidationSchema,
  billingAddressValidationSchema,
} from 'helpers/utils/validationSchema/guest-checkout';
import { useAccount, useCart, useFreshdesk } from 'frontastic';
import { validateGuestCheckout } from 'frontastic/actions/account';
import AddressForm from '../../checkout-form/address-form';
import SuggestAddress from '../validate-address';
import FormEventsAnalyticsTracker from 'components/sol-ui/form-events-analytics-tracker';
import useFormInteraction from 'helpers/hooks/useFormInteraction';

interface GuestCheckoutAddressProps {
  account?: Account;
}

const CheckoutGuestAddresses: React.FC<GuestCheckoutAddressProps> = ({ account }) => {
  const router = useRouter();
  const { data: cart, updateCart } = useCart();
  const [useSameAsShipping, setUseSameAsShipping] = useState(
    JSON.stringify(cart?.shippingAddress) === JSON.stringify(cart?.billingAddress) ? true : false,
  );
  const [loading, setLoading] = useState(false);
  const [keepMe, setKeepMe] = useState(true);
  const [sendACatalog, setSendACatalog] = useState(true);
  const { showNotification } = useNotification();
  const [validationSchema, setValidationSchema] = useState(null);
  const { validateAddress, shippingLocations, updateSubscription } = useAccount();
  const [validateShipping, setValidateShipping] = useState<any>();
  const [validateBilling, setValidateBilling] = useState<any>();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [isShippingValid, setIsShippingValid] = useState(false);
  const [isBillingValid, setIsBillingValid] = useState(false);
  const [userExists, setUserExists] = useState(false); //setting false initially before user enters email
  const { createTicket } = useFreshdesk();
  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };
  const setReload = () => {
    setLoading(true);
  };
  const [selectedShipping, setSelectedShipping] = useState<AddressType>(undefined);
  const [selectedBilling, setSelectedBilling] = useState<AddressType>(undefined);

  const countries = Object.freeze(shippingLocations?.data) as Country[];
  const resetAddress: AddressType = {
    additionalAddressInfo: '',
    additionalStreetInfo: '',
    city: '',
    country: '',
    firstName: '',
    isDefaultBillingAddress: undefined,
    isDefaultShippingAddress: undefined,
    lastName: '',
    phone: '',
    postalCode: '',
    salutation: '',
    streetName: '',
    streetNumber: '',
    state: '',
    company: '',
    extension: '',
  };

  const initialValues = {
    email: cart?.email ?? '',
    shippingAddress: StringHelpers.phoneExtensionSplitter(cart?.shippingAddress) ?? resetAddress,
    billingAddress: StringHelpers.phoneExtensionSplitter(cart?.billingAddress) ?? resetAddress,
  };
  const [isRecaptchaVerified, setIsReCaptchaVerified] = useState(false);
  const [reCaptchaError, setReCaptchaError] = useState(false);
  const { handleSubmitInteraction } = useFormInteraction('Checkout');
  useEffect(() => {
    const validationSchema = Yup.object().shape({
      email: emailValidationSchema,
      shippingAddress: shippingAddressValidationSchema,
      billingAddress: useSameAsShipping ? null : billingAddressValidationSchema,
    });
    setValidationSchema(validationSchema);
  }, [useSameAsShipping]);

  const handleUpdateCart = async (values) => {
    setLoading(true);
    const validateShipping = await validateAddress(values?.shippingAddress);
    setValidateShipping(validateShipping);
    if (!validateShipping.isValid) {
      const validateBilling = await validateAddress(
        useSameAsShipping ? values?.shippingAddress : values?.billingAddress,
      );
      setValidateBilling(validateBilling);
      setCreateModalOpen(true);
      setLoading(false);
      return;
    }

    const validateBilling = await validateAddress(useSameAsShipping ? values?.shippingAddress : values?.billingAddress);
    setValidateBilling(validateBilling);
    if (!validateBilling.isValid) {
      setCreateModalOpen(true);
      setLoading(false);
      return;
    }

    function isAddressValid(validateAddress: any, selectedAddress: any): boolean {
      if (
        validateAddress?.city?.toLowerCase() === selectedAddress?.city?.toLowerCase() &&
        validateAddress?.state?.toLowerCase() === selectedAddress?.state?.toLowerCase() &&
        validateAddress?.country?.toLowerCase() === selectedAddress?.country?.toLowerCase() &&
        validateAddress?.postalCode === selectedAddress?.postalCode &&
        validateAddress?.streetName?.toLowerCase() === selectedAddress?.streetName?.toLowerCase() &&
        validateAddress?.streetNumber === selectedAddress?.streetNumber
      ) {
        return true;
      }
      return false;
    }
    const isShippingValid = isAddressValid(validateShipping?.address, values?.shippingAddress);
    setIsShippingValid(isShippingValid);

    const isBillingValid = isAddressValid(validateBilling?.address, values?.billingAddress);
    setIsBillingValid(isBillingValid);

    if (
      validateShipping.isValid &&
      validateBilling.isValid &&
      (useSameAsShipping ? !isShippingValid : !isShippingValid || !isBillingValid)
    ) {
      setCreateModalOpen(true);
      setLoading(false);
    }

    if (useSameAsShipping ? isShippingValid : isShippingValid && isBillingValid) {
      try {
        const updatedCart = await updateCart({
          account: { email: values?.email },
          shipping: StringHelpers.phoneExtensionMerger(values?.shippingAddress),
          billing: StringHelpers.phoneExtensionMerger(
            useSameAsShipping ? values?.shippingAddress : values?.billingAddress,
          ),
        });
        // listrakSCAEvent(updatedCart, account);
        if (updatedCart?.cartId) {
          // showNotification(CART_ADDRESS_UPDATED, 'success');
          if (keepMe) {
            const result = await updateSubscription({
              decoration_tips_ideas: true,
              new_catalog_previews: true,
              new_item_introductions: true,
              sales_specials: true,
              trending_looks_styles: true,
              email: values?.email,
              requestFrom: 'subscribe',
            });
          }

          if (sendACatalog) {
            await createTicket({
              email: values.email,
              custom_fields: {
                cf_firstname: values.shippingAddress.firstName,
                cf_lastname: values.shippingAddress.lastName,
                cf_streetname: values.shippingAddress.streetName,
                cf_streetnumber: values.shippingAddress.streetNumber,
                cf_city: values.shippingAddress.city,
                cf_state: values.shippingAddress.state,
                cf_country: values.shippingAddress.country,
                cf_phone: values.shippingAddress.phone,
                cf_postalcode: values.shippingAddress.postalCode,
                cf_referal: values.shippingAddress.referal,
                cf_viewandshoplatestcatalogonline: false,
                cf_subscribecatalogbymail: false,
                cf_subscribenewsletter: false,
              },
            });
          }
          handleSubmitInteraction(false, values.email);
          router.push(routerURL.checkoutPayment);
        } else {
          showNotification(CART_UPDATE_ERROR, 'error');
        }
      } catch (error) {
        showNotification(SERVER_ERROR, 'error');
      }
      setLoading(false);
    }
  };
  const handleSubmit = async (values) => {
    const cartValues = values;
    cartValues.shippingAddress.firstName = cartValues.shippingAddress.firstName.trim();
    cartValues.shippingAddress.lastName = cartValues.shippingAddress.lastName.trim();
    if (cartValues?.billingAddress?.firstName) {
      cartValues.billingAddress.firstName = cartValues.billingAddress.firstName.trim();
      cartValues.billingAddress.lastName = cartValues.billingAddress.lastName.trim();
    }
    await handleUpdateCart(cartValues);
  };
  const FormikFormSectionGroup = ({ formik }) => {
    const openBillingAddressForm = () => {
      setUseSameAsShipping(!useSameAsShipping);
      useSameAsShipping &&
        formik.setValues((values) => ({ ...values, billingAddress: formik?.values?.billingAddress || resetAddress }));
    };

    return (
      <>
        <section className="rounded border p-5">
          <p className="text-lg font-semibold text-sol-300">
            {mapLocaleToMeaningfulFormat(router.locale).emailAddress}
          </p>
          <section className="mt-2">
            <label htmlFor="email" className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300">
              {mapLocaleToMeaningfulFormat(router.locale).email}
            </label>
          </section>
          <section className="grid grid-cols-1 gap-x-10 lg:grid-cols-2">
            <section>
              <Field
                type="text"
                name="email"
                className={`input-text-primary mt-3 !w-full ${
                  (formik?.errors?.email && formik?.touched?.email) || userExists ? '!border-red-600' : ''
                }`}
              />
              {formik?.errors?.email && formik?.touched?.email ? (
                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.email}</p>
              ) : (
                ''
              )}
            </section>
          </section>
          <label htmlFor="keepMeCheckbox" className="mt-4 flex cursor-pointer items-start gap-2 lg:items-center">
            <section className="relative">
              <Field
                type="checkbox"
                id="keepMeCheckbox"
                name="keepMeCheckbox"
                checked={keepMe}
                onChange={() => setKeepMe(!keepMe)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setKeepMe(!keepMe);
                  }
                }}
                className="filterNavCheckbox h-4 w-4 cursor-pointer rounded-[2px] border !border-black-900 text-white shadow-none focus:shadow-none focus:outline-none focus:ring-0"
              />
              <CustomCheckBox className={' absolute top-[10px] left-1/2 hidden h-2 w-2 -translate-x-1/2 shadow-xl'} />
            </section>
            <p className="text-sm"> {mapLocaleToMeaningfulFormat(router.locale).keepMeUpdateOnTrands}</p>
          </label>
          <label htmlFor="sendCatalogCheckbox" className="mt-4 flex cursor-pointer items-start gap-2 lg:items-center">
            <section className="relative">
              <Field
                type="checkbox"
                id="sendCatalogCheckbox"
                name="sendCatalogCheckbox"
                checked={sendACatalog}
                onChange={() => setSendACatalog(!sendACatalog)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSendACatalog(!sendACatalog);
                  }
                }}
                className="filterNavCheckbox h-4 w-4 cursor-pointer rounded-[2px] border !border-black-900 text-white shadow-none focus:shadow-none focus:outline-none focus:ring-0"
              />
              <CustomCheckBox className={' absolute top-[10px] left-1/2 hidden h-2 w-2 -translate-x-1/2 shadow-xl'} />
            </section>
            <p className="text-sm"> {mapLocaleToMeaningfulFormat(router.locale).sendCatalogToAddress}</p>
          </label>
        </section>
        <section className="mt-5 max-w-screen-8xl rounded-lg border px-5">
          <h1 className="mt-5 w-max text-left text-lg font-bold leading-[29px] text-sol-400">
            {mapLocaleToMeaningfulFormat(router.locale).shippingAddress}
          </h1>
          <section className="my-5">
            <AddressForm
              type={`shippingAddress`}
              formik={formik}
              addressInitialValues={initialValues?.shippingAddress}
              countries={countries}
            />
          </section>
        </section>
        <section className="mt-4">
          <section className="rounded-md border p-5">
            <h1 className="mb-4 w-max text-left text-lg font-bold leading-[29px] text-sol-400">
              {mapLocaleToMeaningfulFormat(router.locale).billingAddress}
            </h1>
            <label htmlFor="sameBillingAddress" className="mt-4 flex cursor-pointer items-center gap-2">
              <section className="relative">
                <Field
                  type="checkbox"
                  id="sameBillingAddress"
                  checked={useSameAsShipping}
                  onClick={openBillingAddressForm}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setUseSameAsShipping(!useSameAsShipping);
                    }
                  }}
                  className="filterNavCheckbox h-4 w-4 cursor-pointer rounded-[2px] border !border-black-900 text-white shadow-none focus:shadow-none focus:outline-none focus:ring-0"
                />
                <CustomCheckBox className={' absolute top-[10px] left-1/2 hidden h-2 w-2 -translate-x-1/2 shadow-xl'} />
              </section>
              <p className="text-sm"> {mapLocaleToMeaningfulFormat(router.locale).useSameAsShipping}</p>
            </label>
          </section>
        </section>
        {!useSameAsShipping && (
          <section className="mt-5 max-w-screen-8xl rounded-lg border px-5">
            <section className="my-5">
              <AddressForm
                type={`billingAddress`}
                formik={formik}
                addressInitialValues={initialValues?.billingAddress}
                countries={countries}
              />
            </section>
          </section>
        )}
        {/* <ReCaptcha setIsReCaptchaVerified={setIsReCaptchaVerified} setReCaptchaError={setReCaptchaError} /> */}
        {reCaptchaError && (
          <span className="text-xs font-semibold text-red-500">
            {mapLocaleToMeaningfulFormat(router.locale).reCaptchaError}
          </span>
        )}
        <button type="submit" className="btn-primary-large mt-6 w-full md:w-[294px]">
          {loading ? (
            <section className="flex justify-center gap-3">
              {' '}
              <Spinner />
              <p>{mapLocaleToMeaningfulFormat(router.locale).processing}</p>
            </section>
          ) : (
            mapLocaleToMeaningfulFormat(router.locale).continueToPayment
          )}
        </button>
        <SuggestAddress
          open={createModalOpen}
          onClose={closeCreateModal}
          validateShipping={validateShipping}
          validateBilling={validateBilling}
          selectedShipping={selectedShipping}
          selectedBilling={selectedBilling}
          setSelectedAddress={setSelectedShipping}
          setBillingAddress={setSelectedBilling}
          formik={formik}
          setloading={setReload}
          isSameAddress={useSameAsShipping}
          isShippingValid={isShippingValid}
          isBillingValid={isBillingValid}
          account={account}
        />
      </>
    );
  };

  return (
    validationSchema && (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form>
            <FormEventsAnalyticsTracker formName="Checkout" />
            <FormikFormSectionGroup formik={formik} />
          </Form>
        )}
      </Formik>
    )
  );
};

export default CheckoutGuestAddresses;
