import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import {
  clickedObjectIDs,
  clickedObjectIDsAfterSearch,
  viewedObjectIDs,
} from 'helpers/hooks/algoliaEvents/algoliaEvents';
import { setAlgoliaQueryID } from 'helpers/hooks/algoliaEvents/algoliaUtils';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { AMPLIENCE_IMAGE_URL_FORMAT, EVENT_LEFT_CLICK, EVENT_RIGHT_CLICK } from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import omitFileExtension from 'helpers/utils/omitFileExtension';
import { buildLinkProductVariant } from 'helpers/utils/productUrlBuilder';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import NoImageAvailable from 'public/images/No-Image-Available.jpg';
import { useAccount } from 'frontastic';
import { gtmSelectItem } from 'helpers/utils/gtm-events/gtmEcommerce';
import QuickViewModal from './quick-view-modal';
import { amplienceImageLoader } from 'helpers/utils/amplienceImageLoader';
import { RouteHelpers } from 'helpers/routeHelpers';
interface ProductListCardType {
  data: any;
}

export interface UIColor {
  name?: string;
  key?: string;
  bgColor?: string;
  selectedColor?: string;
}

interface ColorSelectorProps {
  colors: UIColor[];
  selectedVariant: any;
  //   changeVariant: (variant: any) => void;
}

const ProductListCard = ({
  data: plpItem,
  currentCat,
  downrodsContent,
  currentFilters,
  isClearanceCategory,
  isSearchComponent,
  positions,
  algoliaData = null,
}: any) => {
  //   return <></>
  //next/router

  const [currentIndex, setCurrentIndex] = useState(0);
  const startIndex =
    currentIndex > plpItem?.all_variants?.length - 3 && plpItem?.all_variants?.length > 3
      ? plpItem?.all_variants?.length - 3
      : currentIndex;
  const endIndex = plpItem?.all_variants?.length > 3 ? startIndex + 3 : plpItem?.all_variants?.length - 1;
  const displayItems =
    plpItem?.all_variants?.length > 3 ? plpItem?.all_variants.slice(startIndex, endIndex) : plpItem?.all_variants;
  const [plpSelectedVariant, setPlpSelectedVariant] = useState(null);
  const [productClicked, setProductClicked] = useState(false);
  const router = useRouter();
  const { getItem, setItem } = useLocalStorage();
  const { account } = useAccount();
  const [isDesktop] = useMediaQuery(desktop);
  const [navigationLink, setNavigationLink] = useState('');
  const [plpItemMainImage, setPlpItemMainImage] = useState(null);
  const [color, setColor] = useState('');
  const [link, setLink] = useState('');
  const dynamicWidth = calculateDynamicImageWidth(300, 400, 500);
  const dynamicWidthVariant = calculateDynamicImageWidth(30, 30, 30);
  const PDPNavigationLink = () => {
    let pdpNavigationLink = `${routerURL.products}/${plpItem?.slug[selectedLocale]}`;
    if (productClicked && plpItem?.actualColor?.length > 1) {
      const query = {
        color: plpSelectedVariant
          ? plpSelectedVariant?.all_Attributes?.actualColor
          : plpItem.all_variants[0]?.all_Attributes?.actualColor,
      };
      pdpNavigationLink += RouteHelpers.encodeToURL(query);
    }
    setNavigationLink(pdpNavigationLink);
  };

  const applySelectedVariant = (allVariants, filterArr, attr) => {
    const filterVariants = allVariants?.filter((variant) => {
      return variant?.all_Attributes[`${attr}`] && variant;
    });
    if (filterVariants && filterVariants?.length) {
      for (const filter of filterArr) {
        const filteredArray = filterVariants?.filter((item) => {
          return item?.all_Attributes[`${attr}`]?.some((obj) => obj?.key === filter);
        });
        if (filteredArray?.length) {
          const masterVaraint = filteredArray?.find((item) => item?.isMasterVariant && item?.isMasterVariant === true);
          const finalVariant = masterVaraint ? masterVaraint : filteredArray[0];
          setPlpSelectedVariant(finalVariant);
          setPlpItemMainImage(finalVariant?.images?.find((image: any) => image?.label === 'Main'));
          PDPNavigationLink();
        }
      }
    }
  };

  useEffect(() => {
    if (!plpSelectedVariant) {
      let selectedVariant = undefined;

      if (isClearanceCategory && !selectedVariant) {
        selectedVariant = plpItem?.all_variants?.filter((variant: any) => variant?.originalPrice > 0)[0];
      }
      if (!selectedVariant) {
        selectedVariant = plpItem?.all_variants?.find((variant: any) => variant?.isMasterVariant);
      }
      setPlpSelectedVariant(selectedVariant ? selectedVariant : plpItem?.all_variants[0]);
      setPlpItemMainImage(
        selectedVariant
          ? selectedVariant?.images?.find((image: any) => image?.label === 'Main')
          : plpItem?.all_variants[0]?.images?.find((image: any) => image?.label === 'Main'),
      );
      PDPNavigationLink();
    }
  }, [plpItem]);

  useEffect(() => {
    if (plpSelectedVariant) {
      const getAppliedFilterColors = currentFilters?.filterColor;
      const getAppliedFilterFinish = currentFilters?.filterFinish;

      if (
        getAppliedFilterColors &&
        getAppliedFilterColors?.length &&
        getAppliedFilterFinish &&
        getAppliedFilterFinish?.length
      ) {
        applySelectedVariant(plpItem?.all_variants, getAppliedFilterColors, 'filterColor');
      } else if (getAppliedFilterColors && !getAppliedFilterFinish) {
        applySelectedVariant(plpItem?.all_variants, getAppliedFilterColors, 'filterColor');
      } else if (!getAppliedFilterColors && getAppliedFilterFinish) {
        applySelectedVariant(plpItem?.all_variants, getAppliedFilterFinish, 'filterFinish');
      } else {
        let selectedVariant = undefined;
        if (isClearanceCategory && !selectedVariant) {
          selectedVariant = plpItem?.all_variants?.filter((variant: any) => variant?.originalPrice > 0)[0];
        }
        if (!selectedVariant) {
          selectedVariant = plpItem?.all_variants?.find((variant: any) => variant?.isMasterVariant);
        }
        PDPNavigationLink();
        setPlpSelectedVariant(selectedVariant ? selectedVariant : plpItem?.all_variants[0]);
        setPlpItemMainImage(
          selectedVariant
            ? selectedVariant?.images?.find((image: any) => image?.label === 'Main')
            : plpItem?.all_variants[0]?.images?.find((image: any) => image?.label === 'Main'),
        );
      }
    }
  }, [plpItem.all_variants, plpSelectedVariant]);

  useEffect(() => {
    if (color !== '') {
      const matchingVariant = plpItem.all_variants.find((variant) => variant.all_Attributes?.actualColor === color);
      if (matchingVariant) {
        setProductClicked(true);
        setPlpSelectedVariant(matchingVariant);
        setPlpItemMainImage(matchingVariant?.images.find((image: any) => image?.label === 'Main'));
        PDPNavigationLink();
      }
    }
  }, [color, plpItem.all_variants, plpSelectedVariant]);
  //create delete modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalOpen(true);
    setItem('queryID', plpItem['__queryID']);
    viewedObjectIDs({
      index: getItem('currentIndex'),
      eventName: 'Product Viewed On PLP Page',
      objectIDs: [plpItem.objectID],
    });
  };

  const stopPropagation = (e) => {
    e.preventDefault();
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const selectedLocale = (router.query.locale ? router.query.locale.toString() : 'en-US').replaceAll('_', '-');

  const ColorSelector: React.FC<ColorSelectorProps> = ({ colors, selectedVariant }) => {
    const onColorPick = (color) => {
      setColor(color);
      const matchingVariant = plpItem.all_variants.find((variant) => variant.all_Attributes?.actualColor === color);
      if (matchingVariant) {
        setProductClicked(true);
        setPlpSelectedVariant(matchingVariant);
        setPlpItemMainImage(matchingVariant?.images.find((image: any) => image?.label === 'Main'));
        PDPNavigationLink();
      }
    };

    let productVariants: any = [];
    const colorKeys = [];
    const pushedColors = [];
    colors.map((color) => colorKeys.push(color.key));
    if (colors?.length != plpItem?.all_variants?.length && colors?.length > 0) {
      plpItem?.all_variants?.some((item) => {
        if (
          !pushedColors?.includes(item?.all_Attributes?.actualColor) &&
          colorKeys?.includes(item?.all_Attributes?.actualColor)
        ) {
          productVariants.push(item);
          pushedColors.push(item?.all_Attributes?.actualColor);
        }
        return pushedColors?.length === colors?.length;
      });
    } else {
      productVariants = plpItem?.all_variants;
    }

    // // Filter out variants for which ShowOnSite is false
    productVariants = productVariants?.filter((variant) => variant?.all_Attributes?.shownOnSite);
    const handleSetNumber = ({ op }) => {
      const newIndex =
        op === 'Prev'
          ? currentIndex === 0
            ? currentIndex
            : currentIndex - 1
          : currentIndex === plpItem?.all_variants.length - 1
          ? currentIndex
          : currentIndex + 1;
      onColorPick(plpItem?.all_variants[newIndex]?.all_Attributes?.actualColor);
      setCurrentIndex(newIndex);
    };

    const selectedLocale = (router.query.locale ? router.query.locale.toString() : 'en-US').replaceAll('_', '-');

    const handleVariant = (e, variant) => {
      const productSlug = plpItem?.slug;
      if (productSlug) {
        const newLink = buildLinkProductVariant(
          router.basePath,
          productSlug[selectedLocale],
          variant?.all_Attributes?.actualColor,
        );
        setLink(newLink);
      }
    };

    return (
      <>
        <section className="h-8 overflow-hidden lg:overflow-visible">
          {colors?.length > 0 && isDesktop && (
            <section className="overflow-x-auto pb-5 lg:overflow-visible lg:pb-0">
              <ul className="mx-auto flex w-max items-center gap-4 px-2">
                {productVariants?.slice(0, isDesktop ? 4 : plpItem?.all_variants.length)?.map((variant, index) => {
                  return (
                    <li key={index} title={variant?.all_Attributes?.actualColor || ''}>
                      <Link href={link} passHref>
                        <a
                          className={`flex h-7 w-7 shrink-0 items-center justify-center rounded-full border-2 border-transparent ${
                            plpSelectedVariant !== null &&
                            plpSelectedVariant?.all_Attributes?.actualColor === variant?.all_Attributes?.actualColor &&
                            productClicked
                              ? 'rounded-full border border-black-100 p-[2px]'
                              : ''
                          }`}
                          onClick={(e) => {
                            if (e.button === EVENT_LEFT_CLICK) {
                              e.preventDefault();
                              onColorPick(variant?.all_Attributes?.actualColor);
                            }
                          }}
                          onMouseDownCapture={(e) => {
                            if (e.button === EVENT_RIGHT_CLICK) {
                              e.preventDefault();
                              handleVariant(e, variant);
                            }
                          }}
                          onTouchStart={(e) => {
                            handleVariant(e, variant);
                          }}
                        >
                          <Image
                            loader={({ src }) => src}
                            width={26}
                            height={26}
                            src={
                              format(AMPLIENCE_IMAGE_URL_FORMAT, [
                                omitFileExtension(variant?.images[variant?.images?.length - 1]?.url),
                                dynamicWidthVariant,
                              ]) || '#'
                            }
                            alt={variant?.all_Attributes?.actualColor}
                            className={`flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center
            rounded-[50%]`}
                          />
                        </a>
                      </Link>
                    </li>
                  );
                })}
                {colors?.length > 4 && isDesktop && (
                  <li>
                    <button
                      type="button"
                      className="flex items-center justify-center text-sm font-normal leading-[17px] text-sol-300"
                      onClick={openDeleteModal}
                    >
                      +{colors?.length - 4}
                    </button>
                  </li>
                )}
              </ul>
            </section>
          )}
          {colors?.length > 0 && !isDesktop && (
            <section className="flex overflow-x-auto pb-5 lg:overflow-visible lg:pb-0">
              <button
                type="button"
                id={'preBtn' + plpItem?.name[selectedLocale]?.trim()?.split(' ')?.join('')}
                aria-label="previous"
                className={`${plpItem?.all_variants.length <= 3 && 'hidden'}`}
                disabled={displayItems.length <= 1}
                onClick={() => handleSetNumber({ op: 'Prev' })}
              >
                <ChevronLeftIcon
                  className={`h-4 w-4 transform ${
                    currentIndex === 0 ? 'opacity-25' : ''
                  } transition-transform duration-500 ease-in-out`}
                />
              </button>
              <ul className="mx-auto flex w-max items-center gap-2 px-1">
                {displayItems?.map((variant, index) => {
                  return (
                    <li
                      key={index}
                      className={`flex h-7 w-7 shrink-0 items-center justify-center ${
                        plpSelectedVariant !== null &&
                        plpSelectedVariant?.all_Attributes?.actualColor === variant?.all_Attributes?.actualColor &&
                        productClicked
                          ? 'rounded-full border border-black-100 p-[2px]'
                          : ''
                      }`}
                    >
                      <Link href={link} passHref key={variant?.id}>
                        <a
                          className="flex items-center justify-center"
                          title={variant?.all_Attributes?.actualColor || ''}
                          onClick={(e) => {
                            if (e.button === EVENT_LEFT_CLICK) {
                              e.preventDefault();
                              onColorPick(variant?.all_Attributes?.actualColor);
                              setCurrentIndex(startIndex + index);
                            }
                          }}
                          onMouseDownCapture={(e) => {
                            if (e.button === EVENT_RIGHT_CLICK) {
                              e.preventDefault();
                              handleVariant(e, variant);
                            }
                          }}
                          onTouchStart={(e) => {
                            handleVariant(e, variant);
                          }}
                        >
                          <Image
                            loader={({ src }) => src}
                            width={26}
                            height={26}
                            src={
                              format(AMPLIENCE_IMAGE_URL_FORMAT, [
                                omitFileExtension(variant?.images[variant?.images?.length - 1]?.url),
                                dynamicWidthVariant,
                              ]) || '#'
                            }
                            alt={variant?.all_Attributes?.actualColor}
                            className={`flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center
            rounded-[50%]`}
                          />
                        </a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <button
                type="button"
                id={'nextBtn' + plpItem?.name[selectedLocale]?.trim()?.split(' ')?.join('')}
                aria-label="next"
                className={`${plpItem?.all_variants.length <= 3 && 'hidden'}`}
                disabled={displayItems.length <= 1}
                onClick={() => handleSetNumber({ op: 'Next' })}
              >
                <ChevronRightIcon
                  className={`h-4 w-4 transform ${
                    currentIndex === plpItem?.all_variants.length - 1 ? 'opacity-25' : ''
                  } transition-transform duration-500 ease-in-out`}
                />
              </button>
            </section>
          )}
        </section>
      </>

      // Will need code later on, Maybe will use in future

      // <ul className="color-checkbox-ul flex h-8 items-center justify-center gap-4 px-2">
      //   {colors.map((item: any, index) => {
      //     const isDisabled = !plpItem.all_variants.some((variant) => variant.all_Attributes?.actualColor === item.key);
      //     const checked = selectedVariant?.all_variants?.actualColor === item.key;
      //     return (
      //       <li key={index} className="shrink-0">
      //         <div className="flex items-center">
      //           <input
      //             type="checkbox"
      //             onChange={() => onColorPick(item)}
      //             className={`absolute h-6 w-6  opacity-0 ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${
      //               checked ? 'border-black-900 hover:border' : 'border: null'
      //             }`}
      //             checked={checked}
      //             disabled={isDisabled}
      //           />
      //           <div
      //             className={`linear-default linear-${item?.label?.toLowerCase()} flex h-8 w-8 cursor-pointer items-center justify-center rounded-[50%] ${
      //               isDisabled ? 'cursor-not-allowed opacity-50' : ''
      //             }`}
      //           >
      //             <CheckedIcon className="checked-icon h-2 w-2 cursor-pointer" />
      //           </div>
      //         </div>
      //       </li>
      //     );
      //   })}
      //   {isDesktop && colors && colors.length ? (
      //     <li className="shrink-0">
      //       <button
      //         type="button"
      //         className="flex items-center justify-center text-sm font-normal leading-[17px] text-sol-300"
      //         onClick={openDeleteModal}
      //       >
      //         +1
      //       </button>
      //     </li>
      //   ) : (
      //     <li className="shrink-0">
      //       <button
      //         type="button"
      //         className="flex items-center justify-center text-sm font-normal leading-[17px] text-sol-300"
      //       >
      //         {/* +1 */}
      //       </button>
      //     </li>
      //   )}
      // </ul>
    );
  };

  const getDistinct = (variants, fieldName) => {
    const colorKeys = variants.map((variant) => variant.all_Attributes[fieldName]);
    const isSameColor = colorKeys?.every((val, i, arr) => val === arr[0]);

    return isSameColor ? [] : Array.from(new Set(colorKeys)).map((color) => ({ key: color, label: color }));
  };

  const colorSelection: any = getDistinct(plpItem?.all_variants, 'actualColor');

  const refineScroll = () => {
    const scrollItem = plpItem?.parentId;
    if (scrollItem) {
      localStorage?.setItem('scrollID', scrollItem);
    }
  };

  useEffect(() => {
    const scrollToElement = () => {
      const scrollID = localStorage?.getItem('scrollID');
      if (scrollID) {
        const element = document?.getElementById(scrollID);
        if (element) {
          element?.scrollIntoView({ behavior: 'smooth' });
          localStorage?.removeItem('scrollID');
        }
      }
    };
    setTimeout(() => {
      scrollToElement();
    }, 2000);
  }, []);

  const handleClick = () => {
    refineScroll();
    setAlgoliaQueryID(plpItem['__queryID']);
    setItem('plpUrl', location.href.replace(location.origin, ''));
    const algoliaQueryId = plpItem['__queryID'];

    algoliaQueryId
      ? clickedObjectIDsAfterSearch({
          index: getItem('currentIndex'),
          eventName: 'Product Clicked After Search',
          objectIDs: [plpItem.objectID],
          queryID: plpItem['__queryID'],
          positions: [plpItem['__position']],
        })
      : clickedObjectIDs({
          index: getItem('currentIndex'),
          eventName: 'Product Clicked On PLP Page',
          objectIDs: [plpItem.objectID],
        });
  };

  const handleGTMSelectItem = () => {
    const listName = algoliaData?.categoryData?.name;
    const listId = algoliaData?.categoryData?.id;
    const productIndex = positions;

    if (plpSelectedVariant) gtmSelectItem(plpItem, plpSelectedVariant, listName, listId, productIndex, account?.email);
  };

  return (
    <>
      <div onClick={handleClick}>
        <section className="relative flex h-full w-full">
          <section className="h-full w-full bg-sol-100">
            <section className="productQuickView relative" id={plpItem?.parentId}>
              <Link href={navigationLink}>
                <a onClick={handleGTMSelectItem}>
                  <Image
                    loader={amplienceImageLoader}
                    width={400}
                    height={400}
                    sizes="(max-width: 1024px) 50vw, 25vw"
                    src={plpItemMainImage?.url ? omitFileExtension(plpItemMainImage?.url) : NoImageAvailable}
                    alt={plpItemMainImage?.label ?? plpItem?.name[selectedLocale]}
                    className="cursor-pointer rounded-t-[6px]"
                  />
                </a>
              </Link>

              {isDesktop && (
                <>
                  <button
                    id="quickView"
                    type="button"
                    className="quickView absolute top-1/2 left-1/2 -translate-x-1/2 bg-white px-2 py-1 font-semibold text-sol-300 hover:bg-black-300 hover:text-white"
                    onClick={(e) => {
                      openDeleteModal(e);
                      handleGTMSelectItem();
                    }}
                  >
                    {mapLocaleToMeaningfulFormat(router.locale).quickView}
                  </button>
                </>
              )}
            </section>
            <section className="px-2 pt-[10px] pb-3 sm:px-5">
              <ColorSelector colors={colorSelection} selectedVariant={plpSelectedVariant} />
              <Link href={navigationLink}>
                <a
                  className="mb-3 mt-[10px] inline-block min-h-[42px] w-full cursor-pointer text-center text-base font-semibold leading-5 text-sol-300 line-clamp-2"
                  onClick={handleGTMSelectItem}
                >
                  {plpItem?.name[selectedLocale]}
                </a>
              </Link>
              <section>
                {account?.isB2BApproved && (
                  <p className="text-black inline-block w-full text-center text-sm font-bold leading-[17px]">
                    {mapLocaleToMeaningfulFormat(router.locale).yourPrice}
                  </p>
                )}
              </section>
              {(account && !account?.isB2BApproved) || !account ? (
                <div className="flex justify-center gap-2">
                  {plpItem?.originalPriceRanges ? (
                    <>
                      <strong className="inline-block text-left text-base font-semibold leading-5 text-red-500">
                        {plpItem['price_range']}
                      </strong>
                      <strong className="inline-block text-left text-base font-semibold leading-5 text-sol-300 line-through">
                        {plpItem?.originalPriceRanges}
                      </strong>
                    </>
                  ) : plpItem?.discountedPriceRanges ? (
                    <>
                      <strong className="inline-block text-left text-base font-semibold leading-5 text-red-500">
                        <span className="inline-block pr-2 text-sol-700">{`${plpItem?.discountedPriceRanges}`}</span>
                      </strong>
                      <strong className="inline-block text-left text-base font-semibold leading-5 text-sol-300 line-through">
                        {plpItem['price_range']}
                      </strong>
                    </>
                  ) : (
                    <span
                      className={`inline-block text-left text-base font-semibold leading-5 text-sol-300 ${
                        plpItem?.discountedPriceRanges || plpItem?.originalPriceRanges ? 'line-through' : null
                      }`}
                    >
                      {plpItem['price_range']}
                    </span>
                  )}
                </div>
              ) : (
                <p className="inline-block w-full text-center text-sm font-semibold leading-[17px] text-sol-300">
                  {plpItem?.isTradeProduct ? (
                    <>
                      {plpItem[`price-${account?.percentageDiscount}PCT-Range`] && (
                        <span className="inline-block pr-2 text-sol-700">
                          {plpItem?.originalPrice
                            ? plpItem[`price_range`]
                            : plpItem[`price-${account?.percentageDiscount}PCT-Range`]}
                          {}
                        </span>
                      )}
                      <span
                        className={`inline-block ${
                          plpItem[`price-${account?.percentageDiscount}PCT-Range`] ? 'line-through' : null
                        }`}
                      >
                        {plpItem?.originalPriceRanges ? plpItem?.originalPriceRanges : plpItem['price_range']}
                      </span>
                    </>
                  ) : (
                    <>
                      {plpItem?.originalPriceRanges && (
                        <span className="inline-block pr-2 text-sol-700">
                          {plpItem?.originalPrice ? plpItem[`price_range`] : null}
                        </span>
                      )}
                      <span className={`inline-block ${plpItem?.originalPriceRanges ? 'line-through' : null}`}>
                        {plpItem?.originalPriceRanges ? plpItem?.originalPriceRanges : plpItem['price_range']}
                      </span>
                    </>
                  )}
                </p>
              )}
            </section>
          </section>
        </section>
      </div>
      <QuickViewModal
        selectedVariant={plpSelectedVariant}
        modalOpen={deleteModalOpen}
        onClose={closeDeleteModal}
        product={plpItem}
        downrodsContent={downrodsContent}
        pdpNavigationLink={navigationLink}
        setNavigationLink={setNavigationLink}
      />
    </>
  );
};

export default React.memo(ProductListCard);
