import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { ChevronUpIcon } from '@heroicons/react/outline';
import { AMPLIENCE_IMAGE_URL_FORMAT } from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import omitFileExtension from 'helpers/utils/omitFileExtension';
import NoImageAvailable from 'public/images/No-Image-Available.jpg';
import { gtmImageScroll } from 'helpers/utils/gtm-events/gtmInteraction';

const VerticalCarousel = ({ itemList, number, setNumber, selectedImage, setSelectedImage, productId }) => {
  const [currentIndex, setCurrentIndex] = useState(0); //set 0 to start from first image in array
  const [displayItems, setDisplayItems] = useState(itemList?.slice(0, 4));

  useEffect(() => {
    const selectedImageinDisplayItems = displayItems?.find((obj) => obj === selectedImage);
    if (!selectedImageinDisplayItems) {
      const tempDisplayitems = itemList?.slice(
        currentIndex > 3 ? currentIndex - 3 : 0,
        currentIndex > 3 ? currentIndex + 1 : 4,
      );
      setDisplayItems([].concat(tempDisplayitems));
    }
  }, [selectedImage]);
  const dynamicWidth = calculateDynamicImageWidth(100, 100, 100);

  const currentImageIndex = itemList?.findIndex((img: any) => img === selectedImage);
  const handleSetImage = ({ op }) => {
    if (op === 'Prev') {
      if (currentImageIndex !== 0) {
        setSelectedImage(itemList?.[currentImageIndex - 1]);
        setCurrentIndex(currentImageIndex - 1);
      } else {
        setSelectedImage(itemList?.[itemList?.length - 1]);
        setCurrentIndex(itemList?.length - 1);
      }
    }
    if (op === 'Next') {
      if (currentImageIndex !== itemList?.length - 1) {
        setSelectedImage(itemList?.[currentImageIndex + 1]);
        setCurrentIndex(currentImageIndex + 1);
      } else {
        setSelectedImage(itemList?.[0]);
        setCurrentIndex(0);
      }
    }
  };

  return (
    <>
      <div className="hidden shrink-0 flex-row items-center gap-3 md:flex-col lg:flex">
        <button
          type="button"
          id="preBtn"
          aria-label="previous"
          className={`${itemList.length <= 2 && 'hidden'}`} //Checks the length <= 2 or not to hide the button if length is less than two
          onClick={() => handleSetImage({ op: 'Prev' })}
          disabled={itemList.length <= 2} //Checks the length <= 2 or not to disable the button if length is less than two
        >
          <ChevronUpIcon
            className={`h-4 w-4 transform ${
              itemList.length <= 2 ? 'hidden' : ''
            } transition-transform duration-500 ease-in-out`}
          />
        </button>
        <div className="flex flex-col gap-3">
          {displayItems?.map((image, i) => (
            <button
              type="button"
              key={i}
              className={`flex cursor-pointer justify-center rounded-[6px] border border-transparent  ${
                selectedImage === image ? 'border-[#B19A6A] p-[2px] opacity-100' : 'opacity-30'
              } transition-opacity duration-700 ease-in-out`}
              onClick={() => {
                gtmImageScroll({
                  click_action: 'thumbnail image',
                  item_id: productId,
                  media_name: `${image?.alt} ${i}`,
                });
                setSelectedImage(image);
              }}
            >
              <Image
                src={format(AMPLIENCE_IMAGE_URL_FORMAT, [
                  omitFileExtension(image.src) || NoImageAvailable.src,
                  dynamicWidth,
                ])}
                loader={(options) => options.src}
                height="91px"
                width="91px"
                className={`h-[91px] w-[91px] rounded-[6px] transition-transform duration-500 ease-in-out ${
                  selectedImage === image && 'scale-100'
                }`}
                alt={`product thumbnail ${i}`}
              />
            </button>
          ))}
        </div>

        <button
          type="button"
          id="nextBtn"
          aria-label="next"
          className={`${itemList.length <= 2 && 'hidden'}`}
          onClick={() => handleSetImage({ op: 'Next' })}
          disabled={itemList.length <= 2}
        >
          <ChevronUpIcon
            className={`h-4 w-4 transform ${
              itemList.length <= 2 ? 'hidden' : ''
            } rotate-180 transition-transform duration-500 ease-in-out`}
          />
        </button>
      </div>
      <ul className="flex h-max shrink-0 flex-row items-center justify-center gap-3 lg:hidden">
        {itemList?.map((image: any, i: React.Key) => (
          <li key={i} onClick={() => setSelectedImage(image)}>
            <button
              key={i}
              type="button"
              aria-label={`Carousel Bullets ${Number(i) + 1}`}
              className={`h-[10px] w-[10px] rounded-[50%] border ${
                selectedImage === image ? 'bg-sol-300' : 'border-sol-300'
              } transition-colors duration-700 ease-in-out`}
            ></button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default VerticalCarousel;
