import React, { useEffect, useRef, useState } from 'react';
{
  /* TODO:  */
}
import Image from 'next/image';
import Link from 'next/link';
import router from 'next/router';
import { Formik, Field } from 'formik';
import { MdCheckCircleOutline } from 'react-icons/md';
import { SUCCESSFULLY_SUBSCRIBED_NEWSLETTER } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import validationSchema from 'helpers/utils/validationSchema/footer';
import { useAccount } from 'frontastic';
import FormEventsAnalyticsTracker from 'components/sol-ui/form-events-analytics-tracker';
import useFormInteraction from 'helpers/hooks/useFormInteraction';
// import Image from 'frontastic/lib/image';
export const SOLFooterDesktop = ({ navs, content }) => {
  const formikRef = useRef(null);
  const {
    socialGroupTitle,
    SocialGroups,
    footerBannerDesktopImage,
    footerBannerImageAltText,
    integrityGroups,
    newsletterDescription,
    newsletterTitle,
    footerBannerTitle,
    copyrightText,
    footerBannerCTALink,
    year,
  } = content;
  const {
    customerServiceColumn,
    customerServiceColumnLinks,
    b2bProgramsColumn,
    b2bProgramsColumnLinks,
    ourCompanyColumn,
    ourCompanyColumnLinks,
    shippingResourcesColumn,
    shippingResourcesColumnLinks,
  } = navs;

  const { showNotification } = useNotification();
  const { signupNewsLetter } = useAccount();
  const [isSubmitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const account = useAccount();
  const { handleSubmitInteraction } = useFormInteraction('Signup Newsletter');
  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    await signupNewsLetter(values.email).then(() => {
      setLoading(false);
      setSubmitted(true);
      handleSubmitInteraction(false, values.email);
      formikRef.current.resetForm();
    });
    setSubmitting(false);
  };

  if (isSubmitted) {
    setTimeout(() => {
      setSubmitted(false);
    }, 5000);
  }
  useEffect(() => {
    if (window?.location.href) {
      formikRef.current.resetForm();
    }
  }, [window?.location?.href]);
  return (
    <>
      <section id="footerSection" className="w-full bg-[rgb(34,34,34)]/10 py-4">
        <section className="mx-auto w-full max-w-screen-8xl flex-col text-sm">
          <section className="flex flex-wrap items-center justify-between gap-7 px-10 py-5">
            <section className="flex flex-wrap items-center justify-center gap-7">
              <section>
                <p className="mb-[3px] font-semibold leading-[17px] text-[#222222]">{newsletterTitle || ''}</p>
                <p className="leading-[17px] text-[#222222]">{newsletterDescription || ''}</p>
              </section>
              <section className="flex w-[320px] items-center gap-0">
                <Formik
                  innerRef={formikRef}
                  initialValues={{ email: '' }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {(formikProps) => (
                    <form autoComplete="off" onSubmit={formikProps.handleSubmit} className="flex">
                      <FormEventsAnalyticsTracker formName="Signup Newsletter" />
                      <div className="relative">
                        <Field
                          type="text"
                          name="email"
                          className={`order-transparent h-10 w-full rounded-l-md border-white text-sm leading-[17px] text-[#666666] placeholder-[#666666] focus:border-transparent focus:ring-0 ${
                            formikProps.touched.email && formikProps.errors.email ? '!border-red-600' : ''
                          }`}
                          placeholder="Enter email address"
                        />

                        {formikProps.touched.email && formikProps.errors.email ? (
                          <div className="absolute inset-x-0  px-4">
                            <p className="whitespace-nowrap pt-1 text-sm font-normal leading-[17px] text-[#DA0F0F]">
                              {formikProps.errors.email}
                            </p>
                          </div>
                        ) : formikProps.values.email !== '' ? null : isSubmitted ? (
                          <div className="absolute inset-x-0 flex px-1">
                            <p className="flex items-center whitespace-nowrap pt-1 text-sm font-normal leading-[17px] text-sand-200">
                              <MdCheckCircleOutline className="mr-1" />
                              {mapLocaleToMeaningfulFormat(router.locale).successfulSubscription}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      <button
                        type={loading && !isSubmitted ? 'button' : 'submit'}
                        id="joinNewsLetter"
                        className={`h-10 rounded-r-md bg-[#404040] px-5 text-sm font-bold leading-[17px] text-white focus:outline-offset-2 ${
                          loading && !isSubmitted ? 'disabled:cursor-not-allowed' : ''
                        }`}
                        disabled={loading && !isSubmitted}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).join}
                      </button>
                    </form>
                  )}
                </Formik>
              </section>
            </section>
            <section className="flex items-center gap-5">
              <p className="text-sm font-semibold leading-[17px] text-[#222222]">{socialGroupTitle || ''}</p>
              <ul className="flex items-center gap-5">
                {SocialGroups?.data?.map((content, key) => (
                  <li key={key}>
                    <a
                      href={content?.link || '#'}
                      target="_blank"
                      rel="noreferrer"
                      className="relative flex h-5 w-5 items-center justify-center"
                    >
                      <Image
                        loader={({ src }) => src}
                        src={`${content?.icon?.url}?fmt=auto` || '#'}
                        alt={content?.alt}
                        priority={true}
                        layout="fill"
                        objectFit="contain"
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </section>
          </section>
        </section>
      </section>
      <section className="w-full bg-[#F4F4F4]">
        <section className="mx-auto w-full max-w-screen-8xl flex-col text-sm text-[#222222]">
          <section className="flex flex-col bg-[#F4F4F4]">
            <section className="flex flex-wrap justify-between gap-10 px-10 pt-9 pb-12">
              <section className="flex flex-col gap-4 leading-[17px]">
                <p className="font-semibold leading-[17px]">
                  <Link href={footerBannerCTALink}>
                    <a className="hover:underline" rel="noreferrer">
                      {footerBannerTitle || ''}
                    </a>
                  </Link>
                </p>
                <Link href={footerBannerCTALink}>
                  <a rel="noreferrer">
                    <Image
                      loader={({ src }) => src}
                      src={footerBannerDesktopImage?.url + '?fmt=auto&w=170' || '#'}
                      alt={footerBannerImageAltText || ''}
                      height={176}
                      width={140}
                    />
                  </a>
                </Link>
              </section>
              {customerServiceColumn && (
                <section className="flex flex-col gap-2">
                  <p className="font-semibold leading-[17px]">{customerServiceColumn}</p>
                  <ul>
                    {customerServiceColumnLinks?.map((item, index) => (
                      <li key={index} className="py-1">
                        <Link
                          href={
                            item?.reference?.link === routerURL?.account
                              ? account?.loggedIn
                                ? routerURL?.account
                                : routerURL?.login
                              : item?.reference?.link || '/'
                          }
                          className="leading-[17px] hover:underline"
                        >
                          <a className="leading-[17px] hover:underline">{item?.name || ''}</a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </section>
              )}
              {b2bProgramsColumn && (
                <section className="flex flex-col gap-2">
                  <p className="font-semibold leading-[17px]">{b2bProgramsColumn}</p>
                  <ul>
                    {b2bProgramsColumnLinks?.map((item, index) => (
                      <li key={index} className="py-1">
                        <Link href={item?.reference?.link || '/'}>
                          <a className="leading-[17px] hover:underline">{item?.name || ''}</a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </section>
              )}
              {ourCompanyColumn && (
                <section className="flex flex-col gap-2">
                  <p className="font-semibold leading-[17px]">{ourCompanyColumn}</p>
                  <ul>
                    {ourCompanyColumnLinks?.map((item, index) => (
                      <li key={index} className="py-1">
                        <Link href={item?.reference?.link || '/'}>
                          <a className="leading-[17px] hover:underline">{item?.name || ''}</a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </section>
              )}
              {shippingResourcesColumn && (
                <section className="flex flex-col gap-2">
                  <p className="font-semibold leading-[17px]">{shippingResourcesColumn}</p>
                  <ul>
                    {shippingResourcesColumnLinks?.map((item, index) => (
                      <li key={index} className="py-1">
                        <Link href={item?.reference?.link || '/'}>
                          <a className="leading-[17px] hover:underline">{item?.name || ''}</a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </section>
              )}
            </section>

            <section className="mx-10 flex flex-wrap items-center justify-between border-t border-[rgb(34,34,34)]/10 py-[22px] text-xs leading-[15px] text-[#222222]">
              <ul className="flex flex-wrap gap-2 ">
                {integrityGroups?.data?.map((content, key) => (
                  <li key={key} className="shrink-0 hover:underline">
                    {key !== 0 ? <span className="pr-2">|</span> : <></>}
                    {content.link === routerURL?.hash ? (
                      <button
                        className="hover:underline"
                        type="button"
                        onClick={() =>
                          window !== undefined && window['Osano']?.cm?.showDrawer('osano-cm-dom-info-dialog-open')
                        }
                      >{`${content?.title || ''} `}</button>
                    ) : (
                      <a href={content?.link || '/'}>{`${content?.title || ''} `}</a>
                    )}
                  </li>
                ))}
              </ul>
              <p>{`${mapLocaleToMeaningfulFormat(router.locale).copyrightSymbol} ${year || ''} ${
                copyrightText || ''
              }`}</p>
            </section>
          </section>
        </section>
      </section>
    </>
  );
};
