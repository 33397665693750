import React, { Fragment, useRef, useEffect, useState, useCallback, useContext } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Popover, Transition } from '@headlessui/react';
import { Category } from '@Types/product/Category';
import AccountIcon from 'components/icons/sol/account';
import CartIcon from 'components/icons/sol/cart';
import WishlistIcon from 'components/icons/sol/wishlist';
import Notification from 'components/sol-ui/notification';
import InlineNotification from 'components/sol-ui/notification/inline-notification';
import { cartQuantityTotal } from 'helpers/cartQuantityTotal';
import { SIGNED_OUT } from 'helpers/constants/messages';
import { CurrentPathContext } from 'helpers/continueShoppingContext/continueShoppingContext';
import { useNotification } from 'helpers/notification/notificationContext';
import { PageLoadContext } from 'helpers/page-load-context/pageLoadContext';
import { getReferenceTarget } from 'helpers/reference';
import { StringHelpers } from 'helpers/stringHelpers';
import { gtmViewItemList, gtmViewCart } from 'helpers/utils/gtm-events/gtmEcommerce';
import { gtmMenuNavigation } from 'helpers/utils/gtm-events/gtmInteraction';

import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { useAccount, useCart, useWishlist } from 'frontastic';
import HeaderLogo from '../../../icons/sol/header-logo';
import Login from '../../login/account-login';
import ImpersonationHeader from './Impersonation-header';
import { MiniCart } from './mini-cart';
import SearchBarComponent from './search-bar';
import { gtmUserLogout } from 'helpers/utils/gtm-events/gtmUser';

interface SOLHeaderDeskTopType {
  data: any;
  categories: Category[];
}

export const SOLHeaderDesktop = ({ data, categories }: SOLHeaderDeskTopType) => {
  const router = useRouter();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [openMegaNav, setOpenMegaNav] = useState(true);
  const {
    subMenuLevel2SpecificCategory,
    subMenuLevel2SpecificCategoryCount,
    subMenuLevel1Count,
    subMenuLevel2Count,
    accountLink,
    notificationTimeout,
    loginLink,
    searchContentCount,
    searchRelatedProdutsCount,
    searchSuggestedSearchesCount,
    maximumLoginAttemptTimeout,
  } = data;

  const { logout, loggedIn, impersonateUser, account } = useAccount();
  const { showNotification, showInlineNotification } = useNotification();
  const { data: cart } = useCart();
  const { data: wishlist } = useWishlist();
  const { setIsPageLoading } = useContext(PageLoadContext);
  const { setCurrentPath } = useContext(CurrentPathContext);

  const { notification, inlineNotification, hideNotification, hideInlineNotification } = useNotification();
  const [showMegaNav, setShowMegaNav] = useState(false);

  const handleLogout = useCallback(async () => {
    setIsPageLoading(true);
    const response = await logout();
    window.location.href = getReferenceTarget(loginLink);
    setIsPageLoading(false);
    gtmUserLogout();
    showNotification(SIGNED_OUT, 'success');
  }, [logout, showNotification, router, loginLink]);

  const handleRouteChange = () => {
    localStorage.setItem('pageNo', '1');
    localStorage.setItem('scrollPosition', '0');
  };

  useEffect(() => {
    if (notification?.message) {
      hideNotification();
    }
    if (inlineNotification?.message) {
      hideInlineNotification();
    }
    const excludedPaths = [
      routerURL?.cart,
      routerURL?.account,
      routerURL?.orderHistory,
      routerURL?.myList,
      routerURL?.address,
      routerURL?.creditCard,
      routerURL?.login,
    ];
    if (!excludedPaths.includes(router.asPath)) {
      setCurrentPath(router.asPath);
    }
  }, [router.asPath]);

  return (
    <section className="shadow-[0px_2px_4px_rgba(177, 154, 106, 0.3)] min-h-[191px] w-full flex-col bg-sol-200 text-xs font-bold">
      {/* This code will uncomment by BE */}
      {/* <section className="inline-block w-full">
        <ImpersonationHeader />
      </section> */}
      <section className="mx-auto flex max-w-screen-8xl items-center justify-between px-2 pt-4 pb-0 ">
        <Notification timeoutInMs={notificationTimeout} />
        <InlineNotification timeoutInMs={maximumLoginAttemptTimeout} />
        <SearchBarComponent
          categories={categories}
          searchRelatedProdutsCount={searchRelatedProdutsCount}
          searchSuggestedSearchesCount={searchSuggestedSearchesCount}
          searchContentCount={searchContentCount}
        />

        <Link href={routerURL?.home}>
          <a id="headerLogo" aria-label="shades of light logo">
            <HeaderLogo className="h-[98px] w-[347px]" />
          </a>
        </Link>
        <ul className="relative top-[6px] mr-3 flex w-[267px] items-end justify-end gap-5">
          <li className="flex  flex-col items-center justify-center">
            <section className="grid justify-between">
              <section>
                {loggedIn && (
                  <section className="relative">
                    <Link href={routerURL?.account}>
                      <div
                        id="headerAccount"
                        className="flex cursor-pointer flex-col items-center gap-2 px-1 py-3 text-xs font-semibold leading-[15px]"
                        onClick={() => {
                          if (window.location.href.includes('search') || window.location.href.includes('categories')) {
                            setCurrentPath(location.href.replace(location.origin, ''));
                          }
                        }}
                      >
                        <AccountIcon className="fill-sol-300" />
                        {mapLocaleToMeaningfulFormat(router.locale).account}
                      </div>
                    </Link>
                    <button
                      type="button"
                      id="headerLogout"
                      onClick={handleLogout}
                      className="absolute -bottom-1 left-[4px] w-max text-xs font-semibold leading-[15px] "
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).signOut}
                      <span className="absolute bottom-0 left-0 top-[12.78646px] h-[.75px] w-full bg-[#000]"></span>
                    </button>
                  </section>
                )}
                {!loggedIn && (
                  <Popover>
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={`${
                            open
                              ? 'border-black-500 border-opacity-20 bg-[#fcfcfa]  shadow-[0px_4px_4px_0px_#B19A6A4D]'
                              : ''
                          } group inline-flex items-center rounded-t-md border border-transparent px-1 py-3 text-base font-medium hover:text-opacity-100`}
                        >
                          <section id="headerAccount" className="text-center">
                            <p className="flex flex-col items-center gap-2 text-xs font-semibold leading-[15px] text-sol-300">
                              <AccountIcon className="fill-sol-300" />
                              {mapLocaleToMeaningfulFormat(router.locale).account}
                            </p>
                          </section>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute right-0 z-30 -mt-[3px] max-w-[368px] origin-top-right">
                            <section className="relative border border-black-500 border-opacity-20 bg-[#fcfcfa] px-5 py-7  shadow-[0px_4px_4px_rgba(177,154,106,0.3)] after:absolute after:right-[163px] after:top-[-1px] after:inline-block after:h-[1px] after:w-[60.922px] after:bg-[#fcfcfa] after:content-[''] sm:w-[373px]">
                              <Popover.Button
                                as={() => (
                                  <Login
                                    accountLink={accountLink}
                                    loginLink={loginLink}
                                    notificationTimeout={notificationTimeout}
                                    isPopOverPanel={false}
                                    showRememberButton={true}
                                    loginTitle={`${mapLocaleToMeaningfulFormat(router.locale).logInAccount}`}
                                    closeModal={() => close()}
                                  />
                                )}
                              ></Popover.Button>
                              <section>
                                <div className="mt-6 h-[1px] w-full bg-[#B19A6A]"></div>
                                <section className="flex justify-center pt-4">
                                  <Popover.Panel>
                                    {({ close }) => (
                                      <Link href={routerURL?.login}>
                                        <a
                                          className="m-3 text-center text-sm font-semibold leading-[17px] hover:underline"
                                          onClick={() => close()}
                                        >
                                          {mapLocaleToMeaningfulFormat(router.locale).createAccount}
                                        </a>
                                      </Link>
                                    )}
                                  </Popover.Panel>
                                </section>
                              </section>
                            </section>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                )}
              </section>
            </section>
          </li>
          {/*<li
            className="flex  flex-col items-center gap-2 py-3"
            onClick={async () => {
              await impersonateUser(router.query['login'] as string);
            }}
          >
            <Link href="#">
              <a id="impersonate" className="flex flex-col items-center gap-2 text-xs font-semibold leading-[15px]">
                <span>Impersonate</span>
              </a>
            </Link>
        </li>*/}
          <li
            className="flex  flex-col items-center gap-2 py-3"
            onClick={() => {
              if (loggedIn && router.asPath != routerURL?.myList) {
                gtmViewItemList({
                  item_list_id: wishlist?.wishlistId,
                  item_list_name: wishlist?.name,
                  items:
                    wishlist?.lineItems.map((lineItem, i) => {
                      return {
                        item_id: lineItem.variant.sku,
                        item_name: lineItem.name,
                        index: i,
                        item_variant: lineItem.variant?.attributes?.collection,
                        // price: calculatePrice(lineItem.variant?.discountedPrice || lineItem.variant?.price),
                        price:
                          lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                          lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
                        quantity: lineItem.count,
                        item_image: lineItem?.variant?.images?.[0]?.url,
                        item_url: lineItem?._url,
                      };
                    }) ?? [],
                });
              }
            }}
          >
            <Link href={loggedIn ? routerURL?.myList : routerURL?.login}>
              <a
                id="headerWishlist"
                className="flex flex-col items-center gap-2 text-xs font-semibold leading-[15px]"
                onClick={() => {
                  if (window.location.href.includes('search') || window.location.href.includes('categories')) {
                    setCurrentPath(location.href.replace(location.origin, ''));
                  }
                }}
              >
                <WishlistIcon className="fill-sol-300" />
                <span>
                  {mapLocaleToMeaningfulFormat(router.locale).myList}{' '}
                  <span>
                    ({loggedIn ? (wishlist?.lineItems?.length > 0 ? cartQuantityTotal(wishlist?.lineItems) : 0) : 0})
                  </span>
                </span>
              </a>
            </Link>
          </li>
          <li className="flex  flex-col items-center justify-center">
            <section className="grid justify-between">
              <section>
                <Popover>
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={`${
                          open
                            ? ' border-black-500 border-opacity-20 bg-[#fcfcfa]  shadow-[0px_4px_4px_0px_#B19A6A4D]'
                            : ''
                        } group inline-flex items-center rounded-t-md border border-transparent px-1 py-3 text-base font-medium hover:text-opacity-100`}
                      >
                        <section className="text-center" id="miniCart">
                          <p
                            className="flex flex-col items-center gap-2 text-xs font-semibold leading-[15px] text-sol-300"
                            onClick={() => {
                              if (
                                window.location.href.includes('search') ||
                                window.location.href.includes('categories')
                              ) {
                                setCurrentPath(location.href.replace(location.origin, ''));
                              }
                              if (!open) {
                                gtmViewCart(cart, account);
                              }
                            }}
                          >
                            <CartIcon className="fill-sol-300" />
                            <span>
                              {mapLocaleToMeaningfulFormat(router.locale).cart}{' '}
                              <span>({cart?.lineItems?.length > 0 ? cartQuantityTotal(cart?.lineItems) : 0})</span>
                            </span>
                          </p>
                        </section>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute right-0 z-30 -mt-[3px] max-h-[598px] max-w-[590px] origin-top-right">
                          <section className="relative border border-black-500 border-opacity-20 bg-[#fcfcfa] pt-7  shadow-[0px_4px_4px_rgba(177,154,106,0.3)] after:absolute after:right-0 after:top-[-1px] after:inline-block after:h-[1px] after:w-[55px] after:bg-[#fcfcfa] after:content-[''] sm:w-[590px]">
                            <Popover.Panel>
                              {({ close }) => <MiniCart cart={cart} desktopModalClose={close} />}
                            </Popover.Panel>
                          </section>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </section>
            </section>
          </li>
        </ul>
      </section>
      <section className="relative z-[9] w-full px-6">
        <nav className="mx-auto mt-5 w-full max-w-[1440px]">
          <ul id="megaMenu" className="menuList flex min-h-[56px] w-full items-stretch justify-between">
            {categories?.map((categoryLvl1: Category, index: number) => (
              <li
                key={index}
                className={`dropdown group flex grow items-center justify-center border-b-4 border-transparent py-4 after:inline-block after:h-[21px] after:w-[1px] after:bg-[rgb(0,0,0)]/30 after:content-[''] after:last:hidden hover:border-[#B19A6A] focus:border-transparent focus:ring-0`}
                onMouseEnter={() => {
                  setShowMegaNav(true);
                }}
                onMouseLeave={() => {
                  setShowMegaNav(false);
                }}
                onClick={handleRouteChange}
              >
                {categoryLvl1?.isTextOnly ? (
                  <p className="catLevel1 flex w-full items-center justify-center px-2 text-center text-[13px] font-semibold leading-4 text-[#222222] hover:cursor-default">
                    {' '}
                    {categoryLvl1?.alternateDisplayName
                      ? categoryLvl1?.alternateDisplayName
                      : StringHelpers.titleCaseFormatter(categoryLvl1?.name)}
                  </p>
                ) : (
                  <Link href={StringHelpers.categoryRouteFormatter(categoryLvl1)}>
                    <a
                      onClick={() => {
                        /**
                         * Convert ['Chandeliers', '&', 'Pendants'] to -> Chandeliers & Pendants
                         */
                        const arrayStringToString = StringHelpers.titleCaseFormatter(categoryLvl1?.name)
                          .map((item) => item.trim())
                          .join(' ');
                        gtmMenuNavigation({
                          click_location: StringHelpers.categoryRouteFormatter(categoryLvl1),
                          click_name: categoryLvl1?.alternateDisplayName || arrayStringToString,
                        });
                      }}
                      className="catLevel1 flex w-full items-center justify-center px-2 text-center text-[13px] font-semibold leading-4 text-[#222222] "
                    >
                      {' '}
                      {categoryLvl1?.alternateDisplayName
                        ? categoryLvl1?.alternateDisplayName
                        : StringHelpers.titleCaseFormatter(categoryLvl1?.name)}
                    </a>
                  </Link>
                )}

                {showMegaNav && (
                  <section className="dropdown-menu absolute left-0 top-[100%] z-[9] hidden h-auto w-full bg-[#fcfcfa] px-6 shadow-[0px_4px_4px_rgba(177,154,106,0.3)] transition-all group-hover:block">
                    <section className="mx-auto grid max-w-[1440px]">
                      <section className="flex justify-center gap-10 px-3">
                        {categoryLvl1?.children?.map(
                          (categoryLvl2: Category, index) =>
                            categoryLvl2?.name !== '' && (
                              <section
                                className={`${
                                  subMenuLevel2SpecificCategory.some(
                                    (category) => categoryLvl2?.overrideURL === category?.subMenuLevel2,
                                  ) &&
                                  categoryLvl1?.children?.length < subMenuLevel1Count &&
                                  categoryLvl2?.children?.length > subMenuLevel2Count
                                    ? 'w-[40%]'
                                    : 'w-[20%]'
                                } my-10`}
                                key={index}
                              >
                                <section onClick={handleRouteChange}>
                                  <h4 className={`mb-3`}>
                                    {categoryLvl2?.isTextOnly ? (
                                      <p className="catLevel2 inline-block text-sm font-semibold leading-[17px] hover:cursor-default">
                                        {categoryLvl2?.alternateDisplayName
                                          ? categoryLvl2?.alternateDisplayName
                                          : StringHelpers.titleCaseFormatter(categoryLvl2?.name)}
                                      </p>
                                    ) : (
                                      <a
                                        href={StringHelpers.categoryRouteFormatter(categoryLvl2)}
                                        onClick={() => {
                                          const arrayStringToString = StringHelpers.titleCaseFormatter(
                                            categoryLvl2?.name,
                                          )
                                            .map((item) => item.trim())
                                            .join(' ');
                                          gtmMenuNavigation({
                                            click_location: StringHelpers.categoryRouteFormatter(categoryLvl2),
                                            click_name: categoryLvl2?.alternateDisplayName || arrayStringToString,
                                          });
                                        }}
                                        className="catLevel2 inline-block text-sm font-semibold leading-[17px] hover:underline"
                                      >
                                        {categoryLvl2?.alternateDisplayName
                                          ? categoryLvl2?.alternateDisplayName
                                          : StringHelpers.titleCaseFormatter(categoryLvl2?.name)}
                                      </a>
                                    )}
                                  </h4>
                                  {subMenuLevel2SpecificCategory.map(
                                    (category, categoryIndex) =>
                                      categoryLvl2?.overrideURL === category?.subMenuLevel2 && (
                                        <ul
                                          className={`${
                                            categoryLvl1?.children?.length < subMenuLevel1Count &&
                                            categoryLvl2?.children?.length > subMenuLevel2Count
                                              ? 'flex'
                                              : 'block'
                                          }`}
                                          key={categoryIndex}
                                        >
                                          <div className="w-full items-stretch justify-between">
                                            {categoryLvl1?.children?.length < subMenuLevel1Count &&
                                            categoryLvl2?.children?.length > subMenuLevel2Count
                                              ? categoryLvl2?.children
                                                  .slice(0, subMenuLevel2SpecificCategoryCount)
                                                  ?.map((categoryLvl3, index) => (
                                                    <li key={index} className="py-1" onClick={handleRouteChange}>
                                                      {categoryLvl3?.isTextOnly ? (
                                                        <p className="catLevel3 inline-block text-[13px] font-normal leading-[19px] text-[#222222] hover:cursor-default">
                                                          {categoryLvl3?.alternateDisplayName
                                                            ? categoryLvl3?.alternateDisplayName
                                                            : StringHelpers.titleCaseFormatter(categoryLvl3?.name)}
                                                        </p>
                                                      ) : (
                                                        <a
                                                          onClick={() => {
                                                            const arrayStringToString =
                                                              StringHelpers.titleCaseFormatter(categoryLvl3?.name)
                                                                .map((item) => item.trim())
                                                                .join(' ');
                                                            gtmMenuNavigation({
                                                              click_location:
                                                                StringHelpers.categoryRouteFormatter(categoryLvl3),
                                                              click_name:
                                                                categoryLvl3?.alternateDisplayName ||
                                                                arrayStringToString,
                                                            });
                                                          }}
                                                          href={StringHelpers.categoryRouteFormatter(categoryLvl3)}
                                                          className="catLevel3 inline-block text-[13px] font-normal leading-[19px] text-[#222222] hover:underline"
                                                        >
                                                          {categoryLvl3?.alternateDisplayName
                                                            ? categoryLvl3?.alternateDisplayName
                                                            : StringHelpers.titleCaseFormatter(categoryLvl3?.name)}
                                                        </a>
                                                      )}
                                                    </li>
                                                  ))
                                              : categoryLvl2?.children
                                                  .slice(0, subMenuLevel2Count)
                                                  ?.map((categoryLvl3, index) => (
                                                    <li key={index} className="py-1" onClick={handleRouteChange}>
                                                      {categoryLvl3?.isTextOnly ? (
                                                        <p className="catLevel3 inline-block text-[13px] font-normal leading-[19px] text-[#222222] hover:cursor-default">
                                                          {categoryLvl3?.alternateDisplayName
                                                            ? categoryLvl3?.alternateDisplayName
                                                            : StringHelpers.titleCaseFormatter(categoryLvl3?.name)}
                                                        </p>
                                                      ) : (
                                                        <a
                                                          onClick={() => {
                                                            const arrayStringToString =
                                                              StringHelpers.titleCaseFormatter(categoryLvl3?.name)
                                                                .map((item) => item.trim())
                                                                .join(' ');
                                                            gtmMenuNavigation({
                                                              click_location:
                                                                StringHelpers.categoryRouteFormatter(categoryLvl3),
                                                              click_name:
                                                                categoryLvl3?.alternateDisplayName ||
                                                                arrayStringToString,
                                                            });
                                                          }}
                                                          href={StringHelpers.categoryRouteFormatter(categoryLvl3)}
                                                          className="catLevel3 inline-block text-[13px] font-normal leading-[19px] text-[#222222] hover:underline"
                                                        >
                                                          {categoryLvl3?.alternateDisplayName
                                                            ? categoryLvl3?.alternateDisplayName
                                                            : StringHelpers.titleCaseFormatter(categoryLvl3?.name)}
                                                        </a>
                                                      )}
                                                    </li>
                                                  ))}
                                          </div>
                                          {categoryLvl2?.children?.length > subMenuLevel2SpecificCategoryCount &&
                                            (categoryLvl1?.children?.length < subMenuLevel1Count &&
                                            categoryLvl2?.children?.length > subMenuLevel2Count ? (
                                              <div className="w-full items-stretch justify-between">
                                                {categoryLvl2?.children
                                                  .slice(subMenuLevel2SpecificCategoryCount)
                                                  ?.map((categoryLvl3, index) => (
                                                    <li key={index} className="py-1" onClick={handleRouteChange}>
                                                      {categoryLvl3?.isTextOnly ? (
                                                        <p className="catLe vel3 inline-block text-[13px] font-normal leading-[19px] text-[#222222] hover:cursor-default">
                                                          {categoryLvl3?.alternateDisplayName
                                                            ? categoryLvl3?.alternateDisplayName
                                                            : StringHelpers.titleCaseFormatter(categoryLvl3?.name)}
                                                        </p>
                                                      ) : (
                                                        <a
                                                          onClick={() => {
                                                            const arrayStringToString =
                                                              StringHelpers.titleCaseFormatter(categoryLvl3?.name)
                                                                .map((item) => item.trim())
                                                                .join(' ');
                                                            gtmMenuNavigation({
                                                              click_location:
                                                                StringHelpers.categoryRouteFormatter(categoryLvl3),
                                                              click_name:
                                                                categoryLvl3?.alternateDisplayName ||
                                                                arrayStringToString,
                                                            });
                                                          }}
                                                          href={StringHelpers.categoryRouteFormatter(categoryLvl3)}
                                                          className="catLevel3 inline-block text-[13px] font-normal leading-[19px] text-[#222222] hover:underline"
                                                        >
                                                          {categoryLvl3?.alternateDisplayName
                                                            ? categoryLvl3?.alternateDisplayName
                                                            : StringHelpers.titleCaseFormatter(categoryLvl3?.name)}
                                                        </a>
                                                      )}
                                                    </li>
                                                  ))}
                                              </div>
                                            ) : (
                                              categoryLvl2?.children?.length > subMenuLevel2Count && (
                                                <li className="py-1" onClick={handleRouteChange}>
                                                  <a
                                                    onClick={() => {
                                                      gtmMenuNavigation({
                                                        click_location:
                                                          StringHelpers.categoryRouteFormatter(categoryLvl2),
                                                        click_name: mapLocaleToMeaningfulFormat(router.locale)
                                                          .exploreAll,
                                                      });
                                                    }}
                                                    href={StringHelpers.categoryRouteFormatter(categoryLvl2)}
                                                    className="inline-block text-[13px] font-bold leading-[19px] text-[#222222] hover:underline"
                                                  >
                                                    {mapLocaleToMeaningfulFormat(router.locale).exploreAll}
                                                  </a>
                                                </li>
                                              )
                                            ))}
                                        </ul>
                                      ),
                                  )}

                                  {!subMenuLevel2SpecificCategory.some(
                                    (category) => categoryLvl2?.overrideURL === category?.subMenuLevel2,
                                  ) && (
                                    <ul>
                                      {categoryLvl2?.children
                                        .slice(0, subMenuLevel2Count)
                                        ?.map((categoryLvl3, index) => (
                                          <li key={index} className="py-1" onClick={handleRouteChange}>
                                            {categoryLvl3?.isTextOnly ? (
                                              <p className="catLevel3 inline-block text-[13px] font-normal leading-[19px] text-[#222222] hover:cursor-default">
                                                {categoryLvl3?.alternateDisplayName
                                                  ? categoryLvl3?.alternateDisplayName
                                                  : StringHelpers.titleCaseFormatter(categoryLvl3?.name)}{' '}
                                              </p>
                                            ) : (
                                              <a
                                                onClick={() => {
                                                  const arrayStringToString = StringHelpers.titleCaseFormatter(
                                                    categoryLvl3?.name,
                                                  )
                                                    .map((item) => item.trim())
                                                    .join(' ');
                                                  gtmMenuNavigation({
                                                    click_location: StringHelpers.categoryRouteFormatter(categoryLvl3),
                                                    click_name:
                                                      categoryLvl3?.alternateDisplayName || arrayStringToString,
                                                  });
                                                }}
                                                href={StringHelpers.categoryRouteFormatter(categoryLvl3)}
                                                className="catLevel3 inline-block text-[13px] font-normal leading-[19px] text-[#222222] hover:underline"
                                              >
                                                {categoryLvl3?.alternateDisplayName
                                                  ? categoryLvl3?.alternateDisplayName
                                                  : StringHelpers.titleCaseFormatter(categoryLvl3?.name)}
                                              </a>
                                            )}
                                          </li>
                                        ))}
                                      {categoryLvl2?.children?.length > subMenuLevel2Count && (
                                        <li className="py-1" onClick={handleRouteChange}>
                                          <a
                                            onClick={() => {
                                              gtmMenuNavigation({
                                                click_location: StringHelpers.categoryRouteFormatter(categoryLvl2),
                                                click_name: mapLocaleToMeaningfulFormat(router.locale).exploreAll,
                                              });
                                            }}
                                            href={StringHelpers.categoryRouteFormatter(categoryLvl2)}
                                            className="inline-block text-[13px] font-bold leading-[19px] text-[#222222] hover:underline"
                                          >
                                            {mapLocaleToMeaningfulFormat(router.locale).exploreAll}
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  )}
                                </section>
                              </section>
                            ),
                        )}
                        {categoryLvl1?.content && categoryLvl1?.content?.megaMenuBannerImageURL?.url !== undefined ? (
                          <section className={`p-10`}>
                            <section>
                              <h4 className={`mb-3`}>
                                <a
                                  onClick={() => {
                                    gtmMenuNavigation({
                                      click_location: StringHelpers.categoryRouteFormatter(categoryLvl1),
                                      click_name: mapLocaleToMeaningfulFormat(router.locale).shopAll,
                                    });
                                  }}
                                  href={StringHelpers.categoryRouteFormatter(categoryLvl1)}
                                  className="inline-block text-sm font-semibold leading-[17px] hover:underline"
                                >
                                  {mapLocaleToMeaningfulFormat(router.locale).shopAll}
                                </a>
                              </h4>
                              <ul>
                                <li className="py-1" onClick={handleRouteChange}>
                                  <section>
                                    <a
                                      onClick={() => {
                                        gtmMenuNavigation({
                                          click_location: StringHelpers.categoryRouteFormatter(categoryLvl1),
                                          click_name: mapLocaleToMeaningfulFormat(router.locale).shopAll,
                                        });
                                      }}
                                      href={StringHelpers.categoryRouteFormatter(categoryLvl1)}
                                    >
                                      <Image
                                        className="logo"
                                        loader={(options) => options.src}
                                        width={275}
                                        height={264}
                                        src={categoryLvl1?.content?.megaMenuBannerImageURL?.url || '#'}
                                        alt={categoryLvl1?.content?.megaMenuBannerImageURL?.name}
                                      />
                                    </a>
                                  </section>
                                  <p className="inline-block text-[13px] font-normal leading-[19px] text-[#222222]">
                                    {categoryLvl1?.content?.megaMenuBannerCtaLinkText}
                                  </p>
                                </li>
                              </ul>
                            </section>
                          </section>
                        ) : (
                          <></>
                        )}
                      </section>
                    </section>
                  </section>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </section>
    </section>
  );
};
