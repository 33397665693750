import React, { memo, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { titleCase } from 'converting-case';
import { history } from 'instantsearch.js/es/lib/routers';
import { simple } from 'instantsearch.js/es/lib/stateMappings';
import { InstantSearch, Configure, useStats, SortBy, useInfiniteHits, Index } from 'react-instantsearch';
import DownArrowIcon from 'components/icons/sol/down-icon';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
import useAlgoliaSearchClient from 'helpers/hooks/useAlgoliaSearchClient';
import { useIsMounted } from 'helpers/hooks/useIsMounted';
import { CLEARANCE_CATEGORY_SLUG_PREFIX, CYBER_SALE_CATEGORY_SLUG_PREFIX } from 'helpers/utils/constant';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { buildPLPFacets } from 'helpers/utils/productUrlBuilder';
import ProductList from '../product-list';
import ProductSearchContent from './product-search-content';
import { RouteHelpers } from 'helpers/routeHelpers';

const CustomContentStats = ({ setContentHitsCount }) => {
  const { nbHits } = useStats();

  setContentHitsCount(nbHits);

  return <></>;
};

const CustomProductStats = ({ setProductCount, selectedIndex }) => {
  const { nbHits } = useStats();

  if (selectedIndex === 0) {
    setProductCount(nbHits);
  }
  return <></>;
};

const RenderTotalStats = ({ productCount, contentCount }) => {
  const router = useRouter();

  return (
    <>
      <span>{productCount + contentCount}</span>{' '}
      <span className="capitalize">{mapLocaleToMeaningfulFormat(router.locale).items}</span>
    </>
  );
};

const ProductSearchList = ({
  rawFacetsData,
  data = null,
  facetsDisplay = null,
  setShowNoSearchFound,
  hitsPerPage,
  sortByData,
  setIsFiltersApplied,
  canResetFilters,
  setCanResetFilters,
  CustomSortBy,
  isSearchComponent = true,
  facetFilter,
}) => {
  const { algolia: algoliaConfig } = data.data;
  const router = useRouter();
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [productCount, setProductCount] = useState(0);
  const [contentCount, setContentCount] = useState(1);
  const [title, setTitle] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [queryFilters, setQueryFilters] = useState('') as any;
  const [searchQueryParam, setSearchQueryParam] = useState('') as any;
  const [facetRouterQuery, setFacetRouterQuery] = useState('');
  const searchClient = useAlgoliaSearchClient(algoliaConfig);
  const [seoVariant, setSeoVariant] = useState('');
  const [contentHitsCount, setContentHitsCount] = useState(0);
  const query = (router.query?.q ?? '') as string;
  const facets = 'shownOnSite:true';

  const isMounted = useIsMounted();

  const isClearanceCategory =
    (router?.query?.path?.includes(CLEARANCE_CATEGORY_SLUG_PREFIX) &&
      !router?.query?.path?.includes(CYBER_SALE_CATEGORY_SLUG_PREFIX)) ||
    false ||
    (router?.asPath?.includes(CLEARANCE_CATEGORY_SLUG_PREFIX) &&
      !router?.asPath?.includes(CYBER_SALE_CATEGORY_SLUG_PREFIX)) ||
    false;

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const tmpSortByOptions = sortByData?.map((item) => {
    return {
      label: item?.optionName,
      value: item?.optionIndex.replace('${env}', algoliaConfig?.sortAttributes?.indexPrefix),
      paramName: item?.paramName,
    };
  });

  useEffect(() => {
    const { q, collection } = router.query;
    setSearchQueryParam(q.toString());
    setTitle(q?.toString());
    setQueryFilters(router.query.filter);
    if (queryFilters != undefined && queryFilters != 'shownOnSite:true') {
      if (queryFilters == '') {
        setQueryFilters('shownOnSite:true');
      } else {
        const facets = buildPLPFacets(data?.route, '', isClearanceCategory);
        setQueryFilters(queryFilters + `AND ${facets}`);
      }
      if (collection) {
        setTitle('' + collection);
        setQueryFilters(`collection:${collection}`);
      }
    }
  }, [router.query]);

  useEffect(() => {
    const currentPageNo = localStorage?.getItem('pageNo');
    if (currentPageNo) {
      setCurrentPageNo(parseInt(currentPageNo));
    } else {
      localStorage?.setItem('pageNo', '1');
    }
  }, []);

  useEffect(() => {
    // Setting the currentNav as search-result for pdp breadcurmbs
    localStorage.setItem('currentNav', 'search-result');
    // Check if content tab is selected
    if (router?.asPath?.includes('&type=content')) setSelectedIndex(1);
  }, []);

  const handleTabChange = (index) => {
    setSelectedIndex(index);
    // Get current URL and query parameters
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (index == 1) params.set('type', 'content');
    if (index == 0) params.delete('type');
    for (const [key, value] of params.entries()) {
      params.set(key, encodeURIComponent(value));
    }
    url.search = params.toString();
    // Push the updated URL to the browser history without reloading
    window.history.pushState({}, '', url);
  };

  useEffect(() => {
    const handleRouteChange = (event) => {
      // Prevent default browser behavior
      event.preventDefault();
      if (window.location.href.includes('search')) window.location.reload();
    };
    window.addEventListener('popstate', handleRouteChange);
  }, []);

  const memoizedProductConfigure = useMemo(() => {
    return (
      <Configure
        query={query}
        filters={facets}
        facetingAfterDistinct={true}
        hitsPerPage={hitsPerPage * currentPageNo}
        highlightPostTag="__/ais-highlight__"
        highlightPreTag="__ais-highlight__"
        page={0}
        maxValuesPerFacet={1000}
        facets={['*']}
      />
    );
  }, [query, currentPageNo]);

  const memoizedContentConfigure = useMemo(() => {
    return <Configure query={query} distinct facetingAfterDistinct={true} hitsPerPage={5} />;
  }, [router.query?.q]);

  const CustomContentComponent = memo(function CustomContentComponent() {
    const { hits, isLastPage, showMore } = useInfiniteHits();
    return (
      <>
        {selectedIndex == 1 && algoliaConfig.indexNameBlogs.length > 0 && (
          <section className="mt-[14px]">
            <ProductSearchContent indexType="Blogs" data={{ hits, isLastPage, showMore }} />
          </section>
        )}
      </>
    );
  });

  const routing = {
    router: history({
      cleanUrlOnDispose: false,
      writeDelay: 400,
      createURL({ qsModule, routeState, location }: any) {
        const routeValues = routeState[algoliaConfig.indexNameProductsMain];

        const queryParameters: any = {};

        if (searchQueryParam) {
          queryParameters['q'] = query;
        }

        if (location.href?.includes('type=content')) {
          queryParameters['type'] = 'content';
        }

        if (routeValues && routeValues.refinementList) {
          for (const key in routeValues.refinementList) {
            if (Object.prototype.hasOwnProperty.call(routeValues.refinementList, key)) {
              const refinement = routeValues.refinementList[key];
              const facet = rawFacetsData?.facets?.find((facet) => facet.facetKey === key);
              if (facet) {
                if (facet.paramName) {
                  queryParameters[facet.paramName] = refinement;
                } else {
                  queryParameters[key] = refinement;
                }
              }
            }
          }
        }

        if (routeValues && routeValues?.page) {
          queryParameters['page'] = routeValues?.page;
        }

        if (routeValues && routeValues.sortBy) {
          const sortByOption = routeValues.sortBy;
          const selectedSortByOptions = tmpSortByOptions?.find((option) => option.value === sortByOption);
          queryParameters['sort'] = selectedSortByOptions?.paramName;
          if (selectedSortByOptions?.paramName) {
            queryParameters['sort'] = selectedSortByOptions.paramName;
          } else {
            queryParameters['sort'] = routeValues.sortBy;
          }
        }

        // Sort filter values alphabetically
        Object.keys(queryParameters).forEach((key) => {
          if (Array.isArray(queryParameters[key])) {
            queryParameters[key] = queryParameters[key].sort((a, b) => a.localeCompare(b));
          }
        });

        const queryString = RouteHelpers.encodeToURL(queryParameters);

        return window.location.href.includes('search') ? queryString : '';
      },
      parseURL({ qsModule, location }) {
        if (isMounted) {
          const queryParams = RouteHelpers.decodeToObj(location.search);

          const uiState = {};
          // Convert queryParams back into the UI state expected by React InstantSearch
          for (const [key, value] of Object.entries(queryParams)) {
            // Example: Convert back based on your paramName mapping in rawFacetsData
            const facet = rawFacetsData?.facets?.find((facet) => facet.paramName === key || facet.facetKey === key);
            if (facet) {
              const facetKey = facet.facetKey || key;
              if (!uiState[algoliaConfig.indexNameProductsMain]) {
                uiState[algoliaConfig.indexNameProductsMain] = { refinementList: {} };
              }
              uiState[algoliaConfig.indexNameProductsMain].refinementList[facetKey] = Array.isArray(value)
                ? value
                : [value];
            }
          }

          // Handle special parameters like page and sort
          if (queryParams?.page) {
            uiState[algoliaConfig.indexNameProductsMain] = {
              ...uiState[algoliaConfig.indexNameProductsMain],
              page: queryParams?.page,
            };
          }

          if (queryParams.q) {
            uiState[algoliaConfig.indexNameProductsMain] = {
              ...uiState[algoliaConfig.indexNameProductsMain],
              query: queryParams.q,
            };
          }

          if (queryParams.sort) {
            const sortByOption = queryParams.sort;
            const selectedSortByOptions = tmpSortByOptions?.find((option) => option.paramName === sortByOption);
            uiState[algoliaConfig.indexNameProductsMain] = {
              ...uiState[algoliaConfig.indexNameProductsMain],
              sortBy: selectedSortByOptions.value,
            };
          }

          return uiState;
        }
      },
    }),
    stateMapping: simple(),
  };

  return (
    <>
      {facetsDisplay ? (
        <section className="min-h-[100vh]">
          <Breadcrumb breadcrumLevelLink={true} activePage={mapLocaleToMeaningfulFormat(router.locale).searchResults} />
          <section className="mx-auto my-10 max-w-[1440px] px-5 md:px-10">
            <section className="mb-9">
              <h1
                id="resultTitle"
                className="mx-auto mb-5 text-center text-2xl font-bold leading-[29px] text-sol-400 after:mx-auto after:mt-6 after:block after:h-[1px] after:w-[150px] after:bg-[#B19A6A] after:content-['']"
              >
                <span>{mapLocaleToMeaningfulFormat(router.locale).searchResultsFor} </span>
                <span className="block sm:inline">{`"${titleCase(title ? title : '')}"`}</span>
              </h1>
              <p className="text-center text-sm text-sol-300">
                <RenderTotalStats productCount={productCount} contentCount={contentCount} />
              </p>
              <section>
                <div className="w-full">
                  <section>
                    <section className="mt-10 mb-7 flex h-11 justify-center gap-8 border-b-transparent md:px-0">
                      <div
                        id="tabProduct"
                        aria-label="Search By Product"
                        onClick={() => handleTabChange(0)}
                        className={classNames(
                          'flex h-11 w-1/2 cursor-pointer items-center justify-center  rounded-t-[6px] border-b-4 border-transparent px-2 pb-3 text-base sm:w-auto md:w-auto md:border-b-4 md:pb-2',

                          selectedIndex == 0
                            ? 'mt-[1px] flex h-11 items-center justify-center border-[#B19A6A] bg-white font-semibold md:mt-0'
                            : ' ',
                        )}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).products} {`(${productCount})`}
                      </div>

                      {contentCount != 0 ? (
                        <div
                          id="tabContent"
                          aria-label="Search By Content"
                          onClick={() => handleTabChange(1)}
                          className={classNames(
                            'flex h-11 w-1/2 cursor-pointer items-center justify-center  rounded-t-[6px] border-b-4 border-transparent px-2 pb-3 text-base sm:w-auto md:w-auto md:border-b-4 md:pb-2',

                            selectedIndex == 1
                              ? 'mt-[1px] flex h-11 items-center justify-center border-[#B19A6A] bg-white font-semibold md:mt-0'
                              : ' ',
                          )}
                        >
                          {mapLocaleToMeaningfulFormat(router.locale).content} {`(${contentCount})`}
                        </div>
                      ) : (
                        <></>
                      )}
                    </section>
                    <section className="mt-2">
                      {isMounted && facetsDisplay && facetsDisplay.length && (
                        <>
                          <InstantSearch
                            searchClient={searchClient}
                            indexName={algoliaConfig.indexNameProductsMain}
                            routing={routing}
                            insights={true}
                            future={{
                              preserveSharedStateOnUnmount: true,
                            }}
                          >
                            {/* Product Index */}
                            <Index indexName={algoliaConfig.indexNameProductsMain}>
                              {selectedIndex === 0 && (
                                <ProductList
                                  facetsDisplay={facetsDisplay}
                                  data={data}
                                  downrodsContent={data?.data?.downrodsContent}
                                  algoliaData={algoliaConfig}
                                  sortByData={sortByData}
                                  setIsFiltersApplied={setIsFiltersApplied}
                                  canResetFilters={canResetFilters}
                                  setCanResetFilters={setCanResetFilters}
                                  isClearanceCategory={isClearanceCategory}
                                  CustomSortBy={CustomSortBy}
                                  setShowNoSearchFound={setShowNoSearchFound}
                                  setSeoVariant={setSeoVariant}
                                  isSearchComponent={isSearchComponent}
                                  setCurrentPageNo={setCurrentPageNo}
                                />
                              )}
                              {memoizedProductConfigure}
                              <CustomProductStats selectedIndex={selectedIndex} setProductCount={setProductCount} />
                            </Index>

                            {/* Content Index */}
                            <Index indexName={algoliaConfig.indexNameBlogs}>
                              {selectedIndex == 1 && (
                                <section className="mx-auto max-w-[1440px]">
                                  <section className="sortByBtn flex w-full justify-end">
                                    <SortBy
                                      className="relative cursor-pointer"
                                      items={[
                                        {
                                          label: 'Relevance',
                                          value: algoliaConfig.indexNameBlogs,
                                        },
                                        {
                                          label: 'Newest',
                                          value: algoliaConfig.indexNameBlogsNewest,
                                        },
                                      ]}
                                    />
                                    <DownArrowIcon className="relative top-[17px] right-[25px] h-[12px] w-[13px]" />
                                  </section>
                                </section>
                              )}

                              <CustomContentComponent />

                              {memoizedContentConfigure}

                              <CustomContentStats setContentHitsCount={setContentCount} />
                            </Index>
                          </InstantSearch>
                        </>
                      )}
                    </section>
                  </section>
                </div>
              </section>
            </section>
          </section>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProductSearchList;
