import PDP from 'components/sol-ui/products/details';
import { DATA_SOURCE_LINKS } from 'helpers/utils/constant';
import { DataSourceExtractorHelpers } from 'helpers/utils/dataSourceExtractor';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

function ProductDetailsTastic(data) {
  const productData = DataSourceExtractorHelpers.getPreloadedValue(
    DATA_SOURCE_LINKS.PRODUCT_DETAILS,
    data?.pageFolder?.dataSourceConfigurations,
  );
  const [navigationLink, setNavigationLink] = useState(undefined);
  const router = useRouter();

  useEffect(() => {
    if (productData && !productData?.isAvailable) {
      localStorage.setItem(
        'product404',
        JSON.stringify({ ...productData?.content404, productName: productData?.product?.name }),
      );
      router.push('/not-found');
    }
  }, [productData]);

  return (
    productData?.isAvailable && (
      <>
        <Head>
          <script
            type="text/javascript"
            src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
            defer
          ></script>
        </Head>
        <PDP
          productDataSource={productData.product}
          algolia={productData.algolia}
          dataSource={data}
          optionalAccessory={data?.data?.data?.dataSource?.content}
          pdpSelectedVariant={undefined}
          setNavigationLink={setNavigationLink}
          productId={productData?.product?.incompleteVariantList ? productData?.product?.productId : null}
          closePlpQuickViewModal={undefined}
          pdpNavigationLink={navigationLink}
          isYMALEnabled={data?.data?.isYMALEnabled}
          isShopCollectionEnabled={data?.data?.isShopCollectionEnabled}
        />
      </>
    )
  );
}

export default ProductDetailsTastic;
