import { Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { Account } from '@Types/account/Account';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import InfoIcon from 'components/icons/sol/info-icon';
import Password from 'components/sol-ui/form-fields/password';
import { INCORRECT_OLD_PASSWORD, PROFILE_UPDATED } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import validationSchema from 'helpers/utils/validationSchema/edit-profile';
import { useDarkMode, useAccount } from 'frontastic';
import CloseIcon from '../../../../icons/sol/close';
import FormEventsAnalyticsTracker from 'components/sol-ui/form-events-analytics-tracker';
import useFormInteraction from 'helpers/hooks/useFormInteraction';
export interface EditProfileProps {
  modelOpen?: boolean;
  onClose?: () => void;
  modalHeading?: string;
  primaryBtnContent?: string;
  account: Account;
}

const EditProfile: React.FC<EditProfileProps> = ({ modelOpen, onClose, modalHeading, primaryBtnContent, account }) => {
  //next/router
  const router = useRouter();
  const { changePassword, update } = useAccount();
  const editProfileInitialValues = {
    email: account?.email || '',
    firstName: account?.firstName || '',
    lastName: account?.lastName || '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
  const { showNotification } = useNotification();
  const { handleSubmitInteraction } = useFormInteraction(modalHeading);
  const { mode, theme } = useDarkMode();
  const renderError = (message: any) => <p className="mt-2 text-sm leading-[17px] text-sol-700">{message}</p>;
  const handleEditProfile = async (values: any) => {
    try {
      if (values?.oldPassword && values?.newPassword) {
        const response = await changePassword(values?.oldPassword, values?.newPassword);
        if (response.accountId) {
          await update({ firstName: values?.firstName, lastName: values?.lastName });
          showNotification(PROFILE_UPDATED, 'success');
          handleSubmitInteraction(false), values?.email;
          onClose();
        } else {
          showNotification(INCORRECT_OLD_PASSWORD, 'error');
        }
      } else {
        await update({ firstName: values?.firstName, lastName: values?.lastName });
        showNotification(PROFILE_UPDATED, 'success');
        handleSubmitInteraction(false, values?.email);
        onClose();
      }
    } catch (error) {
      console.log(error);
      onClose();
    }
  };

  return (
    <Transition.Root show={modelOpen} as={Fragment}>
      <Dialog className={`${mode} ${theme} default fixed inset-0 z-10 overflow-y-auto`} onClose={onClose}>
        <>
          <div className="flex items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="absolute inset-0">
                {/* eslint-disable */}
                <div className="absolute top-1/2  left-1/2  max-h-[90vh] w-[90%] max-w-[500px] -translate-x-1/2 -translate-y-1/2 overflow-auto bg-white py-6 px-4 dark:bg-primary-200 sm:px-6  lg:px-8">
                  <section className="mt-5 flex justify-between">
                    <h1 className="mx-auto mb-9 w-max text-center text-2xl font-bold leading-[29px] text-sol-400 after:mx-auto after:mt-4 after:block after:h-[1px] after:w-[5.75rem] after:bg-[#B19A6A] after:content-['']">
                      {modalHeading}
                    </h1>
                    <section className="pt-2">
                      <button
                        type="button"
                        id="editProfileModalClose"
                        aria-label="Edit Profile Modal Close"
                        onClick={onClose}
                      >
                        <CloseIcon className="fill-sol-300 " />
                      </button>
                    </section>
                  </section>
                  <Formik
                    initialValues={editProfileInitialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleEditProfile(values)}
                  >
                    {({ errors, touched, handleBlur }) => (
                      <Form>
                        <FormEventsAnalyticsTracker formName={modalHeading} />
                        <section className="grid gap-y-5">
                          <section className="w-full">
                            <label
                              htmlFor="email"
                              className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                            >
                              {mapLocaleToMeaningfulFormat(router.locale).email}
                            </label>
                            <Field
                              type="text"
                              name="email"
                              id="email"
                              readOnly
                              onBlur={handleBlur}
                              className={`input-text-primary mt-3 !w-full ${
                                errors?.email && touched?.email ? '!border-red-600' : ''
                              }`}
                            />
                            {errors.email && touched.email && (
                              <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.email}</p>
                            )}
                          </section>
                          <section className="grid grid-cols-1 gap-y-5 lg:grid-cols-2 lg:gap-6 ">
                            <section className="w-full">
                              <label
                                htmlFor="firstName"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).firstName}
                              </label>
                              <Field
                                type="text"
                                name="firstName"
                                id="firstName"
                                onBlur={handleBlur}
                                className={`input-text-primary mt-3 !w-full ${
                                  errors?.firstName && touched?.firstName ? '!border-red-600' : ''
                                }`}
                              />
                              {errors.firstName && touched.firstName && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.firstName}</p>
                              )}
                            </section>
                            <section className="w-full">
                              <label
                                htmlFor="lastName"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).lastName}
                              </label>
                              <Field
                                type="text"
                                name="lastName"
                                id="lastName"
                                onBlur={handleBlur}
                                className={`input-text-primary mt-3 !w-full ${
                                  errors?.lastName && touched?.lastName ? '!border-red-600' : ''
                                }`}
                              />
                              {errors.lastName && touched.lastName && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.lastName}</p>
                              )}
                            </section>
                          </section>
                          <section className="w-full">
                            <label htmlFor="oldPassword" className="text-sm font-semibold leading-[17px] text-sol-300">
                              {mapLocaleToMeaningfulFormat(router.locale).oldPassword}
                            </label>
                            <Password
                              name="oldPassword"
                              id="oldPassword"
                              onBlur={handleBlur}
                              className={errors?.oldPassword && touched?.oldPassword ? '!border-red-600' : ''}
                            />
                            {errors.oldPassword && touched.oldPassword && (
                              <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.oldPassword}</p>
                            )}
                          </section>
                          <section className="w-full">
                            <section className="flex items-center gap-2">
                              <label
                                htmlFor="newPassword"
                                className="text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).newPassword}
                              </label>
                              <section className="dropdown group relative">
                                <section className="cursor-pointer py-1 pr-5">
                                  <InfoIcon className="fill-sol-300" />
                                  <div className="dropdown-menu absolute -left-[80px] z-[1] hidden h-auto w-[270px] rounded-[6px] border border-gray-300 bg-[#F5F5F5] p-4 group-hover:block lg:left-0 lg:w-[347px]">
                                    <h4 className="mb-3 text-xs font-semibold leading-[15px] text-sol-500">
                                      {mapLocaleToMeaningfulFormat(router.locale).passwordsMustMeet}
                                    </h4>
                                    <section className="top-0 w-full">
                                      <ul className="list-disc pl-4 ">
                                        <li className="py-1 text-sol-300">
                                          <p className="text-xs font-normal leading-[18px] text-sol-500">
                                            {mapLocaleToMeaningfulFormat(router.locale).passwordlength}
                                          </p>
                                        </li>
                                        <li className="my-2">
                                          <p className="text-xs font-normal leading-[18px] text-sol-500">
                                            {mapLocaleToMeaningfulFormat(router.locale).passwordDifferentFromUserName}
                                          </p>
                                        </li>
                                        <li className="my-2">
                                          <p className="text-xs font-normal leading-[18px] text-sol-500">
                                            {mapLocaleToMeaningfulFormat(router.locale).passwordNotSpace}
                                          </p>
                                        </li>
                                        <li className="my-2">
                                          <p className="text-xs font-normal leading-[18px] text-sol-500">
                                            {mapLocaleToMeaningfulFormat(router.locale).passwordOneUpperCase}
                                          </p>
                                        </li>
                                        <li className="my-2">
                                          <p className="text-xs font-normal leading-[18px] text-sol-500">
                                            {mapLocaleToMeaningfulFormat(router.locale).passwordOneDigit}
                                          </p>
                                        </li>
                                        <li className="my-2">
                                          <p className="text-xs font-normal leading-[18px] text-sol-500">
                                            {mapLocaleToMeaningfulFormat(router.locale).passwordMustContain}
                                          </p>
                                        </li>
                                      </ul>
                                    </section>
                                  </div>
                                </section>
                              </section>
                            </section>
                            <Password
                              name="newPassword"
                              id="newPassword"
                              onBlur={handleBlur}
                              className={errors?.newPassword && touched?.newPassword ? '!border-red-600' : ''}
                            />
                            {errors.newPassword && touched.newPassword && (
                              <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.newPassword}</p>
                            )}
                          </section>
                          <section className="w-full">
                            <label
                              htmlFor="confirmPassword"
                              className="text-sm font-semibold leading-[17px] text-sol-300"
                            >
                              {mapLocaleToMeaningfulFormat(router.locale).confirmPassword}
                            </label>
                            <Password
                              name="confirmPassword"
                              id="confirmPassword"
                              onBlur={handleBlur}
                              className={errors?.confirmPassword && touched?.confirmPassword ? '!border-red-600' : ''}
                            />
                            {errors.confirmPassword && touched.confirmPassword && (
                              <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                {errors.confirmPassword}
                              </p>
                            )}
                          </section>
                          <section className="flex flex-col items-center gap-0 md:flex-row md:gap-6">
                            <button
                              type="submit"
                              id="profileEdit"
                              className={`btn-primary-small flex h-[44px] w-full items-center justify-center px-8 md:w-auto ${
                                Object.keys(errors).length === 0 ? '' : 'cursor-not-allowed'
                              }`}
                            >
                              {primaryBtnContent}
                            </button>
                            <button
                              type="button"
                              id="cancelProfileEdit"
                              onClick={onClose}
                              className="btn-cancel flex h-[44px] w-full items-center justify-center underline underline-offset-2 md:w-auto"
                            >
                              {mapLocaleToMeaningfulFormat(router.locale).cancel}
                            </button>
                          </section>
                        </section>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Transition.Child>
          </div>
        </>
      </Dialog>
    </Transition.Root>
  );
};

export default EditProfile;
