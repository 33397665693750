import { useState, Fragment, useEffect } from 'react';
import router, { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import type { Address as AddressType } from '@Types/account/Address';
import { Country, State } from '@Types/account/Country';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { get } from 'lodash-es';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import Spinner from 'components/sol-ui/notification/spinner';
import { ADDRESS_UPDATED, SERVER_ERROR } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { StringHelpers } from 'helpers/stringHelpers';
import { DEFAULT_COUNTRY, DEFAULT_STATE, EXTENSIONMASK, PHONEMASK } from 'helpers/utils/constant';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import validationSchema from 'helpers/utils/validationSchema/modal/edit-profil';
import { useDarkMode, useAccount } from 'frontastic';
import CloseIcon from '../../../icons/sol/close';
import FormEventsAnalyticsTracker from 'components/sol-ui/form-events-analytics-tracker';
export interface EditProfileProps {
  open?: boolean;
  onClose?: () => void;
  modalHeading?: string;
  primaryBtnContent?: string;
  address?: AddressType;
  handleEditAddress?: (address: AddressType, isDefault: boolean) => Promise<void>;
  handleAddAddress?: (address: AddressType, isDefault: boolean) => Promise<void>;
  onClickEdit?: boolean;
  setAddressesData?: any;
  loading: boolean;
  isBillingAddress: boolean;
}

const EditProfile: React.FC<EditProfileProps> = ({
  open,
  onClose,
  modalHeading,
  primaryBtnContent,
  address,
  handleEditAddress,
  handleAddAddress,
  onClickEdit,
  setAddressesData,
  loading,
  isBillingAddress,
}) => {
  //next/router
  const router = useRouter();
  //Darkmode
  const { mode, theme } = useDarkMode();
  const { shippingLocations } = useAccount();
  const countries = Object.freeze(shippingLocations?.data) as Country[];
  const [states, setStates] = useState<State[]>();
  const [isSaving, setIsSaving] = useState(false);
  const [isDefault, setAsDefaultAddress] = useState(false);

  const resetForm = {
    additionalAddressInfo: '',
    additionalStreetInfo: '',
    city: '',
    country: DEFAULT_COUNTRY,
    firstName: '',
    isDefaultBillingAddress: undefined,
    isDefaultShippingAddress: undefined,
    lastName: '',
    phone: '',
    postalCode: '',
    salutation: '',
    streetName: '',
    streetNumber: '',
    state: DEFAULT_STATE,
    company: '',
    extension: '',
  };
  let editProfileInitialValues: AddressType = StringHelpers.phoneExtensionSplitter(address) ?? resetForm;
  //global notification
  const { showNotification } = useNotification();
  const handleSubmit = async (address: AddressType) => {
    if (address?.addressId) {
      try {
        setIsSaving(true);
        const updatedAddress: AddressType = address;
        updatedAddress.firstName = updatedAddress.firstName.trim();
        updatedAddress.lastName = updatedAddress.lastName.trim();
        await handleEditAddress(StringHelpers.phoneExtensionMerger(updatedAddress), isDefault);
      } catch (error) {
        showNotification(SERVER_ERROR, 'error');
      }
      setIsSaving(false);
      setAsDefaultAddress(false);
    } else {
      try {
        setIsSaving(true);
        const newAddress: AddressType = address;
        newAddress.firstName = newAddress.firstName.trim();
        newAddress.lastName = newAddress.lastName.trim();
        await handleAddAddress(StringHelpers.phoneExtensionMerger(newAddress), isDefault);
        editProfileInitialValues = resetForm;
        //setAsDefaultAddress(true);
      } catch (error) {
        showNotification(SERVER_ERROR, 'error');
      }
      setIsSaving(false);
    }
  };

  const handleCheckbox = () => {
    if (!isDefault) {
      setAsDefaultAddress(true);
    } else {
      setAsDefaultAddress(false);
    }
  };

  const handleCancel = () => {
    if (modalHeading !== mapLocaleToMeaningfulFormat(router.locale).editAddress) {
      setAsDefaultAddress(true);
    } else {
      setAsDefaultAddress(false);
    }

    onClose();
  };
  useEffect(() => {
    if (countries) {
      const selectedCountry = editProfileInitialValues?.country ?? undefined;
      const countryCode = selectedCountry !== '' ? selectedCountry : DEFAULT_COUNTRY;
      if (countryCode) {
        const states = handleCountrySelect(countryCode) as State[];
        setStates(states);
      }
    }
  }, [countries, open]);

  const handleCountrySelect = (countryCode: string) => {
    if (countryCode) {
      return countries?.find((country) => country?.countryCode === countryCode)?.states;
    }
    return [];
  };
  const handleDefaultState = (countryCode) => {
    const states = countries?.find((country) => country?.countryCode === countryCode)?.states;
    if (states) {
      return states[0]?.stateCode;
    }
    return '';
  };

  const Input = styled(InputMask)``;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className={`${mode} ${theme} default fixed inset-0 z-10 overflow-y-auto`} onClose={onClose}>
        <>
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="absolute inset-0">
                {/* eslint-disable */}
                <div className="absolute top-1/2 left-1/2 h-[90vh] w-[90%] max-w-[600px] -translate-x-1/2 -translate-y-1/2 overflow-auto bg-white py-8 px-4 dark:bg-primary-200 sm:px-6  lg:px-8">
                  <section className="flex justify-between">
                    <h1 className="mx-auto mb-9 w-max text-center text-2xl font-bold leading-[29px] text-sol-400 after:mx-auto after:mt-4 after:block after:h-[1px] after:w-[150px] after:bg-[#B19A6A] after:content-['']">
                      {modalHeading}
                    </h1>
                    <section className="pt-2">
                      <button
                        type="button"
                        id="AddressModalClose"
                        aria-label="Close Address Modal"
                        onClick={handleCancel}
                      >
                        <CloseIcon className="fill-sol-300 " />
                      </button>
                    </section>
                  </section>
                  <Formik
                    initialValues={editProfileInitialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values: AddressType) => handleSubmit(values)}
                    validateOnBlur={false}
                    validateOnChange={false}
                  >
                    {(formik) => (
                      <Form>
                        <FormEventsAnalyticsTracker formName={modalHeading} />
                        <section className="grid gap-y-5">
                          <section className="grid gap-y-5 gap-x-10 lg:grid-cols-2">
                            <section className="w-full">
                              <label
                                htmlFor="firstName"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).firstName}
                              </label>
                              <Field
                                type="text"
                                name="firstName"
                                id="firstName"
                                onBlur={formik?.handleBlur}
                                className={`input-text-primary mt-3 !w-full ${
                                  formik?.errors?.firstName && formik?.touched?.firstName ? '!border-red-600' : ''
                                }`}
                              />
                              {formik?.errors?.firstName && formik?.touched?.firstName && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                  {formik?.errors?.firstName}
                                </p>
                              )}
                            </section>
                            <section className="w-full">
                              <label
                                htmlFor="lastName"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).lastName}
                              </label>
                              <Field
                                type="text"
                                name="lastName"
                                id="lastName"
                                onBlur={formik?.handleBlur}
                                className={`input-text-primary mt-3 !w-full ${
                                  formik?.errors?.lastName && formik?.touched?.lastName ? '!border-red-600' : ''
                                }`}
                              />
                              {formik?.errors?.lastName && formik?.touched?.lastName && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                  {formik?.errors?.lastName}
                                </p>
                              )}
                            </section>
                          </section>
                          <section className="w-full">
                            <label htmlFor="company" className="text-sm font-semibold leading-[17px] text-sol-300">
                              {mapLocaleToMeaningfulFormat(router.locale).company}
                            </label>
                            <Field
                              type="text"
                              name="company"
                              id="company"
                              className={`input-text-primary mt-3 block !w-full`}
                            />
                          </section>
                          <section className="w-full">
                            <section className="flex items-center justify-between">
                              <label
                                htmlFor="streetName"
                                className="requiredLabel whitespace-nowrap text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).streetName}
                              </label>
                              <p className="mb-1 whitespace-nowrap text-sm font-normal leading-[17px] text-sol-300">
                                {' '}
                                {mapLocaleToMeaningfulFormat(router.locale).weCannotShip}
                              </p>
                            </section>
                            <Field
                              type="text"
                              name="streetName"
                              id="streetName"
                              onBlur={formik?.handleBlur}
                              className={`input-text-primary mt-3 !w-full ${
                                formik?.errors?.streetName && formik?.touched?.streetName ? '!border-red-600' : ''
                              }`}
                            />
                            {formik?.errors?.streetName && formik?.touched?.streetName && (
                              <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                {formik?.errors?.streetName}
                              </p>
                            )}
                          </section>
                          <section className="w-full">
                            <label htmlFor="streetNumber" className="text-sm font-semibold leading-[17px] text-sol-300">
                              {mapLocaleToMeaningfulFormat(router.locale).streetNumber}
                            </label>
                            <Field
                              type="text"
                              name="streetNumber"
                              id="streetNumber"
                              className={`input-text-primary mt-3 !w-full`}
                            />
                          </section>

                          <section className="grid gap-5 lg:grid-cols-2 lg:gap-10">
                            <section className="w-full">
                              <label
                                htmlFor="city"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).city}
                              </label>
                              <Field
                                type="text"
                                name="city"
                                id="city"
                                className={`input-text-primary mt-3 !w-full ${
                                  formik?.errors?.city && formik?.touched?.city ? '!border-red-600' : ''
                                }`}
                              />
                              {formik?.errors?.city && formik?.touched?.city && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                  {formik?.errors?.city}
                                </p>
                              )}
                            </section>
                            <section>
                              <label
                                htmlFor="state"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).stateRegion}
                              </label>
                              <Field
                                value={formik?.values?.state ?? editProfileInitialValues?.state}
                                id="state"
                                name="state"
                                as="select"
                                onChange={(e) => {
                                  const { value } = e.target;
                                  formik.setFieldValue('state', value);
                                  formik.handleChange(e);
                                }}
                                className="input-text-primary mt-3 !w-full"
                              >
                                {states &&
                                  states.map((state) => (
                                    <option key={state?.stateCode} value={state?.stateCode}>
                                      {state?.state}
                                    </option>
                                  ))}
                              </Field>
                            </section>
                          </section>
                          <section className="grid gap-5 lg:grid-cols-2 lg:gap-10">
                            <section className="w-full">
                              <label
                                htmlFor="postalCode"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).postalCode}
                              </label>
                              <Field
                                type="text"
                                name="postalCode"
                                id="postalCode"
                                className={`input-text-primary mt-3 !w-full ${
                                  formik?.errors?.postalCode && formik?.touched?.postalCode ? '!border-red-600' : ''
                                }`}
                              />
                              {formik?.errors?.postalCode && formik?.touched?.postalCode && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                  {formik?.errors?.postalCode}
                                </p>
                              )}
                            </section>
                            <section>
                              <label
                                htmlFor="country"
                                className="block h-5 text-sm font-semibold leading-[17px] text-sol-300 lg:h-6"
                              >
                                {' '}
                                {mapLocaleToMeaningfulFormat(router.locale).country}
                              </label>
                              <Field
                                id="country"
                                name={`.country`}
                                as="select"
                                className="input-text-primary mt-3 !w-full"
                                value={formik?.values?.country ?? editProfileInitialValues?.country}
                                onChange={async (e) => {
                                  const { value } = e.target;
                                  const _states = handleCountrySelect(value);
                                  if (_states) {
                                    formik.setFieldValue(`country`, value);
                                    formik.setFieldValue(`state`, handleDefaultState(value));
                                    setStates(_states);
                                  }
                                }}
                              >
                                {countries &&
                                  countries
                                    ?.filter((country) => isBillingAddress || country?.countryCode !== 'CA')
                                    ?.map((country, index) => (
                                      <option key={index} value={country?.countryCode}>
                                        {country?.country}
                                      </option>
                                    ))}
                              </Field>
                            </section>
                          </section>
                          <section className="flex gap-x-4 lg:grid lg:grid-cols-2 lg:gap-x-10">
                            <section className="w-full">
                              <label
                                htmlFor="phone"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).phoneNumber}
                              </label>
                              <Field
                                name="phone"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                                value={formik?.values?.phone ?? editProfileInitialValues?.phone}
                              >
                                {({ form: { errors, touched } }) => (
                                  <Input
                                    id="phone"
                                    type="text"
                                    mask={PHONEMASK}
                                    maskChar={null}
                                    onChange={formik?.handleChange}
                                    onBlur={formik?.handleBlur}
                                    value={formik?.values?.phone ?? editProfileInitialValues?.phone}
                                    className={`input-text-primary mt-3 !w-full ${
                                      errors?.phone && touched?.phone ? '!border-red-600' : ''
                                    }`}
                                  />
                                )}
                              </Field>
                              {formik?.errors?.phone && formik?.touched?.phone && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                  {formik?.errors?.phone}
                                </p>
                              )}
                            </section>
                            <section className="w-[115px]">
                              <label htmlFor="extension" className="text-sm font-semibold leading-[17px] text-sol-300">
                                {mapLocaleToMeaningfulFormat(router.locale).extension}
                              </label>
                              <Field>
                                {({ form: { errors, touched } }) => (
                                  <Input
                                    type="text"
                                    mask={EXTENSIONMASK}
                                    maskChar={null}
                                    name="extension"
                                    id="extension"
                                    onBlur={formik?.handleBlur}
                                    onChange={formik?.handleChange}
                                    value={formik?.values?.extension ?? editProfileInitialValues?.extension}
                                    className={`input-text-primary mt-3 !w-full ${
                                      errors?.extension && touched?.extension ? '!border-red-600' : ''
                                    }`}
                                  />
                                )}
                              </Field>
                            </section>
                          </section>
                          <label htmlFor="setDefaultAddress" className="mt-4 flex items-center gap-4">
                            <Field
                              name="checkbox"
                              type="checkbox"
                              id="setDefaultAddress"
                              className="h-4 w-4 appearance-none rounded-[2px] border-none border-black-300 text-white ring-2 ring-sol-300 focus:ring-sol-300"
                              onClick={handleCheckbox}
                              checked={isDefault}
                            />
                            <p className="text-sm leading-[17px] text-sol-300">
                              {mapLocaleToMeaningfulFormat(router.locale).setDefaultAddress}
                            </p>
                          </label>
                          <section className="flex flex-col items-center gap-0 md:flex-row md:gap-6">
                            <button
                              type="submit"
                              disabled={isSaving}
                              id="editProfile"
                              className={`btn-primary-small flex h-[44px] w-full items-center justify-center px-8 md:w-auto ${
                                Object.keys(formik?.errors)?.length === 0 ? '' : 'cursor-not-allowed'
                              }`}
                            >
                              {' '}
                              {loading ? (
                                <section className="flex items-center justify-center gap-3">
                                  {' '}
                                  <Spinner />
                                  <p>{mapLocaleToMeaningfulFormat(router.locale).processing}</p>
                                </section>
                              ) : (
                                primaryBtnContent
                              )}
                            </button>
                            <button
                              type="button"
                              id="cancelAddAddress"
                              onClick={handleCancel}
                              className={`btn-cancel flex h-[44px] w-full items-center justify-center underline md:w-auto`}
                            >
                              {mapLocaleToMeaningfulFormat(router.locale).cancel}
                            </button>
                          </section>
                        </section>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Transition.Child>
          </div>
        </>
      </Dialog>
    </Transition.Root>
  );
};

export default EditProfile;
