import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Cart as CartType } from '@Types/cart/Cart';
import Reference from 'yup/lib/Reference';
import { cartQuantityTotal } from 'helpers/cartQuantityTotal';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { isCartIsValid } from 'helpers/utils/validateCart';
import { NextFrontasticImage, useAccount, useCart } from 'frontastic';
import Breadcrumb from '../cms/Breadcrumb';
import CartItem from '../line-Item';
import CartSummary from '../order-summary';
import { gtmViewCart } from 'helpers/utils/gtm-events/gtmEcommerce';

export interface CartProps {
  pageTitle?: string;
  emptyStateImage?: NextFrontasticImage;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
  emptyStateCTALabel?: string;
  emptyStateCTALink?: Reference;
  cart: CartType;
  editItemQuantity: (lineItemId: string, newQuantity: number) => Promise<void>;
  removeItem: (lineItemId: string) => Promise<void>;
}

const Cart = ({
  cart,
  editItemQuantity,
  removeItem,
  pageTitle,
  emptyStateImage,
  emptyStateTitle,
  emptyStateSubtitle,
  emptyStateCTALabel,
  emptyStateCTALink,
}: CartProps) => {
  const router = useRouter();
  const { redeemDiscountCode, removeDiscountCode } = useCart();
  const [isCartValid, setIsCartValid] = useState(true);
  const { account } = useAccount();
  const [lineItemQuantityDisabled, setLineItemQuantityDisabled] = useState(false);

  useEffect(() => {
    setIsCartValid(isCartIsValid(cart));
  }, [cart]);

  useEffect(() => {
    if (isCartIsValid && cart) {
      gtmViewCart(cart, account);
    }
  }, [isCartValid, cart, account]);
  return (
    <>
      <Breadcrumb breadcrumLevelLink={true} activePage={mapLocaleToMeaningfulFormat(router.locale).cart} />
      <section className="mx-auto max-w-screen-8xl px-4 pb-10">
        <h1 className="mt-6  text-center text-[1.750rem]  font-semibold after:mx-auto  after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-sol-600 after:content-[''] lg:mt-4">
          {mapLocaleToMeaningfulFormat(router.locale).cart}
        </h1>
        <p className="my-5 text-center text-sm">
          {cart?.lineItems?.length > 0 ? cartQuantityTotal(cart?.lineItems) : 0}{' '}
          {mapLocaleToMeaningfulFormat(router.locale).items}
        </p>
        {!isCartValid && (
          <p className="mt-4 text-center font-medium text-red-600 lg:pr-4 lg:pb-8">
            {' '}
            {mapLocaleToMeaningfulFormat(router.locale).invalidCart}
          </p>
        )}
        <section className="flex flex-col justify-between gap-9 lg:flex-row">
          <section className="w-full">
            <ul>
              {cart?.lineItems?.length === 0 ? (
                <section className="rounded-md border px-5 py-10">
                  <p>{mapLocaleToMeaningfulFormat(router.locale).cartIsEmpty}</p>
                  <p className="mt-5">
                    <Link href={routerURL?.continueShopping}>
                      <a className="w-full rounded-md border border-sol-300 border-opacity-90 bg-white py-2 px-4 font-semibold text-sol-300 lg:w-[180px]">
                        {mapLocaleToMeaningfulFormat(router.locale).continueShopping}
                      </a>
                    </Link>
                  </p>
                </section>
              ) : (
                <></>
              )}
              {cart?.lineItems?.map((cartLineItem, index) => (
                <li
                  key={index}
                  className="mb-5 mt-3 w-full rounded border px-5 py-6 pb-5 text-sm font-normal text-sol-300 lg:mt-0 lg:py-2"
                >
                  {!cartLineItem?.isAvailable && (
                    <p className="mt-4 text-left font-medium text-red-600 lg:pr-4 lg:pb-1">
                      {' '}
                      {mapLocaleToMeaningfulFormat(router.locale).thisProductNoLongerAvailable}
                    </p>
                  )}
                  <CartItem
                    componentIdentifier={`cart`}
                    lineItem={cartLineItem}
                    showQuantityInput={true}
                    showAddToCartButton={false}
                    showRemoveButton={true}
                    removeLineItem={removeItem}
                    updateLineItem={editItemQuantity}
                    lineItemQuantityDisabled={lineItemQuantityDisabled}
                    setLineItemQuantityDisabled={setLineItemQuantityDisabled}
                    showInventory={true}
                    isFromOrderDetails={false}
                    index={index}
                  />
                </li>
              ))}
            </ul>
          </section>
          {cart && cart?.lineItems?.length === 0 ? null : (
            <CartSummary
              checkoutHeading={mapLocaleToMeaningfulFormat(router.locale).cartSummary}
              showCalculateEstimatedTax={false}
              showPromoCode={true}
              cart={cart}
              showCheckoutButton={true}
              showHelpLinks={true}
              redeemDiscountCode={redeemDiscountCode}
              removeDiscountCode={removeDiscountCode}
              showPromoRemoveButton={true}
              showShippingPrice={false}
              isCheckout={false}
              isCartValid={isCartValid}
              isCheckoutAddress={false}
            />
          )}
        </section>
      </section>
    </>
  );
};

export default Cart;
