import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { Product } from '@Types/product/Product';
import CheckedIcon from 'components/icons/sol/checked-icon';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { AMPLIENCE_IMAGE_URL_FORMAT, EVENT_LEFT_CLICK, EVENT_RIGHT_CLICK } from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import omitFileExtension from 'helpers/utils/omitFileExtension';
import { buildLinkProductVariant } from 'helpers/utils/productUrlBuilder';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import NoImageAvailable from 'public/images/No-Image-Available.jpg';
import { useAccount } from 'frontastic';
import { getProduct } from 'frontastic/actions/products';
import QuickViewModal from '../product-list/quick-view-modal';
import { amplienceImageLoader } from 'helpers/utils/amplienceImageLoader';
import { gtmSelectItem } from 'helpers/utils/gtm-events/gtmEcommerce';
import { slugToTitle } from 'helpers/utils/gtm-events/gtmHelpers';
import { RouteHelpers } from 'helpers/routeHelpers';

interface ProductListCardType {
  data: Product;
  currentCat?: any;
  downrodsContent?: any;
  currentFilters?: any;
  positions?: any;
  source?: any;
}

export interface UIColor {
  name?: string;
  key?: string;
  bgColor?: string;
  selectedColor?: string;
}

interface ColorSelectorProps {
  colors: UIColor[];
  selectedVariant: any;
  //   changeVariant: (variant: any) => void;
}

const CTProductListCard = ({
  data: plpItem,
  currentCat,
  downrodsContent,
  currentFilters,
  positions,
  source,
}: ProductListCardType) => {
  const [productData, setProductData] = useState(plpItem);
  const [currentIndex, setCurrentIndex] = useState(0);
  const startIndex =
    currentIndex > plpItem?.variants?.length - 3 && plpItem?.variants?.length > 3
      ? plpItem?.variants?.length - 3
      : currentIndex;
  const endIndex = plpItem?.variants?.length > 3 ? startIndex + 3 : plpItem?.variants?.length - 1;
  const displayItems =
    plpItem?.variants?.length > 3 ? plpItem?.variants.slice(startIndex, endIndex) : plpItem?.variants;
  const [plpSelectedVariant, setPlpSelectedVariant] = useState(null);
  const [productClicked, setProductClicked] = useState(false);
  const router = useRouter();
  const { account } = useAccount();
  const [isDesktop] = useMediaQuery(desktop);
  const [navigationLink, setNavigationLink] = useState('');
  const [plpItemMainImage, setPlpItemMainImage] = useState(null);
  const [color, setColor] = useState('');
  const [link, setLink] = useState('');
  const dynamicWidth = calculateDynamicImageWidth(300, 400, 500);
  const dynamicWidthVariant = calculateDynamicImageWidth(30, 30, 30);

  useEffect(() => {
    fetchProduct();
  }, []);

  const fetchProduct = useCallback(async () => {
    if (productData && productData.variants.length == 0) {
      const product = await getProduct({ id: productData?.productId });
      productData.variants = product?.variants;
      setProductData(productData);
      setPlpSelectedVariant(productData?.variants[0]);
    }
  }, [productData]);

  const PDPNavigationLink = () => {
    let pdpNavigationLink = productData.navLink;
    if (productClicked && productData?.variants?.length > 1) {
      const query = {
        color: plpSelectedVariant
          ? plpSelectedVariant?.attributes.actualColor
          : productData.variants[0]?.attributes?.actualColor,
      };
      pdpNavigationLink += RouteHelpers.encodeToURL(query);
    }
    setNavigationLink(pdpNavigationLink);
  };

  const applySelectedVariant = (allVariants, filterArr, attr) => {
    const filterVariants = allVariants?.filter((variant) => {
      return variant?.attributes[`${attr}`] && variant;
    });
    if (filterVariants && filterVariants?.length) {
      for (const filter of filterArr) {
        const filteredArray = filterVariants?.filter((item) => {
          return item?.attributes[`${attr}`]?.some((obj) => obj?.key === filter);
        });
        if (filteredArray?.length) {
          const masterVaraint = filteredArray?.find((item) => item?.isMasterVariant && item?.isMasterVariant === true);
          const finalVariant = masterVaraint ? masterVaraint : filteredArray[0];
          setPlpSelectedVariant(finalVariant);
          setPlpItemMainImage(finalVariant?.images?.find((image: any) => image?.label === 'Main'));
          PDPNavigationLink();
        }
      }
    }
  };

  useEffect(() => {
    if (!plpSelectedVariant) {
      const masterVariant = productData?.variants?.find((variant: any) => variant?.isMasterVariant);
      setPlpSelectedVariant(masterVariant ? masterVariant : productData?.variants[0]);
      setPlpItemMainImage(
        masterVariant
          ? masterVariant?.images?.find((image: any) => image?.label === 'Main')
          : productData?.variants[0]?.images?.find((image: any) => image?.label === 'Main'),
      );
      PDPNavigationLink();
    }
  }, [productData]);

  useEffect(() => {
    if (plpSelectedVariant) {
      const getAppliedFilterColors = currentFilters?.filterColor;
      const getAppliedFilterFinish = currentFilters?.filterFinish;

      if (
        getAppliedFilterColors &&
        getAppliedFilterColors?.length &&
        getAppliedFilterFinish &&
        getAppliedFilterFinish?.length
      ) {
        applySelectedVariant(productData?.variants, getAppliedFilterColors, 'filterColor');
      } else if (getAppliedFilterColors && !getAppliedFilterFinish) {
        applySelectedVariant(productData?.variants, getAppliedFilterColors, 'filterColor');
      } else if (!getAppliedFilterColors && getAppliedFilterFinish) {
        applySelectedVariant(productData?.variants, getAppliedFilterFinish, 'filterFinish');
      } else {
        const masterVariant = productData?.variants?.find((variant: any) => variant?.isMasterVariant);
        PDPNavigationLink();
        setPlpSelectedVariant(masterVariant ? masterVariant : productData?.variants[0]);
        setPlpItemMainImage(
          masterVariant
            ? masterVariant?.images?.find((image: any) => image?.label === 'Main')
            : productData?.variants[0]?.images?.find((image: any) => image?.label === 'Main'),
        );
      }
    }
  }, [productData.variants, plpSelectedVariant]);

  useEffect(() => {
    if (color !== '') {
      const matchingVariant = productData.variants.find((variant) => variant.attributes?.actualColor === color);
      if (matchingVariant) {
        setProductClicked(true);
        setPlpSelectedVariant(matchingVariant);
        setPlpItemMainImage(matchingVariant?.images.find((image: any) => image?.label === 'Main'));
        PDPNavigationLink();
      }
    }
  }, [color, productData.variants, plpSelectedVariant]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  const handleGTMSelectItem = () => {
    const listName = slugToTitle(source);
    const listId = source;
    const productIndex = positions;

    if (plpSelectedVariant) gtmSelectItem(plpItem, plpSelectedVariant, listName, listId, productIndex, account?.email);
  };

  const stopPropagation = (e) => {
    e.preventDefault();
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const selectedLocale = (router.query.locale ? router.query.locale.toString() : 'en-US').replaceAll('_', '-');

  const ColorSelector: React.FC<ColorSelectorProps> = ({ colors, selectedVariant }) => {
    const onColorPick = (color) => {
      setColor(color);
      const matchingVariant = productData.variants.find((variant) => variant.attributes?.actualColor === color);
      if (matchingVariant) {
        setProductClicked(true);
        setPlpSelectedVariant(matchingVariant);
        setPlpItemMainImage(matchingVariant?.images.find((image: any) => image?.label === 'Main'));
        PDPNavigationLink();
      }
    };

    let productVariants: any = [];
    const colorKeys = [];
    const pushedColors = [];
    colors.map((color) => colorKeys.push(color.key));
    if (colors?.length != productData?.variants?.length && colors?.length > 0) {
      productData?.variants?.some((item) => {
        if (
          !pushedColors.includes(item?.attributes?.actualColor) &&
          colorKeys.includes(item?.attributes?.actualColor)
        ) {
          productVariants.push(item);
          pushedColors.push(item?.attributes?.actualColor);
        }
        return pushedColors?.length === colors?.length;
      });
    } else {
      productVariants = productData?.variants;
    }

    // // Filter out variants for which ShowOnSite is false
    productVariants = productVariants?.filter((variant) => variant?.attributes?.shownOnSite);
    const handleSetNumber = ({ op }) => {
      const newIndex =
        op === 'Prev'
          ? currentIndex === 0
            ? currentIndex
            : currentIndex - 1
          : currentIndex === productData?.variants.length - 1
          ? currentIndex
          : currentIndex + 1;
      onColorPick(productData?.variants[newIndex]?.attributes?.actualColor);
      setCurrentIndex(newIndex);
    };

    const selectedLocale = (router.query.locale ? router.query.locale.toString() : 'en-US').replaceAll('_', '-');

    const handleVariant = (e, variant) => {
      const productSlug = productData?.slug;
      if (productSlug) {
        const newLink = buildLinkProductVariant(
          router.basePath,
          productSlug[selectedLocale],
          variant?.attributes?.actualColor,
        );
        setLink(newLink);
      }
    };

    return (
      <>
        <section className="h-8 overflow-hidden lg:overflow-visible">
          {colors?.length > 0 && isDesktop && (
            <section className="overflow-x-auto pb-5 lg:overflow-visible lg:pb-0">
              <ul className="mx-auto flex w-max items-center gap-4 px-2">
                {productVariants?.slice(0, isDesktop ? 4 : productData?.variants.length)?.map((variant, index) => {
                  return (
                    <li key={index} title={variant?.attributes?.actualColor || ''}>
                      <Link href={link} passHref>
                        <a
                          className={`flex h-7 w-7 shrink-0 items-center justify-center rounded-full border-2 border-transparent ${
                            plpSelectedVariant !== null &&
                            plpSelectedVariant?.attributes?.actualColor === variant?.attributes?.actualColor &&
                            productClicked
                              ? 'rounded-full border border-black-100 p-[2px]'
                              : ''
                          }`}
                          onClick={(e) => {
                            if (e.button === EVENT_LEFT_CLICK) {
                              e.preventDefault();
                              onColorPick(variant?.attributes?.actualColor);
                            }
                          }}
                          onMouseDownCapture={(e) => {
                            if (e.button === EVENT_RIGHT_CLICK) {
                              e.preventDefault();
                              handleVariant(e, variant);
                            }
                          }}
                          onTouchStart={(e) => {
                            handleVariant(e, variant);
                          }}
                        >
                          <Image
                            loader={({ src }) => src}
                            width={26}
                            height={26}
                            src={
                              format(AMPLIENCE_IMAGE_URL_FORMAT, [
                                omitFileExtension(variant?.images[variant?.images?.length - 1]?.url),
                                dynamicWidthVariant,
                              ]) || '#'
                            }
                            alt={variant?.attributes?.actualColor}
                            className={`flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center
            rounded-[50%]`}
                          />
                        </a>
                      </Link>
                    </li>
                  );
                })}
                {colors?.length > 4 && isDesktop && (
                  <li>
                    <button
                      type="button"
                      className="flex items-center justify-center text-sm font-normal leading-[17px] text-sol-300"
                      onClick={(e) => {
                        openDeleteModal(e);
                      }}
                    >
                      +{colors?.length - 4}
                    </button>
                  </li>
                )}
              </ul>
            </section>
          )}
          {colors?.length > 0 && !isDesktop && (
            <section className="flex overflow-x-auto pb-5 lg:overflow-visible lg:pb-0">
              <button
                type="button"
                id={'preBtn' + productData?.name[selectedLocale]?.trim()?.split(' ')?.join('')}
                aria-label="previous"
                className={`${productData?.variants.length <= 3 && 'hidden'}`}
                disabled={displayItems.length <= 1}
                onClick={() => handleSetNumber({ op: 'Prev' })}
              >
                <ChevronLeftIcon
                  className={`h-4 w-4 transform ${
                    currentIndex === 0 ? 'opacity-25' : ''
                  } transition-transform duration-500 ease-in-out`}
                />
              </button>
              <ul className="mx-auto flex w-max items-center gap-2 px-1">
                {displayItems?.map((variant, index) => {
                  return (
                    <li
                      key={index}
                      className={`flex h-7 w-7 shrink-0 items-center justify-center ${
                        plpSelectedVariant !== null &&
                        plpSelectedVariant?.attributes?.actualColor === variant?.attributes?.actualColor &&
                        productClicked
                          ? 'rounded-full border border-black-100 p-[2px]'
                          : ''
                      }`}
                    >
                      <Link href={link} passHref key={variant?.id}>
                        <a
                          className="flex items-center justify-center"
                          title={variant?.attributes?.actualColor || ''}
                          onClick={(e) => {
                            if (e.button === EVENT_LEFT_CLICK) {
                              e.preventDefault();
                              onColorPick(variant?.attributes?.actualColor);
                              setCurrentIndex(startIndex + index);
                            }
                          }}
                          onMouseDownCapture={(e) => {
                            if (e.button === EVENT_RIGHT_CLICK) {
                              e.preventDefault();
                              handleVariant(e, variant);
                            }
                          }}
                          onTouchStart={(e) => {
                            handleVariant(e, variant);
                          }}
                        >
                          <Image
                            loader={({ src }) => src}
                            width={26}
                            height={26}
                            src={
                              format(AMPLIENCE_IMAGE_URL_FORMAT, [
                                omitFileExtension(variant?.images[variant?.images?.length - 1]?.url),
                                dynamicWidthVariant,
                              ]) || '#'
                            }
                            alt={variant?.attributes?.actualColor}
                            className={`flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center
            rounded-[50%]`}
                          />
                        </a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <button
                type="button"
                id={'nextBtn' + productData?.name[selectedLocale]?.trim()?.split(' ')?.join('')}
                aria-label="next"
                className={`${productData?.variants.length <= 3 && 'hidden'}`}
                disabled={displayItems.length <= 1}
                onClick={() => handleSetNumber({ op: 'Next' })}
              >
                <ChevronRightIcon
                  className={`h-4 w-4 transform ${
                    currentIndex === productData?.variants.length - 1 ? 'opacity-25' : ''
                  } transition-transform duration-500 ease-in-out`}
                />
              </button>
            </section>
          )}
        </section>
      </>
    );
  };

  const getDistinct = (variants, fieldName) => {
    const colorKeys = variants.map((variant) => variant.attributes[fieldName]);
    const isSameColor = colorKeys?.every((val, i, arr) => val === arr[0]);

    return isSameColor ? [] : Array.from(new Set(colorKeys)).map((color) => ({ key: color, label: color }));
  };

  const colorSelection: any = getDistinct(productData?.variants, 'actualColor');

  return (
    <>
      <li>
        <section className="relative flex h-full w-full">
          <section className="h-full w-full bg-sol-100">
            <section className="productQuickView relative">
              <a href={navigationLink}>
                <Image
                  loader={amplienceImageLoader}
                  width={400}
                  height={400}
                  src={plpItemMainImage?.url ? omitFileExtension(plpItemMainImage?.url) : NoImageAvailable}
                  sizes="(max-width: 1024px) 50vw, 25vw"
                  alt={plpItemMainImage?.label ?? productData?.name[selectedLocale]}
                  className="cursor-pointer rounded-t-[6px]"
                  onClick={handleGTMSelectItem}
                />
              </a>

              {isDesktop && (
                <>
                  <button
                    id="quickView"
                    type="button"
                    className="quickView absolute top-1/2 left-1/2 -translate-x-1/2 bg-white px-2 py-1 font-semibold text-sol-300 hover:bg-black-300 hover:text-white"
                    onClick={(e) => {
                      openDeleteModal(e);
                      handleGTMSelectItem();
                    }}
                  >
                    {mapLocaleToMeaningfulFormat(router.locale).quickView}
                  </button>
                </>
              )}
            </section>
            <section className="px-2 pt-[10px] pb-3 sm:px-5">
              <ColorSelector colors={colorSelection} selectedVariant={plpSelectedVariant} />

              <a
                href={navigationLink}
                className="mb-3 mt-[10px] inline-block min-h-[42px] w-full cursor-pointer text-center text-base font-semibold leading-5 text-sol-300 line-clamp-2"
                onClick={handleGTMSelectItem}
              >
                {productData?.name}
              </a>

              <section>
                {account?.isB2BApproved && (
                  <p className="text-black inline-block w-full text-center text-sm font-bold leading-[17px]">
                    {mapLocaleToMeaningfulFormat(router.locale).yourPrice}
                  </p>
                )}
              </section>
              {account && !account?.isB2BApproved && plpSelectedVariant?.originalPrice ? (
                <>
                  <p className="text-black inline-block w-full text-center text-sm font-bold leading-[17px]">
                    {' '}
                    {mapLocaleToMeaningfulFormat(router.locale).yourPrice}
                  </p>
                  <div className="flex gap-2 md:justify-center">
                    {
                      <strong className="block text-left text-base font-semibold leading-5 text-red-500 ">
                        {plpSelectedVariant?.discountedPrice
                          ? CurrencyHelpers.formatForCurrency(plpSelectedVariant?.discountedPrice)
                          : CurrencyHelpers.formatForCurrency(plpSelectedVariant?.price)}
                      </strong>
                    }
                    <strong
                      className={`${
                        plpSelectedVariant?.discountedPrice && 'line-through'
                      } block text-left text-base font-semibold leading-5 text-sol-300 line-through`}
                    >
                      {plpSelectedVariant?.originalPrice
                        ? CurrencyHelpers.formatForCurrency(plpSelectedVariant?.originalPrice)
                        : CurrencyHelpers.formatForCurrency(plpSelectedVariant?.price)}
                    </strong>
                  </div>
                </>
              ) : account && account?.isB2BApproved && plpSelectedVariant?.originalPrice ? (
                <>
                  <p className="text-black inline-block w-full text-center text-sm font-bold leading-[17px]">
                    {' '}
                    {mapLocaleToMeaningfulFormat(router.locale).yourPrice}
                  </p>
                  <div className="flex gap-2 md:justify-center">
                    {plpSelectedVariant?.price && (
                      <strong className="block text-left text-base font-semibold leading-5 text-red-500 ">
                        {CurrencyHelpers.formatForCurrency(plpSelectedVariant?.price)}
                      </strong>
                    )}
                    <strong
                      className={`${
                        plpSelectedVariant?.originalPrice && 'line-through'
                      } block text-left text-base font-semibold leading-5 text-sol-300`}
                    >
                      {CurrencyHelpers.formatForCurrency(plpSelectedVariant?.originalPrice)}
                    </strong>
                  </div>
                </>
              ) : (
                <div className="flex justify-center gap-2">
                  {plpSelectedVariant?.originalPrice ? (
                    <>
                      <strong className="block text-left text-base font-semibold leading-5 text-red-500">
                        {CurrencyHelpers.formatForCurrency(plpSelectedVariant?.price)}
                      </strong>
                      <strong className="block text-left text-base font-semibold leading-5 text-sol-300 line-through">
                        {CurrencyHelpers.formatForCurrency(plpSelectedVariant?.originalPrice)}
                      </strong>
                    </>
                  ) : plpSelectedVariant?.discountedPrice ? (
                    <>
                      <strong className="block text-left text-base font-semibold leading-5 text-red-500">
                        {CurrencyHelpers.formatForCurrency(plpSelectedVariant?.discountedPrice)}
                      </strong>
                      <strong className="block text-left text-base font-semibold leading-5 text-sol-300 line-through">
                        {CurrencyHelpers.formatForCurrency(plpSelectedVariant?.price)}
                      </strong>
                    </>
                  ) : (
                    <strong className="block text-left text-base font-semibold leading-5 text-sol-300">
                      {CurrencyHelpers.formatForCurrency(plpSelectedVariant?.price)}
                    </strong>
                  )}
                </div>
              )}
            </section>
          </section>
        </section>
      </li>
      <QuickViewModal
        selectedVariant={plpSelectedVariant}
        modalOpen={deleteModalOpen}
        onClose={closeDeleteModal}
        product={productData}
        downrodsContent={downrodsContent}
        pdpNavigationLink={navigationLink}
        setNavigationLink={setNavigationLink}
      />
    </>
  );
};

export default CTProductListCard;
