import React, { useState, useRef, useEffect } from 'react';
import { useRefinementList } from 'react-instantsearch';
import DownIcon from 'components/icons/sol/down-icon';
import UpIcon from 'components/icons/sol/up-icon';
import CheckedIcon from '../../../icons/sol/checked-icon';
import { gtmApplyFilter, gtmPLPInteraction } from 'helpers/utils/gtm-events/gtmInteraction';

function CustomRefinementLists(props) {
  const { items, refine, createURL, canToggleShowMore, isShowingMore, toggleShowMore, attribute } = props;

  const handleRefine = async (item, e) => {
    if (e.key === 'Enter') {
      // Only trigger gtm event when users add the filter
      // -> !item.isRefined means that filters is not checked yet
      if (!item.isRefined) {
        if (attribute === 'filterColor') {
          gtmPLPInteraction({
            click_action: 'color_filter',
          });
        }
        gtmApplyFilter({
          filter_type: props.title,
          selected_option: item?.value,
        });
      }
      refine(item?.value);
      const pageTop = document.getElementById('pageBreadCrumbs');
      if (pageTop) {
        pageTop.scrollIntoView();
      }
    }
  };
  const handleRefines = async (item) => {
    // Only trigger gtm event when users add the filter
    // -> !item.isRefined means that filters is not checked yet
    if (!item.isRefined) {
      if (attribute === 'filterColor') {
        gtmPLPInteraction({
          click_action: 'color_filter',
        });
      }
      gtmApplyFilter({
        filter_type: props.title,
        selected_option: item?.value,
      });
    }
    refine(item?.value);

    const pageTop = document.getElementById('pageBreadCrumbs');
    if (pageTop) {
      pageTop.scrollIntoView();
    }
  };

  return (
    <>
      {attribute.toLowerCase().indexOf('color') === -1 ? (
        <section className="pb-5">
          <ul className="grid gap-4">
            {items.map((item, index) => (
              <li key={index}>
                <label className="flex cursor-pointer items-start gap-2">
                  <a className="hidden" href={`${createURL(item.value)}`}>
                    {item?.label}
                  </a>
                  <button type="button" className="relative mt-[6px] ml-[1.5px]" onClick={() => handleRefines(item)}>
                    <input
                      type="checkbox"
                      className="filterNavCheckbox flex h-4 w-4 cursor-pointer appearance-none flex-wrap rounded-[2px] border !border-black-900 text-white shadow-none focus:ring-0"
                      checked={item?.isRefined}
                      tabIndex={-1}
                      onKeyDown={(e) => handleRefine(item, e)}
                      readOnly
                    />
                    <CheckedIcon
                      className={`checked-icon absolute top-[4px] left-1/2 ml-[.4px] h-2 w-2 -translate-x-1/2 shadow-xl ${
                        item?.isRefined ? '' : 'hidden'
                      }`}
                    />
                  </button>
                  <p className="mt-[6px] text-sm font-normal leading-[17px] text-[#222222]">
                    {item?.label} {attribute.toLowerCase().indexOf('readytoship') == -1 && <span>({item?.count})</span>}
                  </p>
                </label>
              </li>
            ))}
            {canToggleShowMore ? (
              <button onClick={() => toggleShowMore()} className="text-right text-sm" type="button">
                {isShowingMore ? 'Show less' : 'Show more'}
              </button>
            ) : (
              <></>
            )}
          </ul>
        </section>
      ) : (
        <section className="pb-6">
          <ul className="color-checkbox-ul flex flex-wrap gap-4 px-[6px] pt-[6px]">
            {items.map((item, index) => (
              <li key={index} className="shrink-0 cursor-pointer">
                <div className="relative flex items-center">
                  <a className="hidden" href={`${createURL(item.value)}`}>
                    {item?.label}
                  </a>
                  <button
                    type="button"
                    onClick={() => handleRefines(item)}
                    className="flex cursor-pointer items-center justify-center"
                  >
                    <input
                      tabIndex={-1}
                      onKeyDown={(e) => handleRefine(item, e)}
                      title={item?.label || ''}
                      type="checkbox"
                      checked={item?.isRefined}
                      className="absolute h-6 w-6 cursor-pointer rounded-[50%] opacity-0"
                    />
                    <div
                      className={`linear-${item?.label?.toLowerCase()} flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center rounded-[50%]`}
                    >
                      <CheckedIcon className="checked-icon absolute top-[9px] h-[8.34px] w-[10.29px] cursor-pointer" />
                    </div>
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </section>
      )}
    </>
  );
}

const Accordion = (props) => {
  if (props?.attribute.toLowerCase().indexOf('color') !== -1) {
    props = {
      ...props,
      limit: 1000,
      showMore: false,
    };
    delete props.showMoreLimit;
  }
  const refineProps = useRefinementList(props);

  const { title, isClearItems, defaultOpen } = props;
  const [isOpened, setOpened] = useState<boolean>(false);
  const [height, setHeight] = useState<string>('0');
  const contentElement = useRef(null);

  useEffect(() => {
    if (isClearItems) {
      setOpened(false);
      setHeight('0px');
    }
  }, [isClearItems]);

  const filterAccordionOpening = (e) => {
    setOpened(!isOpened);
    setHeight(!isOpened ? `${contentElement?.current?.scrollHeight}px` : '0');
  };

  useEffect(() => {
    if (defaultOpen) {
      setOpened(defaultOpen);
      setHeight('auto');
    }
  }, []);
  const handleDropDown = (e) => {
    if (e.key === 'Tab') {
      setOpened(true);
    } else {
      setOpened(!isOpened);
    }
  };

  return refineProps?.items?.length ? (
    <section className="px-[14px] pt-[19px] lg:px-0">
      <section onClick={filterAccordionOpening} className={'text-black flex cursor-pointer justify-between gap-3'}>
        <strong className="mb-[18px] inline-block text-sm font-semibold leading-[17px] text-[#222222]">{title}</strong>
        <section className="cursor-pointer pt-1" onKeyDown={handleDropDown} tabIndex={0}>
          {isOpened ? <UpIcon className="fill-sol-300" /> : <DownIcon className="fill-sol-300" />}
        </section>
      </section>
      <section
        ref={contentElement}
        style={{ height: isOpened ? 'auto' : '0px' }}
        className="overflow-hidden transition-all duration-200"
      >
        <section>
          <CustomRefinementLists {...props} {...refineProps} />
        </section>
      </section>
    </section>
  ) : (
    <></>
  );
};

export default React.memo(Accordion);
